import {
  useHistory as useRouterHistory,
  useLocation as useRouterLocation,
  useParams as useRouterParams,
} from 'react-router-dom'

type RouterState = {
  message?: {
    content: string
    type: 'success' | 'error' | 'info' | 'warning'
  }
}

export const useRouter = <T extends Record<string, string | undefined>>() => {
  const history = useRouterHistory<RouterState>()
  const location = useRouterLocation<RouterState>()
  const params = useRouterParams<T>()
  return {
    history,
    location,
    params,
  }
}
