import React from 'react'
import parse from 'html-react-parser'

import { useRouter } from '../router'

export const Alert: React.FC = () => {
  const { location, history } = useRouter()
  return (
    <>
      {location.state?.message && (
        <div id="toast-container" className="toast-top-center">
          <div
            className={`toast toast-${location.state.message.type}`}
            aria-live="assertive"
            onClick={() => {
              history.replace(location.pathname, {
                ...location.state,
                message: undefined,
              })
            }}
          >
            <button type="button" className="toast-close-button" role="button">
              ×
            </button>
            <div className="toast-message">
              {parse(location.state.message.content)}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
