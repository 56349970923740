import React from 'react'

type Props = object

export const Footer: React.FC<Props> = () => {
  return (
    <div className="app-wrapper-footer">
      <div className="app-footer">
        <div className="app-footer__inner">
          <div className="app-footer-left" />
          <div className="app-footer-right">
            <ul className="header-megamenu nav">
              <li className="nav-item">&copy;Toko-Ai, Inc.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
