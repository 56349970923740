import dayjs from 'dayjs'
import React, { useState, useEffect } from 'react'

import { useUniqueId } from '../hooks/uniqueId'

type Props = {
  defaultValue?: Date | null
  onChange?(value: Date): void
  className?: string
  scheduled?: boolean
}

type State = {
  year: string
  month: string
  day: string
  hour: string
  minute: string
  second: string
}

export const DateTimeInput: React.FC<Props> = ({
  defaultValue,
  onChange,
  className = '',
  scheduled,
}) => {
  const date = dayjs(defaultValue || new Date()) // nullの場合1970年になってしまうのでnew Date()を使う
  const [yearId, monthId, dayId, hourId, minuteId, secondId] = useUniqueId(6)
  const [state, setState] = useState<State>({
    year: date.format('YYYY'),
    month: date.format('MM'),
    day: date.format('DD'),
    hour: date.format('HH'),
    minute: date.format('mm'),
    second: scheduled ? '00' : date.format('ss'),
  })
  useEffect(() => {
    onChange &&
      onChange(
        dayjs(
          `${state.year}-${state.month}-${state.day}T${state.hour}:${state.minute}:${state.second}`
        ).toDate()
      )
  }, [state])
  return (
    <>
      <div className={`form-inline ${className}`}>
        <div className="form-group">
          <input
            name="year"
            id={yearId}
            type="text"
            className="form-control"
            size={4}
            pattern="^[0-9]{4}$"
            defaultValue={state.year}
            onChange={(e) => setState({ ...state, year: e.target.value })}
          />
          <label htmlFor={yearId} className="mx-2">
            年
          </label>
        </div>
        <div className="form-group">
          <input
            name="month"
            id={monthId}
            type="text"
            className="form-control"
            size={4}
            pattern="^[0-9]{2}$"
            defaultValue={state.month}
            onChange={(e) => setState({ ...state, month: e.target.value })}
          />
          <label htmlFor={monthId} className="mx-2">
            月
          </label>
        </div>
        <div className="form-group">
          <input
            name="day"
            id={dayId}
            type="text"
            className="form-control"
            size={4}
            pattern="^[0-9]{2}$"
            defaultValue={state.day}
            onChange={(e) => setState({ ...state, day: e.target.value })}
          />
          <label htmlFor={dayId} className="mx-2">
            日
          </label>
        </div>
      </div>
      <div className="form-inline mt-2">
        <div className="form-group">
          <input
            name="hour"
            id={hourId}
            type="text"
            className="form-control"
            size={4}
            pattern="^[0-9]{2}$"
            defaultValue={state.hour}
            onChange={(e) => setState({ ...state, hour: e.target.value })}
          />
          <label htmlFor={hourId} className="mx-2">
            時
          </label>
        </div>
        {scheduled ? (
          <div className="form-group">
            <select
              name="minute"
              id={minuteId}
              className="custom-select form-control"
              defaultValue={state.minute}
              onChange={(e) => setState({ ...state, minute: e.target.value })}
            >
              <option value="00">00</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
            <label htmlFor={minuteId} className="mx-2">
              分
            </label>
          </div>
        ) : (
          <>
            <div className="form-group">
              <input
                name="minute"
                id={minuteId}
                type="text"
                className="form-control"
                size={4}
                pattern="^[0-9]{2}$"
                defaultValue={state.minute}
                onChange={(e) => setState({ ...state, minute: e.target.value })}
              />
              <label htmlFor={minuteId} className="mx-2">
                分
              </label>
            </div>
            <div className="form-group">
              <input
                name="second"
                id={secondId}
                type="text"
                className="form-control"
                size={4}
                pattern="^[0-9]{2}$"
                defaultValue={state.second}
                onChange={(e) => setState({ ...state, second: e.target.value })}
              />
              <label htmlFor={secondId} className="mx-2">
                秒
              </label>
            </div>
          </>
        )}
      </div>
    </>
  )
}
