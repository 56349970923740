import { useState } from 'react'

let count = 0
export const useUniqueId = (num: number = 1) => {
  const [uniqueIds] = useState(() => {
    const tempUniqueIds = []
    for (let i = 0; i < num; i++) {
      tempUniqueIds.push(`uid_${count++}`)
    }
    return tempUniqueIds
  })

  return uniqueIds
}
