import React from 'react'

import { PagyDetailsFragment } from '../graphql/graphql'

type Props = {
  info?: PagyDetailsFragment
  onPageChange(page: number): void
}

// type State = {}

export const Pagination: React.FC<Props> = ({ info, onPageChange }) => {
  if (!info) return <></>
  const { count, from, to, prev, next, page, pages } = info
  const pageNumbers = []
  for (let i = Math.max(1, page - 2); i <= Math.min(page + 2, pages); i++) {
    pageNumbers.push(i)
  }
  return (
    <div className="row justify-content-between">
      <div className="col-auto">
        <div className="dataTables_info" role="status" aria-live="polite">
          {count} 件中 {from} 〜 {to} 件目を表示
        </div>
      </div>
      <div className="col-auto">
        <div className="dataTables_paginate paging_simple_numbers">
          <ul className="pagination">
            <li
              className={`paginate_button page-item previous ${
                prev ? '' : 'disabled'
              }`}
            >
              <a
                href="#"
                tabIndex={0}
                className="page-link"
                onClick={() => prev && onPageChange(prev)}
              >
                前へ
              </a>
            </li>
            {pageNumbers.map((number) => {
              return (
                <li
                  className={`paginate_button page-item ${
                    number === page ? 'active' : ''
                  }`}
                  key={number}
                >
                  <a
                    href="#"
                    tabIndex={0}
                    className="page-link"
                    onClick={() => number && onPageChange(number)}
                  >
                    {number}
                  </a>
                </li>
              )
            })}
            <li
              className={`paginate_button page-item next ${
                next ? '' : 'disabled'
              }`}
            >
              <a
                href="#"
                tabIndex={0}
                className="page-link"
                onClick={() => next && onPageChange(next)}
              >
                次へ
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
