import React, { ReactNode } from 'react'

type Props = Omit<
  React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >,
  'title'
> & {
  title: ReactNode
  required?: boolean
  badgeJpro?: boolean
  badgeShopify?: boolean
  badgeHondanaEbook?: boolean
  recommended?: boolean
}

// type State = {}

export const Label: React.FC<Props> = ({
  children,
  required,
  recommended,
  badgeJpro,
  badgeShopify,
  badgeHondanaEbook,
  title,
  ...props
}) => {
  return (
    <label {...props}>
      {required && <span className="badge badge-danger mr-2">必須</span>}
      {recommended && (
        <span
          className="badge badge-danger mr-2"
          style={{ backgroundColor: '#888' }}
        >
          必須
        </span>
      )}
      {title}
      {badgeJpro && (
        <span className="badge badge-dot badge-dot-sm badge-jpro ml-1">
          JPRO
        </span>
      )}
      {badgeShopify && (
        <span className="badge badge-dot badge-dot-sm badge-shopify ml-1">
          Shopify
        </span>
      )}
      {badgeHondanaEbook && (
        <span className="badge badge-dot badge-dot-sm badge-yd ml-1">
          HONDANA電子書籍
        </span>
      )}
      {children}
    </label>
  )
}
