import dayjs from 'dayjs'
import React, { useState, useEffect } from 'react'

import { useUniqueId } from '../hooks/uniqueId'

type Props = {
  day?: boolean
  defaultValue?: Date | null
  onChange?(value: Date | null): void
  className?: string
}

type State = {
  year: string
  month: string
  day: string
}

export const DateInput: React.FC<Props> = ({
  day = true,
  defaultValue,
  onChange,
  className = '',
  children,
}) => {
  const [yearId, monthId, dayId] = useUniqueId(3)
  const date = defaultValue ? dayjs(defaultValue) : null
  const [state, setState] = useState<State>({
    year: date ? date.format('YYYY') : '',
    month: date ? date.format('MM') : '',
    day: date ? date.format('DD') : day ? '' : '01',
  })

  useEffect(() => {
    if (onChange) {
      if (state.year && state.month && state.day) {
        onChange(dayjs(`${state.year}-${state.month}-${state.day}`).toDate())
      } else {
        onChange(null)
      }
    }
  }, [state])

  return (
    <div className={`form-inline ${className}`}>
      <div className="form-group">
        <input
          name="year"
          id={yearId}
          type="text"
          className="form-control"
          size={4}
          defaultValue={state.year}
          onChange={(e) => {
            e.persist()
            setState((oldState) => ({
              ...oldState,
              year: e.target.value,
            }))
          }}
          maxLength={4}
        />
        <label htmlFor={yearId} className="mx-2">
          年
        </label>
      </div>
      <div className="form-group">
        <input
          name="month"
          id={monthId}
          type="text"
          className="form-control"
          size={2}
          defaultValue={state.month}
          onChange={(e) => {
            e.persist()
            setState((oldState) => ({
              ...oldState,
              month: e.target.value,
            }))
          }}
          maxLength={2}
        />
        <label htmlFor={monthId} className="mx-2">
          月
        </label>
      </div>
      {day && (
        <div className="form-group">
          <input
            name="day"
            id={dayId}
            type="text"
            className="form-control"
            size={2}
            defaultValue={state.day}
            onChange={(e) => {
              e.persist()
              setState((oldState) => ({
                ...oldState,
                day: e.target.value,
              }))
            }}
            maxLength={2}
          />
          <label htmlFor={dayId} className="mx-2">
            日
          </label>
        </div>
      )}
      {children}
    </div>
  )
}
