import CKEditor from 'ckeditor4-react'
import React from 'react'

CKEditor.editorUrl = '/ckeditor/ckeditor.js'

export const WysiwygEditor: React.FC<any> = ({
  canUpload = true,
  ...ckEditorProps
}) => {
  return (
    <CKEditor
      config={{
        allowedContent: true,
        extraPlugins: ['sourcedialog'],
        removePlugins: ['uploadimage', 'sourcearea'],
        filebrowserBrowseUrl: canUpload && '/ckfinder/ckfinder.html',
      }}
      {...ckEditorProps}
    />
  )
}
