import RCTree, { TreeProps } from 'rc-tree'
import { DataNode } from 'rc-tree/lib/interface'
import React from 'react'

const setupTreeNode = (
  data: DataNode[],
  onAdd?: (item: any) => void,
  onEdit?: (item: any) => void,
  onRemove?: (item: any) => void
) =>
  data.map((datum: any) => {
    const item = Object.assign({}, datum)
    item.title = (
      <>
        {item.name}
        {onAdd && item.depth < 2 && (
          <button
            className="btn btn-sm btn-primary ml-3"
            onClick={(e) => {
              e.stopPropagation()
              onAdd(item)
            }}
          >
            追加
          </button>
        )}
        {onEdit && !item.uneditable && (
          <button
            type="button"
            className={`btn btn-secondary btn-sm ${
              item.depth < 2 ? 'ml-1' : 'ml-3'
            }`}
            onClick={(e) => {
              e.stopPropagation()
              onEdit(item)
            }}
          >
            編集
          </button>
        )}
        {onRemove && !item.uneditable && (
          <button
            type="button"
            className="btn btn-sm btn-light ml-1"
            onClick={(e) => {
              e.stopPropagation()
              onRemove(item)
            }}
          >
            削除
          </button>
        )}
      </>
    )
    if (item.children && item.children.length) {
      item.children = setupTreeNode(item.children, onAdd, onEdit, onRemove)
      return item
    }
    return item
  })

export const Tree: React.FC<
  Omit<TreeProps, 'prefixCls' | 'treeData'> & {
    data: DataNode[]
    onAdd?(item: any): void
    onEdit?(item: any): void
    onRemove?(item: any): void
  }
> = ({ data, onAdd, onEdit, onRemove, ...props }) => {
  const treeData = setupTreeNode(data, onAdd, onEdit, onRemove)

  return <RCTree {...props} treeData={treeData} />
}
