import mime from 'mime-types'
import React, { useRef, useEffect } from 'react'

type Props = React.DetailedHTMLProps<
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>,
  HTMLInputElement
> & {
  defaultFileUrl?: string | null
  onChange?(files: FileList): void
}

export const FileInput: React.FC<Props> = ({
  defaultFileUrl,
  onChange,
  ref,
  ...props
}) => {
  const file = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (defaultFileUrl) {
      fetch(defaultFileUrl)
        .then(async (response) => {
          const contentType = response.headers.get('content-type') || undefined
          const extension = contentType ? mime.extension(contentType) : 'jpg'
          return {
            blob: await response.blob(),
            contentType,
            extension,
          }
        })
        .then(
          ({ blob, contentType, extension }) =>
            new File([blob], `image.${extension}`, { type: contentType })
        )
        .then((file) => {
          const dt = new DataTransfer()
          dt.items.add(file)
          return dt.files
        })
        .then((files) => {
          if (file.current) {
            file.current.files = files
            onChange && onChange(files)
          }
        })
    }
  }, [defaultFileUrl])
  return (
    <input
      ref={file}
      {...props}
      onChange={(e) => {
        onChange && onChange(e.target.files as FileList)
      }}
    />
  )
}
