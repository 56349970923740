import React, { useEffect } from 'react'

type Props = {
  title: string
  icon: string
}

export const PageTitle: React.FC<Props> = ({ title, icon }) => {
  useEffect(() => {
    document.title = `${title} - HONDANA管理システム`
  }, [title])
  return (
    <div className="app-page-title">
      <div className="page-title-wrapper">
        <div className="page-title-heading">
          <div className="page-title-icon">
            <i className={`icon-gradient bg-primary ${icon}`} />
          </div>
          <div>{title}</div>
        </div>
      </div>
    </div>
  )
}
