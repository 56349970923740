import React from 'react'

import { serverState } from '../serverState'

type Props = {
  openedSidebar?: boolean
  handleSidebar?(): void
}

export const Logo: React.FC<Props> = ({ openedSidebar, handleSidebar }) => (
  <>
    <div className="app-header__logo">
      <div className={serverState.logoClassName} />
      <div className="header__pane ml-auto">
        <div>
          <button
            type="button"
            className={`hamburger close-sidebar-btn hamburger--elastic ${
              openedSidebar ? '' : 'is-active'
            }`}
            onClick={handleSidebar}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
      </div>
    </div>
    <div className="app-header__mobile-menu">
      <div>
        <button
          type="button"
          className="hamburger hamburger--elastic mobile-toggle-nav"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </div>
    </div>
    <div className="app-header__menu">
      <span>
        <button
          type="button"
          className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
        >
          <span className="btn-icon-wrapper">
            <i className="fa fa-ellipsis-v fa-w-6" />
          </span>
        </button>
      </span>
    </div>
  </>
)
