import React from 'react'

type Props = {
  errorMessages: string[]
}

export const ErrorMessage: React.FC<Props> = ({ errorMessages }) => (
  <>
    {errorMessages.length > 0 && (
      <div className="text-danger mt-1">{errorMessages[0]}</div>
    )}
  </>
)
