import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  DateTimeWithTimezone: any;
  JstDate: any;
  Upload: any;
};

export type Admin = {
  __typename?: 'Admin';
  info?: Maybe<Info>;
  infos?: Maybe<InfoPagy>;
  jproHistories?: Maybe<JproHistoryPagy>;
  operationLogs?: Maybe<OperationLogPagy>;
  publishers?: Maybe<PublisherPagy>;
};


export type AdminInfoArgs = {
  id: Scalars['ID'];
};


export type AdminInfosArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type AdminJproHistoriesArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type AdminOperationLogsArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  publisherId: Scalars['ID'];
  q?: Maybe<OperationLogSearchParams>;
};


export type AdminPublishersArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  q?: Maybe<PublisherSearchParams>;
};

export enum AudienceCodeValueEnum {
  /** 0～2歳 */
  Baby = 'baby',
  /** 小学全般 */
  ElementarySchool = 'elementarySchool',
  /** 小学低学年 */
  ElementarySchoolLowerGrade = 'elementarySchoolLowerGrade',
  /** 小学中学年 */
  ElementarySchoolMiddleGrade = 'elementarySchoolMiddleGrade',
  /** 小学高学年 */
  ElementarySchoolUpperGrade = 'elementarySchoolUpperGrade',
  /** 高校 */
  HighSchool = 'highSchool',
  /** 3～5歳 */
  Infant = 'infant',
  /** 未選択 */
  None = 'none',
  /** 中学以上 */
  SecondarySchool = 'secondarySchool'
}

export enum AudienceDescriptionEnum {
  /** 成人向け */
  Adult = 'adult',
  /** 成人向け（薬物） */
  Drug = 'drug',
  /** 成人向け（言語） */
  Language = 'language',
  /** 指定なし */
  None = 'none',
  /** 成人指定（理由明記なし） */
  Restrict = 'restrict',
  /** 成人向け（性） */
  Sex = 'sex',
  /** 成人向け（暴力） */
  Violence = 'violence'
}

export type Author = {
  __typename?: 'Author';
  books: BookConnection;
  booksCount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** ID */
  id: Scalars['ID'];
  /** 著者画像URL */
  imageUrl?: Maybe<Scalars['String']>;
  /** 著者名カナ */
  kana: Scalars['String'];
  /** 著者名 */
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 著者プロフィール */
  value?: Maybe<Scalars['String']>;
};


export type AuthorBooksArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withOtherFormat?: Maybe<Scalars['Boolean']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};

export enum AuthorCategory {
  Bun = 'bun',
  Cho = 'cho',
  E = 'e',
  Ga = 'ga',
  Genan = 'genan',
  Gensaku = 'gensaku',
  Hen = 'hen',
  Hencho = 'hencho',
  Henchu = 'henchu',
  Henyaku = 'henyaku',
  Illustration = 'illustration',
  Kaisetsu = 'kaisetsu',
  Kan = 'kan',
  Kanyaku = 'kanyaku',
  Kyakuhon = 'kyakuhon',
  Other = 'other',
  Photo = 'photo',
  Roudoku = 'roudoku',
  Sakkyoku = 'sakkyoku',
  Saku = 'saku',
  Yaku = 'yaku'
}

export type AuthorPagy = {
  __typename?: 'AuthorPagy';
  nodes: Array<Author>;
  pageInfo: Pagy;
};

export type AuthorSearchParams = {
  freeText?: Maybe<Scalars['String']>;
  /** 画像の有無 */
  image?: Maybe<Scalars['Boolean']>;
  /** 並び順 */
  sort?: Maybe<AuthorSort>;
  /** プロフィールの有無 */
  value?: Maybe<Scalars['Boolean']>;
};

export enum AuthorSort {
  BooksCountAsc = 'BOOKS_COUNT_ASC',
  BooksCountDesc = 'BOOKS_COUNT_DESC',
  KanaAsc = 'KANA_ASC',
  KanaDesc = 'KANA_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC'
}

export enum BandEnum {
  /** あり */
  Exist = 'exist',
  /** 未選択 */
  None = 'none',
  /** なし */
  NotExist = 'notExist'
}

export type Banner = {
  __typename?: 'Banner';
  createdAt: Scalars['DateTime'];
  /** 表示順 */
  displayOrder: Scalars['Int'];
  /** ID */
  id: Scalars['ID'];
  /** 画像のURL */
  imageUrl: Scalars['String'];
  /** altに使用 */
  name: Scalars['String'];
  /** 公開・非公開の判定 */
  publicStatus: PublicStatus;
  /** 外部リンクかどうかの判定 */
  target: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  /** リンク先 */
  url?: Maybe<Scalars['String']>;
};

/** The connection type for Banner. */
export type BannerConnection = {
  __typename?: 'BannerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BannerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Banner>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type BannerEdge = {
  __typename?: 'BannerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Banner>;
};

export type BigBanner = {
  __typename?: 'BigBanner';
  createdAt: Scalars['DateTime'];
  /** 表示順 */
  displayOrder: Scalars['Int'];
  /** ID */
  id: Scalars['ID'];
  /** 画像のURL */
  imageUrl: Scalars['String'];
  /** altに使用 */
  name: Scalars['String'];
  /** 公開・非公開の判定 */
  publicStatus: PublicStatus;
  /** サブ画像のURL */
  subImageUrl?: Maybe<Scalars['String']>;
  /** 外部リンクかどうかの判定 */
  target: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  /** リンク先 */
  url?: Maybe<Scalars['String']>;
};

/** The connection type for BigBanner. */
export type BigBannerConnection = {
  __typename?: 'BigBannerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BigBannerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BigBanner>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type BigBannerEdge = {
  __typename?: 'BigBannerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BigBanner>;
};

export enum BindingEnum {
  /** 未選択 */
  None = 'none',
  /** 中綴 */
  SaddleStitch = 'saddleStitch',
  /** 平綴 */
  Staple = 'staple'
}

export enum BindingKindEnum {
  /** ダブル */
  Double = 'double',
  /** 未選択 */
  None = 'none',
  /** シングル */
  Single = 'single'
}

export type Book = {
  __typename?: 'Book';
  /** 出版年月日 */
  bookDate?: Maybe<Scalars['DateTime']>;
  bookFormat?: Maybe<Scalars['String']>;
  bookFormatEnum?: Maybe<BookFormat>;
  bookFormatOther?: Maybe<Scalars['String']>;
  bookJpro?: Maybe<BookJpro>;
  bookSize?: Maybe<Scalars['String']>;
  bookSizeEnum?: Maybe<BookSize>;
  /** カート（有効／無効） */
  cartStatus: Scalars['Boolean'];
  /** Cコード */
  cCode?: Maybe<Scalars['String']>;
  /** 内容説明 */
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** 電子書籍設定 */
  ebookStatus: Scalars['Boolean'];
  ebookstores: BookEbookstoreConnection;
  /** 目次 */
  explain?: Maybe<Scalars['String']>;
  formatGroup?: Maybe<FormatGroup>;
  formatGroupId?: Maybe<Scalars['ID']>;
  formatPrimary: Scalars['Boolean'];
  freeItems: Array<FreeItem>;
  genres: Array<Genre>;
  genreTree: Array<Genre>;
  hondanaEbook?: Maybe<HondanaEbook>;
  /** ID */
  id: Scalars['ID'];
  /**
   * 書影
   * @deprecated imageUrlを使用するようにしてください
   */
  image?: Maybe<Scalars['String']>;
  /** 書影URL */
  imageUrl?: Maybe<Scalars['String']>;
  /** ISBN */
  isbn?: Maybe<Scalars['String']>;
  janCode?: Maybe<Scalars['String']>;
  jpro: Scalars['Boolean'];
  /** 書名カナ */
  kana?: Maybe<Scalars['String']>;
  /** キーワード */
  keyword?: Maybe<Scalars['String']>;
  /** @deprecated hondana_ebookに変更になります。 */
  latestHondanaEbook?: Maybe<HondanaEbook>;
  /** 雑誌コード */
  magazineCode?: Maybe<Scalars['String']>;
  /** 独自商品コード */
  managementCode?: Maybe<Scalars['String']>;
  measureHeight?: Maybe<Scalars['Int']>;
  measureThickness?: Maybe<Scalars['Int']>;
  measureWidth?: Maybe<Scalars['Int']>;
  /** 書名 */
  name: Scalars['String'];
  newOrder: Scalars['Int'];
  /** 新刊設定 */
  newStatus: Scalars['Boolean'];
  /** これから出る本設定 */
  nextBook: Scalars['Boolean'];
  opuses: OpusConnection;
  /** @deprecated format_groupを使ってください */
  otherFormats: Array<Book>;
  /** 概要（長文） */
  outline?: Maybe<Scalars['String']>;
  /** 概要（短文） */
  outlineAbr?: Maybe<Scalars['String']>;
  /** ページ数 */
  page?: Maybe<Scalars['Int']>;
  /** 本体価格 */
  price?: Maybe<Scalars['Int']>;
  /** 公開日時 */
  publicDate?: Maybe<Scalars['DateTime']>;
  /** 公開の状態 */
  publicStatus?: Maybe<PublicScheduledStatus>;
  /** おすすめ表示順 */
  recommendOrder?: Maybe<Scalars['Int']>;
  /** おすすめ紹介文 */
  recommendSentence?: Maybe<Scalars['String']>;
  /** おすすめ設定 */
  recommendStatus: Scalars['Boolean'];
  relatedBooks: BookConnection;
  relatedNewsPosts: NewsPostConnection;
  /** 書店発売日 */
  releaseDate?: Maybe<Scalars['DateTime']>;
  series: Array<Series>;
  seriesTree: Array<Series>;
  shopifyProductId?: Maybe<Scalars['String']>;
  stockStatus?: Maybe<Scalars['String']>;
  stockStatusEnum?: Maybe<StockStatus>;
  /** サブの書影URL */
  subImageUrls: Array<Scalars['String']>;
  /** サブタイトルカナ */
  subKana?: Maybe<Scalars['String']>;
  /** サブタイトル */
  subName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** 版 */
  version?: Maybe<Scalars['String']>;
  /** 巻次 */
  volume?: Maybe<Scalars['String']>;
  /** 試し読みURL */
  yondemillBookSalesUrl?: Maybe<Scalars['String']>;
};


export type BookEbookstoresArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type BookImageUrlArgs = {
  sizeType?: Maybe<ImageSize>;
};


export type BookOpusesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type BookOtherFormatsArgs = {
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type BookRelatedBooksArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type BookRelatedNewsPostsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type BookSubImageUrlsArgs = {
  limit?: Maybe<Scalars['Int']>;
  sizeType?: Maybe<ImageSize>;
};

/** The connection type for Book. */
export type BookConnection = {
  __typename?: 'BookConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BookEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Book>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BookEbookstore = {
  __typename?: 'BookEbookstore';
  book: Book;
  createdAt: Scalars['DateTime'];
  ebookstore: Ebookstore;
  /** ID */
  id: Scalars['ID'];
  /** 公開・非公開の判定 */
  publicStatus: PublicStatus;
  /** 電子書籍書店の検索結果URL */
  searchUrl: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 書籍ごとの電子書籍書店のURL */
  url: Scalars['String'];
};

/** The connection type for BookEbookstore. */
export type BookEbookstoreConnection = {
  __typename?: 'BookEbookstoreConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BookEbookstoreEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BookEbookstore>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type BookEbookstoreEdge = {
  __typename?: 'BookEbookstoreEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BookEbookstore>;
};

export type BookEbookstoreInput = {
  /** ID */
  ebookstoreId: Scalars['ID'];
  /** 公開・非公開の判定 */
  publicStatus: PublicStatus;
  /** 書籍ごとの電子書籍書店のURL */
  url?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type BookEdge = {
  __typename?: 'BookEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Book>;
};

export enum BookFormat {
  Bunko = 'bunko',
  Comic = 'comic',
  Ebook = 'ebook',
  Other = 'other',
  Shinsho = 'shinsho',
  Tankoubon = 'tankoubon'
}

export type BookFormatInput = {
  /** 書誌フォーマット */
  bookFormatEnum?: Maybe<BookFormat>;
  /** 書誌フォーマット その他 */
  bookFormatOther?: Maybe<Scalars['String']>;
  /** ID */
  bookId: Scalars['ID'];
};

export type BookJpro = {
  __typename?: 'BookJpro';
  /** 情報解禁日 */
  announcementDate?: Maybe<Scalars['JstDate']>;
  /** 対象読者 */
  audienceCodeValueEnum?: Maybe<AudienceCodeValueEnum>;
  /** 成人指定 */
  audienceDescriptionEnum?: Maybe<AudienceDescriptionEnum>;
  /** 受賞情報 */
  award?: Maybe<Scalars['String']>;
  /** 帯内容 */
  bandContents?: Maybe<Scalars['String']>;
  /** 帯（ムック） */
  bandEnum?: Maybe<BandEnum>;
  /** 綴じ */
  bindingEnum?: Maybe<BindingEnum>;
  /** 結束種類（シングルダブル） */
  bindingKindEnum?: Maybe<BindingKindEnum>;
  /** 製本所 */
  bindingPlace?: Maybe<Scalars['String']>;
  /** 装丁者名 */
  bondName?: Maybe<Scalars['String']>;
  /** 付属資料（CD／DVD）の館内外貸出可否 */
  cdDvdEnum?: Maybe<CdDvdEnum>;
  /** 児童書詳細ジャンル */
  childrensBookGenreEnum?: Maybe<ChildrensBookGenreEnum>;
  /** 担当者コメント */
  comments?: Maybe<Scalars['String']>;
  /** 類書・競合書 */
  competition?: Maybe<Scalars['String']>;
  /** 完結フラグ */
  completionEnum?: Maybe<CompletionEnum>;
  /** カバー（ムック） */
  coverEnum?: Maybe<CoverEnum>;
  createdAt: Scalars['DateTime'];
  /** 取次搬入予定日 */
  deliveryDate?: Maybe<Scalars['JstDate']>;
  /** 取次搬入予定日 */
  deliveryDateAmpmEnum?: Maybe<DeliveryDateAmpmEnum>;
  /** 各巻書名カナ */
  eachVolumeKana?: Maybe<Scalars['String']>;
  /** 各巻書名 */
  eachVolumeName?: Maybe<Scalars['String']>;
  /** 配本の有無 */
  extentEnum?: Maybe<ExtentEnum>;
  /** 配本回数 (回) */
  extentValue?: Maybe<Scalars['Int']>;
  /** 本文活字の大きさ （級数） */
  fontSize?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** JPRO書影連携日 */
  imageSentAt?: Maybe<Scalars['DateTime']>;
  /** レーベル名カナ */
  imprintKana?: Maybe<Scalars['String']>;
  /** レーベル名 */
  imprintName?: Maybe<Scalars['String']>;
  /** レーベル 巻次 */
  imprintVolume?: Maybe<Scalars['String']>;
  /** 取次会社取扱い */
  intermediaryCompanyHandlingEnum?: Maybe<IntermediaryCompanyHandlingEnum>;
  /** 扱い社 */
  intermediaryName?: Maybe<Scalars['String']>;
  /** 扱い社取引コード */
  intermediaryTransactionCode?: Maybe<Scalars['String']>;
  /** 発行年月 */
  issuedDate?: Maybe<Scalars['JstDate']>;
  /** 言語 */
  languageEnum?: Maybe<LanguageEnum>;
  /** 内容紹介 図書館選書用 */
  librarySelectionContent?: Maybe<Scalars['String']>;
  /** 内容紹介オンライン書店表示用 */
  longDescription?: Maybe<Scalars['String']>;
  /** 月号・号数表記 */
  monthlyIssue?: Maybe<Scalars['String']>;
  /** 情報ステータス （確定前 ／ 確定後 ／ 削除） */
  notificationTypeEnum?: Maybe<NotificationTypeEnum>;
  /** 結束数 */
  numberOfCohesions?: Maybe<Scalars['Int']>;
  /** 初版取次搬入予定部数 */
  numberOfFirstEdition?: Maybe<Scalars['Int']>;
  /** 旧版商品ISBN情報 */
  oldIsbn?: Maybe<Scalars['String']>;
  /** 発売協定日 */
  onSaleDate?: Maybe<Scalars['JstDate']>;
  /** その他特記事項 */
  otherNoticesEnum?: Maybe<OtherNoticesEnum>;
  /** マンガの割合 （ページ数） */
  percentageOfManga?: Maybe<Scalars['Int']>;
  /** 注文・申込締切 */
  preOrderLimit?: Maybe<Scalars['JstDate']>;
  /** 特価本体価格 */
  priceAmount?: Maybe<Scalars['Int']>;
  /** 特価期限 */
  priceEffectiveUntil?: Maybe<Scalars['JstDate']>;
  /** 付録の内容 */
  productFormDescription?: Maybe<Scalars['String']>;
  /** 制作特記項目 */
  productionNotesItemEnum?: Maybe<ProductionNotesItemEnum>;
  /** 刊行形態 */
  publicationFormEnum?: Maybe<PublicationFormEnum>;
  /** 取次広報誌掲載用 */
  publicityMagazinePublication?: Maybe<Scalars['String']>;
  /** 発売元出版社 */
  publisherName?: Maybe<Scalars['String']>;
  /** 発売元出版社記号 */
  publisherPrefix?: Maybe<Scalars['String']>;
  /** 発売元取引コード */
  publisherTransactionCode?: Maybe<Scalars['String']>;
  /** 読者書き込み */
  readersWriteEnum?: Maybe<ReadersWriteEnum>;
  /** 読者書き込みありの場合のページ数 */
  readersWritePage?: Maybe<Scalars['Int']>;
  /** 「これから出る本」用項目 著者表示 */
  recentPublicationAuthor?: Maybe<Scalars['String']>;
  /** 「これから出る本」用項目 内容紹介 */
  recentPublicationContent?: Maybe<Scalars['String']>;
  /** 「これから出る本」用項目 これ本掲載時期 */
  recentPublicationDate?: Maybe<Scalars['JstDate']>;
  /** 「これから出る本」用項目 これ本掲載時期（上期:01、下期:02） */
  recentPublicationPeriodEnum?: Maybe<RecentPublicationPeriodEnum>;
  /** 「これから出る本」用項目 読者対象 */
  recentPublicationReaderEnum?: Maybe<RecentPublicationReaderEnum>;
  /** 「これから出る本」用項目 分類 */
  recentPublicationTypeEnum?: Maybe<RecentPublicationTypeEnum>;
  relationshipWithOriginalBook?: Maybe<RelationshipWithOriginalBookEnum>;
  /** 再販 切り替え日 */
  resellingDate?: Maybe<Scalars['JstDate']>;
  /** 再販 */
  resellingEnum?: Maybe<ResellingEnum>;
  /** 返品期限・L表記 */
  returnDeadline?: Maybe<Scalars['JstDate']>;
  /** ルビの有無 */
  rubyEnum?: Maybe<RubyEnum>;
  /** 別送資料 */
  separateMaterial?: Maybe<Scalars['String']>;
  /** シリーズ名カナ */
  seriesKana?: Maybe<Scalars['String']>;
  /** シリーズ名 */
  seriesName?: Maybe<Scalars['String']>;
  /** シリーズ 巻次 */
  seriesVolume?: Maybe<Scalars['String']>;
  /** 特殊な装丁 */
  specialBinding?: Maybe<Scalars['String']>;
  /** ジャンルコード */
  subjectCodeEnum?: Maybe<SubjectCodeEnum>;
  /** サブタイトル 巻次 */
  subVolume?: Maybe<Scalars['String']>;
  /** 販売条件 */
  supplyRestrictionDetailEnum?: Maybe<SupplyRestrictionDetailEnum>;
  /** しかけの有無 */
  trick?: Maybe<Scalars['String']>;
  /** セット商品分売可否 （単品 ／ セット商品分売可 ／ セット商品分売不可） */
  unpricedItemTypeEnum?: Maybe<UnpricedItemTypeEnum>;
  updatedAt: Scalars['DateTime'];
  /** 重量 （グラム） */
  weight?: Maybe<Scalars['Int']>;
};

export type BookJproInput = {
  announcementDate?: Maybe<Scalars['JstDate']>;
  audienceCodeValueEnum?: Maybe<AudienceCodeValueEnum>;
  audienceDescriptionEnum?: Maybe<AudienceDescriptionEnum>;
  award?: Maybe<Scalars['String']>;
  bandContents?: Maybe<Scalars['String']>;
  bandEnum?: Maybe<BandEnum>;
  bindingEnum?: Maybe<BindingEnum>;
  bindingKindEnum?: Maybe<BindingKindEnum>;
  bindingPlace?: Maybe<Scalars['String']>;
  bondName?: Maybe<Scalars['String']>;
  cdDvdEnum?: Maybe<CdDvdEnum>;
  childrensBookGenreEnum?: Maybe<ChildrensBookGenreEnum>;
  comments?: Maybe<Scalars['String']>;
  competition?: Maybe<Scalars['String']>;
  completionEnum?: Maybe<CompletionEnum>;
  coverEnum?: Maybe<CoverEnum>;
  deliveryDate?: Maybe<Scalars['JstDate']>;
  deliveryDateAmpmEnum?: Maybe<DeliveryDateAmpmEnum>;
  eachVolumeKana?: Maybe<Scalars['String']>;
  eachVolumeName?: Maybe<Scalars['String']>;
  extentEnum?: Maybe<ExtentEnum>;
  extentValue?: Maybe<Scalars['Int']>;
  fontSize?: Maybe<Scalars['Int']>;
  imprintKana?: Maybe<Scalars['String']>;
  imprintName?: Maybe<Scalars['String']>;
  imprintVolume?: Maybe<Scalars['String']>;
  intermediaryCompanyHandlingEnum?: Maybe<IntermediaryCompanyHandlingEnum>;
  intermediaryName?: Maybe<Scalars['String']>;
  intermediaryTransactionCode?: Maybe<Scalars['String']>;
  issuedDate?: Maybe<Scalars['JstDate']>;
  languageEnum?: Maybe<LanguageEnum>;
  librarySelectionContent?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  monthlyIssue?: Maybe<Scalars['String']>;
  notificationTypeEnum?: Maybe<NotificationTypeEnum>;
  numberOfCohesions?: Maybe<Scalars['Int']>;
  numberOfFirstEdition?: Maybe<Scalars['Int']>;
  oldIsbn?: Maybe<Scalars['String']>;
  onSaleDate?: Maybe<Scalars['JstDate']>;
  otherNoticesEnum?: Maybe<OtherNoticesEnum>;
  percentageOfManga?: Maybe<Scalars['Int']>;
  preOrderLimit?: Maybe<Scalars['JstDate']>;
  priceAmount?: Maybe<Scalars['Int']>;
  priceEffectiveUntil?: Maybe<Scalars['JstDate']>;
  productFormDescription?: Maybe<Scalars['String']>;
  productionNotesItemEnum?: Maybe<ProductionNotesItemEnum>;
  publicationFormEnum?: Maybe<PublicationFormEnum>;
  publicityMagazinePublication?: Maybe<Scalars['String']>;
  publisherName?: Maybe<Scalars['String']>;
  publisherPrefix?: Maybe<Scalars['String']>;
  publisherTransactionCode?: Maybe<Scalars['String']>;
  readersWriteEnum?: Maybe<ReadersWriteEnum>;
  readersWritePage?: Maybe<Scalars['Int']>;
  recentPublicationAuthor?: Maybe<Scalars['String']>;
  recentPublicationContent?: Maybe<Scalars['String']>;
  recentPublicationDate?: Maybe<Scalars['JstDate']>;
  recentPublicationPeriodEnum?: Maybe<RecentPublicationPeriodEnum>;
  recentPublicationReaderEnum?: Maybe<RecentPublicationReaderEnum>;
  recentPublicationTypeEnum?: Maybe<RecentPublicationTypeEnum>;
  relationshipWithOriginalBook?: Maybe<RelationshipWithOriginalBookEnum>;
  resellingDate?: Maybe<Scalars['JstDate']>;
  resellingEnum?: Maybe<ResellingEnum>;
  returnDeadline?: Maybe<Scalars['JstDate']>;
  rubyEnum?: Maybe<RubyEnum>;
  separateMaterial?: Maybe<Scalars['String']>;
  seriesKana?: Maybe<Scalars['String']>;
  seriesName?: Maybe<Scalars['String']>;
  seriesVolume?: Maybe<Scalars['String']>;
  specialBinding?: Maybe<Scalars['String']>;
  subjectCodeEnum?: Maybe<SubjectCodeEnum>;
  subVolume?: Maybe<Scalars['String']>;
  supplyRestrictionDetailEnum?: Maybe<SupplyRestrictionDetailEnum>;
  trick?: Maybe<Scalars['String']>;
  unpricedItemTypeEnum?: Maybe<UnpricedItemTypeEnum>;
  weight?: Maybe<Scalars['Int']>;
};

export type BookPagy = {
  __typename?: 'BookPagy';
  nodes: Array<Book>;
  pageInfo: Pagy;
};

export type BookSearchParams = {
  /** 著者名 */
  author?: Maybe<Scalars['String']>;
  /** 出版年月日終了日 */
  bookDateEnd?: Maybe<Scalars['DateTimeWithTimezone']>;
  /** 出版年月日開始日 */
  bookDateStart?: Maybe<Scalars['DateTimeWithTimezone']>;
  /** 電子書籍設定 */
  ebookStatus?: Maybe<Scalars['Boolean']>;
  /** 除外ジャンルID */
  exclusionGenres?: Maybe<Array<Scalars['ID']>>;
  /** 除外シリーズID */
  exclusionSeries?: Maybe<Array<Scalars['ID']>>;
  /** フリー項目 */
  freeItem?: Maybe<FreeItemSearchParams>;
  freeText?: Maybe<Scalars['String']>;
  /** ジャンルID */
  genre?: Maybe<Scalars['ID']>;
  /** ジャンルID OR検索リスト */
  genreIn?: Maybe<Array<Scalars['ID']>>;
  /** ジャンルIDリスト */
  genreList?: Maybe<Array<Scalars['ID']>>;
  /** 画像の有無 */
  image?: Maybe<Scalars['Boolean']>;
  /** ISBN */
  isbn?: Maybe<Scalars['String']>;
  /** JANコード */
  janCode?: Maybe<Scalars['String']>;
  /** JPRO連携の有無 */
  jpro?: Maybe<Scalars['Boolean']>;
  /** 雑誌コード */
  magazineCode?: Maybe<Scalars['String']>;
  /** タイトル */
  name?: Maybe<Scalars['String']>;
  /** 新刊 */
  newStatus?: Maybe<Scalars['Boolean']>;
  /** 近刊 */
  nextBook?: Maybe<Scalars['Boolean']>;
  /** 公開の状態 */
  publicStatus?: Maybe<PublicScheduledStatus>;
  /** おすすめ設定 */
  recommendStatus?: Maybe<Scalars['Boolean']>;
  /** 書店発売日終了日 */
  releaseDateEnd?: Maybe<Scalars['DateTimeWithTimezone']>;
  /** 書店発売日開始日 */
  releaseDateStart?: Maybe<Scalars['DateTimeWithTimezone']>;
  /** シリーズID */
  series?: Maybe<Scalars['ID']>;
  /** シリーズID OR検索リスト */
  seriesIn?: Maybe<Array<Scalars['ID']>>;
  /** シリーズIDリスト */
  seriesList?: Maybe<Array<Scalars['ID']>>;
  /** 並び順 */
  sort?: Maybe<BookSort>;
  /** 在庫ステータス */
  stockStatus?: Maybe<StockStatus>;
  /** 試し読み */
  trial?: Maybe<Scalars['Boolean']>;
};

export enum BookSize {
  A4 = 'a4',
  A4hen = 'a4hen',
  A5 = 'a5',
  A5hen = 'a5hen',
  A6 = 'a6',
  A6hen = 'a6hen',
  Ab = 'ab',
  B4 = 'b4',
  B5 = 'b5',
  B5hen = 'b5hen',
  B6 = 'b6',
  B6hen = 'b6hen',
  B7 = 'b7',
  Bunko = 'bunko',
  Kikubaiban = 'kikubaiban',
  Kikuban = 'kikuban',
  Kikubanhen = 'kikubanhen',
  None = 'none',
  Other = 'other',
  Shinsho = 'shinsho',
  Shiroku = 'shiroku',
  Shirokuhen = 'shirokuhen'
}

export enum BookSort {
  BookDateAsc = 'BOOK_DATE_ASC',
  BookDateDesc = 'BOOK_DATE_DESC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IsbnAsc = 'ISBN_ASC',
  IsbnDesc = 'ISBN_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC'
}

export enum BulkUpdateBooksCsvKeyEnum {
  /** BookID */
  BookId = 'bookId',
  /** ISBN */
  Isbn = 'isbn',
  /** JANコード */
  JanCode = 'janCode',
  /** 独自商品コード */
  ManagementCode = 'managementCode'
}

/** Autogenerated input type of BulkUpdateBooks */
export type BulkUpdateBooksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  csvFile: Scalars['Upload'];
  csvKey?: Maybe<BulkUpdateBooksCsvKeyEnum>;
};

/** Autogenerated return type of BulkUpdateBooks */
export type BulkUpdateBooksPayload = {
  __typename?: 'BulkUpdateBooksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  publisher?: Maybe<Publisher>;
};

export enum CdDvdEnum {
  /** 未選択 */
  None = 'none',
  /** 不可 */
  NotRentable = 'notRentable',
  /** 館内のみ */
  OnlyInTheLibrary = 'onlyInTheLibrary',
  /** 可 */
  Rentable = 'rentable'
}

export enum ChildrensBookGenreEnum {
  /** まんが */
  Comic = 'comic',
  /** フィクション */
  Fiction = 'fiction',
  /** 幼児向 */
  ForInfants = 'forInfants',
  /** 未選択 */
  None = 'none',
  /** ノンフィクション */
  NonFiction = 'nonFiction',
  /** その他 */
  Other = 'other',
  /** 絵本 */
  PictureBook = 'pictureBook',
  /** しかけ絵本 */
  PopUpBook = 'popUpBook'
}

export type Company = {
  __typename?: 'Company';
  category: CompanyCategory;
  /** @deprecated categoryを使ってください */
  companyCategory: CompanyCategory;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 会社情報タイトル */
  name: Scalars['String'];
  /** 公開日時 */
  publicDate?: Maybe<Scalars['DateTime']>;
  /** 公開ステータス */
  publicStatus: PublicScheduledStatus;
  /** 予約投稿日時 */
  reservationDate?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  /** 会社情報内容 */
  value: Scalars['String'];
};

export type CompanyCategory = {
  __typename?: 'CompanyCategory';
  branches: Array<CompanyCategory>;
  companies: CompanyConnection;
  companiesCount: Scalars['Int'];
  /** @deprecated fixCategoryを使ってください */
  companyFixCategory: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** 深度 （階層構造） */
  depth: Scalars['Int'];
  fixCategory: CompanyFixCategory;
  /** ID */
  id: Scalars['ID'];
  /** company_fix_categoryかどうか */
  isFix: Scalars['Boolean'];
  /** カテゴリ名 */
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type CompanyCategoryCompaniesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Company. */
export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CompanyEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Company>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Company>;
};

export enum CompanyFixCategory {
  AccessMap = 'accessMap',
  History = 'history',
  OrderGuide = 'orderGuide',
  Others = 'others',
  Overview = 'overview',
  PrivacyPolicy = 'privacyPolicy',
  Recruit = 'recruit',
  Sitemap = 'sitemap',
  SitePolicy = 'sitePolicy'
}

export type CompanyPagy = {
  __typename?: 'CompanyPagy';
  nodes: Array<Company>;
  pageInfo: Pagy;
};

export type CompanySearchParams = {
  /** ID */
  categoryId?: Maybe<Scalars['ID']>;
  /** タイトル */
  name?: Maybe<Scalars['String']>;
  /** 公開ステータス */
  publicStatus?: Maybe<PublicScheduledStatus>;
  /** 並び順 */
  sort?: Maybe<CompanySort>;
};

export enum CompanySort {
  CreatedAtDesc = 'CREATED_AT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PublicDateAsc = 'PUBLIC_DATE_ASC',
  PublicDateDesc = 'PUBLIC_DATE_DESC'
}

export enum CompletionEnum {
  /** 完結 */
  Complete = 'complete',
  /** 未完 */
  Incomplete = 'incomplete',
  /** 未選択 */
  None = 'none'
}

export enum ConvertStatusEnum {
  /** 失敗 */
  Failure = 'failure',
  /** 処理中 */
  Processing = 'processing',
  /** 処理待ち */
  Queued = 'queued',
  /** 成功 */
  Success = 'success',
  /** システムエラー */
  SystemError = 'systemError',
  /** 待機中 */
  Waiting = 'waiting'
}

export enum CoverEnum {
  /** あり */
  Exist = 'exist',
  /** 未選択 */
  None = 'none',
  /** なし */
  NotExist = 'notExist'
}



/** Autogenerated input type of DeleteAuthors */
export type DeleteAuthorsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of DeleteAuthors */
export type DeleteAuthorsPayload = {
  __typename?: 'DeleteAuthorsPayload';
  authors: Array<Author>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteBanners */
export type DeleteBannersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of DeleteBanners */
export type DeleteBannersPayload = {
  __typename?: 'DeleteBannersPayload';
  banners: Array<Banner>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteBigBanners */
export type DeleteBigBannersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of DeleteBigBanners */
export type DeleteBigBannersPayload = {
  __typename?: 'DeleteBigBannersPayload';
  bigBanners: Array<BigBanner>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteBooks */
export type DeleteBooksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of DeleteBooks */
export type DeleteBooksPayload = {
  __typename?: 'DeleteBooksPayload';
  books: Array<Book>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteCompanies */
export type DeleteCompaniesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of DeleteCompanies */
export type DeleteCompaniesPayload = {
  __typename?: 'DeleteCompaniesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  companies: Array<Company>;
};

/** Autogenerated input type of DeleteCompanyCategory */
export type DeleteCompanyCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteCompanyCategory */
export type DeleteCompanyCategoryPayload = {
  __typename?: 'DeleteCompanyCategoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  companyCategory: CompanyCategory;
};

/** Autogenerated input type of DeleteGenre */
export type DeleteGenreInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteGenre */
export type DeleteGenrePayload = {
  __typename?: 'DeleteGenrePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  genre: Genre;
};

/** Autogenerated input type of DeleteHondanaEbook */
export type DeleteHondanaEbookInput = {
  bookId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteHondanaEbook */
export type DeleteHondanaEbookPayload = {
  __typename?: 'DeleteHondanaEbookPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  hondanaEbook: HondanaEbook;
};

/** Autogenerated input type of DeleteInfos */
export type DeleteInfosInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of DeleteInfos */
export type DeleteInfosPayload = {
  __typename?: 'DeleteInfosPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  infos: Array<Info>;
};

/** Autogenerated input type of DeleteNewsCategory */
export type DeleteNewsCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteNewsCategory */
export type DeleteNewsCategoryPayload = {
  __typename?: 'DeleteNewsCategoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  newsCategory: NewsCategory;
};

/** Autogenerated input type of DeleteNewsPosts */
export type DeleteNewsPostsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of DeleteNewsPosts */
export type DeleteNewsPostsPayload = {
  __typename?: 'DeleteNewsPostsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  newsPosts: Array<NewsPost>;
};

/** Autogenerated input type of DeleteSeries */
export type DeleteSeriesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteSeries */
export type DeleteSeriesPayload = {
  __typename?: 'DeleteSeriesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  series: Series;
};

export enum DeliveryDateAmpmEnum {
  Am = 'am',
  /** 未選択 */
  None = 'none',
  Pm = 'pm'
}

export type Ebookstore = {
  __typename?: 'Ebookstore';
  /** サイト文字コード */
  charset?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** ID */
  id: Scalars['ID'];
  /** 電子書籍書店名 */
  name: Scalars['String'];
  /** フリーワード検索URL */
  searchUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** サイトURL */
  url: Scalars['String'];
};

/** Autogenerated input type of EditAdminPublisher */
export type EditAdminPublisherInput = {
  /** 住所 */
  address?: Maybe<Scalars['String']>;
  /** アフィリエイト（amazon）ID */
  amazonAssociatesId?: Maybe<Scalars['String']>;
  authUid?: Maybe<Scalars['String']>;
  /** ビッグバナーステータス */
  bannerBigStatus?: Maybe<Scalars['Boolean']>;
  /** キャッチフレーズ */
  catchphrase?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** お問い合わせ用メールアドレス */
  contactMail?: Maybe<Scalars['String']>;
  /** コピーライト */
  copyright?: Maybe<Scalars['String']>;
  /** 会社説明 */
  description?: Maybe<Scalars['String']>;
  /** Facebookアカウント */
  facebook?: Maybe<Scalars['String']>;
  /** FAX番号 */
  fax?: Maybe<Scalars['String']>;
  /** フリー項目 */
  freeItemsAttributes: Array<PublisherFreeItemInput>;
  /** 部署 */
  fromPersonUnit?: Maybe<Scalars['String']>;
  /** グーグルAnalytics用タグ （アクセス解析タグ） */
  googleAnalyticsTag?: Maybe<Scalars['String']>;
  /** 会社コード */
  id: Scalars['ID'];
  /** Instagramユーザーネーム */
  instagram?: Maybe<Scalars['String']>;
  /** JPRO連携 */
  jpro?: Maybe<Scalars['Boolean']>;
  /** 出版社名カナ */
  kana?: Maybe<Scalars['String']>;
  /** データ連携担当者メールアドレス */
  linkagePersonMail?: Maybe<Scalars['String']>;
  /** データ連携担当者名 */
  linkagePersonName?: Maybe<Scalars['String']>;
  /** ロゴ画像 */
  logo?: Maybe<Scalars['Upload']>;
  /** LP用お知らせカテゴリID */
  lpNewsCategoryId?: Maybe<Scalars['String']>;
  /** 出版社名 */
  name?: Maybe<Scalars['String']>;
  /** 記事公開URL指定ステータス */
  newsPublicUrlStatus?: Maybe<Scalars['Boolean']>;
  /** 書誌ページOGP画像生成ステータス */
  ogimageStatus?: Maybe<Scalars['Boolean']>;
  ogpDescription?: Maybe<Scalars['String']>;
  ogpImage?: Maybe<Scalars['Upload']>;
  ogpTitle?: Maybe<Scalars['String']>;
  /** ROOTのコンテンツオーナーID */
  ownerId?: Maybe<Scalars['String']>;
  /** 担当者メールアドレス */
  personMail?: Maybe<Scalars['String']>;
  /** 担当者 */
  personName?: Maybe<Scalars['String']>;
  /** ISBN 出版社記号 */
  publisherPrefix?: Maybe<Scalars['String']>;
  /** ログインアカウント（出版社ユーザー） */
  publisherUsersAttributes: Array<PublisherUserInput>;
  /** アフィリエイト（楽天）ID */
  rakutenAffiliateId?: Maybe<Scalars['String']>;
  removeLogo?: Maybe<Scalars['Boolean']>;
  removeOgpImage?: Maybe<Scalars['Boolean']>;
  /** 7&Y用アフィリエイト基本URL */
  sevenAndYUrl?: Maybe<Scalars['String']>;
  /** Shopify ストアフロント アクセストークン */
  shopifyStorefrontAccessToken?: Maybe<Scalars['String']>;
  /** 電話番号 */
  tel?: Maybe<Scalars['String']>;
  /** 取引コード */
  transactionCode?: Maybe<Scalars['String']>;
  /** Twitterアカウント */
  twitter?: Maybe<Scalars['String']>;
  /** URL */
  url?: Maybe<Scalars['String']>;
  /** YONDEMILL 販売システム連携 */
  yondemillBookSales?: Maybe<Scalars['Boolean']>;
  /** 郵便番号 */
  zipcode?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of EditAdminPublisher */
export type EditAdminPublisherPayload = {
  __typename?: 'EditAdminPublisherPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  publisher: Publisher;
};

/** Autogenerated input type of EditAuthor */
export type EditAuthorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  kana: Scalars['String'];
  name: Scalars['String'];
  removeImage?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of EditAuthor */
export type EditAuthorPayload = {
  __typename?: 'EditAuthorPayload';
  author: Author;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of EditBanner */
export type EditBannerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  /** altに使用 */
  name: Scalars['String'];
  /** 公開・非公開の判定 */
  publicStatus: PublicStatus;
  /** 外部リンクかどうかの判定 */
  target: Scalars['Boolean'];
  /** リンク先 */
  url: Scalars['String'];
};

/** Autogenerated return type of EditBanner */
export type EditBannerPayload = {
  __typename?: 'EditBannerPayload';
  banner: Banner;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of EditBanners */
export type EditBannersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
  publicStatus?: Maybe<PublicStatus>;
};

/** Autogenerated return type of EditBanners */
export type EditBannersPayload = {
  __typename?: 'EditBannersPayload';
  banners: Array<Banner>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of EditBigBanner */
export type EditBigBannerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  /** altに使用 */
  name: Scalars['String'];
  /** 公開・非公開の判定 */
  publicStatus: PublicStatus;
  removeSubImage?: Maybe<Scalars['Boolean']>;
  subImage?: Maybe<Scalars['Upload']>;
  /** 外部リンクかどうかの判定 */
  target: Scalars['Boolean'];
  /** リンク先 */
  url: Scalars['String'];
};

/** Autogenerated return type of EditBigBanner */
export type EditBigBannerPayload = {
  __typename?: 'EditBigBannerPayload';
  bigBanner: BigBanner;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of EditBigBanners */
export type EditBigBannersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
  publicStatus?: Maybe<PublicStatus>;
};

/** Autogenerated return type of EditBigBanners */
export type EditBigBannersPayload = {
  __typename?: 'EditBigBannersPayload';
  bigBanners: Array<BigBanner>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of EditBook */
export type EditBookInput = {
  /** 出版年月日 */
  bookDate?: Maybe<Scalars['DateTime']>;
  bookEbookstores?: Maybe<Array<BookEbookstoreInput>>;
  bookFormatEnum?: Maybe<BookFormat>;
  bookFormatOther?: Maybe<Scalars['String']>;
  /** 書誌ID */
  bookIds?: Maybe<Array<Scalars['ID']>>;
  bookJproAttributes?: Maybe<BookJproInput>;
  bookSizeEnum?: Maybe<BookSize>;
  /** カート（有効／無効） */
  cartStatus?: Maybe<Scalars['Boolean']>;
  /** Cコード */
  cCode?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 内容説明 */
  content?: Maybe<Scalars['String']>;
  /** 電子書籍設定 */
  ebookStatus?: Maybe<Scalars['Boolean']>;
  /** 目次 */
  explain?: Maybe<Scalars['String']>;
  /** 書誌フォーマット プライマリーブックID */
  formatPrimaryId?: Maybe<Scalars['ID']>;
  /** フリー項目（シリアライズして保存 */
  freeitem?: Maybe<Scalars['String']>;
  freeItems?: Maybe<Array<FreeItemInput>>;
  /** ジャンルID */
  genreIds?: Maybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  /** ISBN */
  isbn?: Maybe<Scalars['String']>;
  /** JANコード */
  janCode?: Maybe<Scalars['String']>;
  jpro?: Maybe<Scalars['Boolean']>;
  /** 書名カナ */
  kana?: Maybe<Scalars['String']>;
  /** キーワード */
  keyword?: Maybe<Scalars['String']>;
  /** 雑誌コード */
  magazineCode?: Maybe<Scalars['String']>;
  /** 独自商品コード */
  managementCode?: Maybe<Scalars['String']>;
  measureHeight?: Maybe<Scalars['Int']>;
  measureThickness?: Maybe<Scalars['Int']>;
  measureWidth?: Maybe<Scalars['Int']>;
  /** 書名 */
  name?: Maybe<Scalars['String']>;
  /** 新刊設定 */
  newStatus?: Maybe<Scalars['Boolean']>;
  /** これから出る本設定 */
  nextBook?: Maybe<Scalars['Boolean']>;
  opuses?: Maybe<Array<OpusInput>>;
  otherFormats?: Maybe<Array<BookFormatInput>>;
  /** 概要（長文） */
  outline?: Maybe<Scalars['String']>;
  /** 概要（短文） */
  outlineAbr?: Maybe<Scalars['String']>;
  /** ページ数 */
  page?: Maybe<Scalars['Int']>;
  /** 本体価格 */
  price?: Maybe<Scalars['Int']>;
  /** 公開日時 */
  publicDate?: Maybe<Scalars['DateTime']>;
  /** 公開の状態 （公開／非公開） */
  publicStatus?: Maybe<PublicScheduledStatus>;
  /** おすすめ表示順 */
  recommendOrder?: Maybe<Scalars['Int']>;
  /** おすすめ紹介文 */
  recommendSentence?: Maybe<Scalars['String']>;
  /** おすすめ設定 */
  recommendStatus?: Maybe<Scalars['Boolean']>;
  /** 書店発売日 */
  releaseDate?: Maybe<Scalars['DateTime']>;
  removeImage?: Maybe<Scalars['Boolean']>;
  removeSubImages?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  /** シリーズID */
  seriesIds?: Maybe<Array<Scalars['ID']>>;
  /** 在庫ステータス */
  stockStatusEnum?: Maybe<StockStatus>;
  subImages?: Maybe<Array<Maybe<Scalars['Upload']>>>;
  /** サブタイトルカナ */
  subKana?: Maybe<Scalars['String']>;
  /** サブタイトル */
  subName?: Maybe<Scalars['String']>;
  /** 版 */
  version?: Maybe<Scalars['String']>;
  /** 巻次 */
  volume?: Maybe<Scalars['String']>;
  /** 試し読みURL */
  yondemillBookSalesUrl?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of EditBook */
export type EditBookPayload = {
  __typename?: 'EditBookPayload';
  book?: Maybe<Book>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of EditBooks */
export type EditBooksInput = {
  /** カート（有効／無効） */
  cartStatus?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 削除するジャンルID */
  deleteGenreId?: Maybe<Scalars['ID']>;
  /** 削除するシリーズID */
  deleteSeriesId?: Maybe<Scalars['ID']>;
  /** 在庫ステータスを削除する */
  deleteStockStatus?: Maybe<Scalars['Boolean']>;
  /** ジャンルID */
  genreId?: Maybe<Scalars['ID']>;
  ids: Array<Scalars['ID']>;
  /** 新刊設定 */
  newStatus?: Maybe<Scalars['Boolean']>;
  /** これから出る本設定 */
  nextBook?: Maybe<Scalars['Boolean']>;
  /** 公開の状態 （公開／非公開） */
  publicStatus?: Maybe<PublicStatus>;
  /** おすすめ設定 */
  recommendStatus?: Maybe<Scalars['Boolean']>;
  /** シリーズID */
  seriesId?: Maybe<Scalars['ID']>;
  /** 在庫ステータス */
  stockStatus?: Maybe<StockStatus>;
};

/** Autogenerated return type of EditBooks */
export type EditBooksPayload = {
  __typename?: 'EditBooksPayload';
  books: Array<Book>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of EditCompanies */
export type EditCompaniesInput = {
  categoryId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
  publicStatus?: Maybe<PublicScheduledStatus>;
};

/** Autogenerated return type of EditCompanies */
export type EditCompaniesPayload = {
  __typename?: 'EditCompaniesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  companies: Array<Company>;
};

/** Autogenerated input type of EditCompanyCategory */
export type EditCompanyCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of EditCompanyCategory */
export type EditCompanyCategoryPayload = {
  __typename?: 'EditCompanyCategoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  companyCategory: CompanyCategory;
};

/** Autogenerated input type of EditCompany */
export type EditCompanyInput = {
  /** カテゴリID */
  categoryId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** タイトル */
  name: Scalars['String'];
  /** 公開日時 */
  publicDate?: Maybe<Scalars['DateTime']>;
  publicStatus?: Maybe<PublicScheduledStatus>;
  /** 予約投稿日時 */
  reservationDate?: Maybe<Scalars['DateTime']>;
  /** 内容 */
  value: Scalars['String'];
};

/** Autogenerated return type of EditCompany */
export type EditCompanyPayload = {
  __typename?: 'EditCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  company: Company;
};

/** Autogenerated input type of EditGenre */
export type EditGenreInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of EditGenre */
export type EditGenrePayload = {
  __typename?: 'EditGenrePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  genre: Genre;
};

/** Autogenerated input type of EditHondanaEbook */
export type EditHondanaEbookInput = {
  bookId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  renditionLayout?: Maybe<RenditionLayoutEnum>;
  trialPublicStatus?: Maybe<PublicStatus>;
  trialRange?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of EditHondanaEbook */
export type EditHondanaEbookPayload = {
  __typename?: 'EditHondanaEbookPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  fileUploadUrl?: Maybe<Scalars['String']>;
  hondanaEbook: HondanaEbook;
  imageUploadUrl?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of EditInfo */
export type EditInfoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** タイトル */
  name: Scalars['String'];
  /** 公開日時 */
  publicDate: Scalars['DateTime'];
  /** 公開ステータス */
  publicStatus: PublicStatus;
  /** 内容 */
  value: Scalars['String'];
};

/** Autogenerated return type of EditInfo */
export type EditInfoPayload = {
  __typename?: 'EditInfoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  info: Info;
};

/** Autogenerated input type of EditNewsCategory */
export type EditNewsCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of EditNewsCategory */
export type EditNewsCategoryPayload = {
  __typename?: 'EditNewsCategoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  newsCategory: NewsCategory;
};

/** Autogenerated input type of EditNewsPost */
export type EditNewsPostInput = {
  /** 関連書籍のID */
  bookIds?: Maybe<Array<Scalars['ID']>>;
  /** カテゴリID */
  categoryId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** タイトル */
  name: Scalars['String'];
  naviDisplay?: Maybe<Scalars['Boolean']>;
  /** 記事の日付 */
  publicDate?: Maybe<Scalars['DateTime']>;
  /** 公開・非公開の判定 */
  publicStatus?: Maybe<PublicScheduledStatus>;
  /** 公開URL */
  publicUrl?: Maybe<Scalars['String']>;
  /** 予約投稿日時 */
  reservationDate?: Maybe<Scalars['DateTime']>;
  /** 内容 */
  value: Scalars['String'];
};

/** Autogenerated return type of EditNewsPost */
export type EditNewsPostPayload = {
  __typename?: 'EditNewsPostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  newsPost: NewsPost;
};

/** Autogenerated input type of EditNewsPosts */
export type EditNewsPostsInput = {
  categoryId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
  naviDisplay?: Maybe<Scalars['Boolean']>;
  publicStatus?: Maybe<PublicStatus>;
};

/** Autogenerated return type of EditNewsPosts */
export type EditNewsPostsPayload = {
  __typename?: 'EditNewsPostsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  newsPosts: Array<NewsPost>;
};

/** Autogenerated input type of EditPublisherEbookstores */
export type EditPublisherEbookstoresInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
  publicStatus?: Maybe<PublicStatus>;
};

/** Autogenerated return type of EditPublisherEbookstores */
export type EditPublisherEbookstoresPayload = {
  __typename?: 'EditPublisherEbookstoresPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  publisherEbookstores: Array<PublisherEbookstore>;
};

/** Autogenerated input type of EditPublisher */
export type EditPublisherInput = {
  /** 住所 */
  address?: Maybe<Scalars['String']>;
  /** キャッチフレーズ */
  catchphrase?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** お問い合わせ用メールアドレス */
  contactMail?: Maybe<Scalars['String']>;
  /** コピーライト */
  copyright?: Maybe<Scalars['String']>;
  /** 会社説明 */
  description?: Maybe<Scalars['String']>;
  /** Facebookアカウント */
  facebook?: Maybe<Scalars['String']>;
  /** FAX番号 */
  fax?: Maybe<Scalars['String']>;
  /** 部署 */
  fromPersonUnit?: Maybe<Scalars['String']>;
  /** Instagramユーザーネーム */
  instagram?: Maybe<Scalars['String']>;
  /** 出版社名カナ */
  kana?: Maybe<Scalars['String']>;
  /** データ連携担当者メールアドレス */
  linkagePersonMail?: Maybe<Scalars['String']>;
  /** データ連携担当者名 */
  linkagePersonName?: Maybe<Scalars['String']>;
  /** ロゴ画像 */
  logo?: Maybe<Scalars['Upload']>;
  /** LP用お知らせカテゴリID */
  lpNewsCategoryId?: Maybe<Scalars['String']>;
  /** 出版社名 */
  name?: Maybe<Scalars['String']>;
  /** 記事公開URL指定ステータス */
  newsPublicUrlStatus?: Maybe<Scalars['Boolean']>;
  /** 書誌ページOGP画像生成ステータス */
  ogimageStatus?: Maybe<Scalars['Boolean']>;
  ogpDescription?: Maybe<Scalars['String']>;
  ogpImage?: Maybe<Scalars['Upload']>;
  ogpTitle?: Maybe<Scalars['String']>;
  /** 担当者メールアドレス */
  personMail?: Maybe<Scalars['String']>;
  /** 担当者 */
  personName?: Maybe<Scalars['String']>;
  /** ISBN 出版社記号 */
  publisherPrefix?: Maybe<Scalars['String']>;
  removeLogo?: Maybe<Scalars['Boolean']>;
  removeOgpImage?: Maybe<Scalars['Boolean']>;
  /** Shopify ストアフロント アクセストークン */
  shopifyStorefrontAccessToken?: Maybe<Scalars['String']>;
  /** 電話番号 */
  tel?: Maybe<Scalars['String']>;
  /** 取引コード */
  transactionCode?: Maybe<Scalars['String']>;
  /** Twitterアカウント */
  twitter?: Maybe<Scalars['String']>;
  /** 郵便番号 */
  zipcode?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of EditPublisherNetshops */
export type EditPublisherNetshopsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
  publicStatus?: Maybe<PublicStatus>;
};

/** Autogenerated return type of EditPublisherNetshops */
export type EditPublisherNetshopsPayload = {
  __typename?: 'EditPublisherNetshopsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  publisherNetshops: Array<PublisherNetshop>;
};

/** Autogenerated return type of EditPublisher */
export type EditPublisherPayload = {
  __typename?: 'EditPublisherPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  publisher: Publisher;
};

/** Autogenerated input type of EditSeries */
export type EditSeriesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kana: Scalars['String'];
  name: Scalars['String'];
};

/** Autogenerated return type of EditSeries */
export type EditSeriesPayload = {
  __typename?: 'EditSeriesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  series: Series;
};

/** Autogenerated input type of ExportBooks */
export type ExportBooksInput = {
  author?: Maybe<Scalars['String']>;
  /** 出版年月日終了日 */
  bookDateEnd?: Maybe<Scalars['DateTimeWithTimezone']>;
  /** 出版年月日開始日 */
  bookDateStart?: Maybe<Scalars['DateTimeWithTimezone']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 出力対象 */
  coverage?: Maybe<Scalars['Boolean']>;
  /** 電子書籍設定 */
  ebookStatus?: Maybe<Scalars['Boolean']>;
  /** ジャンルID */
  genre?: Maybe<Scalars['ID']>;
  /** 書影 */
  image?: Maybe<Scalars['Boolean']>;
  /** ISBN */
  isbn?: Maybe<Scalars['String']>;
  /** JPRO連携の有無 */
  jpro?: Maybe<Scalars['Boolean']>;
  /** 雑誌コード */
  magazineCode?: Maybe<Scalars['String']>;
  /** タイトル */
  name?: Maybe<Scalars['String']>;
  /** 新刊設定 */
  newStatus?: Maybe<Scalars['Boolean']>;
  /** これから出る本設定 */
  nextBook?: Maybe<Scalars['Boolean']>;
  /** 公開の状態 （公開／非公開） */
  publicStatus?: Maybe<PublicScheduledStatus>;
  /** おすすめ設定 */
  recommendStatus?: Maybe<Scalars['Boolean']>;
  /** シリーズID */
  series?: Maybe<Scalars['ID']>;
  /** 在庫ステータス */
  stockStatus?: Maybe<StockStatus>;
};

/** Autogenerated return type of ExportBooks */
export type ExportBooksPayload = {
  __typename?: 'ExportBooksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  publisher?: Maybe<Publisher>;
};

export enum ExtentEnum {
  /** 希望しない */
  DontWant = 'dontWant',
  /** 未選択 */
  None = 'none',
  /** 新刊ライン送品を希望する */
  Want = 'want'
}

export type FormatGroup = {
  __typename?: 'FormatGroup';
  books: Array<Book>;
  /** ID */
  id: Scalars['ID'];
  primaryBookId: Scalars['ID'];
};


export type FormatGroupBooksArgs = {
  withPrivate?: Maybe<Scalars['Boolean']>;
};

export type FreeItem = {
  __typename?: 'FreeItem';
  /** ID */
  id: Scalars['ID'];
  /** フリー項目設定 */
  publisherFreeItem: PublisherFreeItem;
  /** フリー項目設定ID */
  publisherFreeItemId: Scalars['ID'];
  /** 値 */
  value?: Maybe<Scalars['String']>;
};

export enum FreeItemEnum {
  Checkbox = 'checkbox',
  Text = 'text',
  Textarea = 'textarea'
}

export type FreeItemInput = {
  publisherFreeItemId: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
};

export type FreeItemSearchParams = {
  /** キー */
  key: Scalars['String'];
  /** 存在フラグ */
  presence?: Maybe<Scalars['Boolean']>;
  /** 値 */
  value?: Maybe<Scalars['String']>;
};

export type Genre = {
  __typename?: 'Genre';
  booksCount: Scalars['Int'];
  branches: Array<Genre>;
  createdAt: Scalars['DateTime'];
  /** 深度 （階層構造） */
  depth: Scalars['Int'];
  fullName: Scalars['String'];
  /** ID */
  id: Scalars['ID'];
  /** ジャンル名 */
  name: Scalars['String'];
  parent?: Maybe<Genre>;
  updatedAt: Scalars['DateTime'];
};

export type HondanaEbook = {
  __typename?: 'HondanaEbook';
  /** 書籍 */
  book: Book;
  /** コンバートステータス */
  convertStatus: ConvertStatusEnum;
  createdAt: Scalars['DateTime'];
  /** 書籍データファイル名 */
  fileName: Scalars['String'];
  /** ID */
  id: Scalars['ID'];
  /** 書籍データ形式 */
  renditionLayout: RenditionLayoutEnum;
  /** 試し読み公開ステータス */
  trialPublicStatus: PublicStatus;
  /** 試し読み範囲 */
  trialRange: Scalars['String'];
  trialUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type HondanaEbookPagy = {
  __typename?: 'HondanaEbookPagy';
  nodes: Array<HondanaEbook>;
  pageInfo: Pagy;
};

export enum ImageSize {
  Large = 'large',
  Middle = 'middle',
  Normal = 'normal',
  Original = 'original'
}

/** Autogenerated input type of ImportBooks */
export type ImportBooksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  csvFile: Scalars['Upload'];
};

/** Autogenerated return type of ImportBooks */
export type ImportBooksPayload = {
  __typename?: 'ImportBooksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  publisher?: Maybe<Publisher>;
};

/** Autogenerated input type of ImportPublisher */
export type ImportPublisherInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  zipFile: Scalars['Upload'];
};

/** Autogenerated return type of ImportPublisher */
export type ImportPublisherPayload = {
  __typename?: 'ImportPublisherPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  publisher?: Maybe<Publisher>;
};

export type Info = {
  __typename?: 'Info';
  /** ID */
  id: Scalars['ID'];
  /** タイトル */
  name: Scalars['String'];
  /** 公開日時 */
  publicDate: Scalars['DateTime'];
  /** 公開ステータス */
  publicStatus: PublicStatus;
  /** 内容 */
  value: Scalars['String'];
};

export type InfoPagy = {
  __typename?: 'InfoPagy';
  nodes: Array<Info>;
  pageInfo: Pagy;
};

export enum IntermediaryCompanyHandlingEnum {
  /** なし */
  Disable = 'disable',
  /** あり */
  Enable = 'enable'
}

export type JproHistory = {
  __typename?: 'JproHistory';
  /** 作成日時 */
  createdAt: Scalars['DateTime'];
  /** ID */
  id: Scalars['ID'];
  /** 送信日時 */
  sentAt?: Maybe<Scalars['DateTime']>;
  /** 処理ステータス */
  state: Scalars['String'];
  /** 処理ステータス */
  stateEnum: JproHistoryStateEnum;
  tsvFileUrl?: Maybe<Scalars['String']>;
};

export type JproHistoryPagy = {
  __typename?: 'JproHistoryPagy';
  nodes: Array<JproHistory>;
  pageInfo: Pagy;
};

export enum JproHistoryStateEnum {
  /** 作成済 */
  Created = 'created',
  /** TSVファイルが空 */
  EmptyTsvFile = 'emptyTsvFile',
  /** JPROエラー */
  JproFailed = 'jproFailed',
  /** TSV作成済 */
  TsvGenerated = 'tsvGenerated',
  /** TSV作成エラー */
  TsvGenerateFailed = 'tsvGenerateFailed',
  /** TSV送信エラー */
  TsvTransferFailed = 'tsvTransferFailed',
  /** TSV送信済 */
  TsvTransferred = 'tsvTransferred'
}


export enum LanguageEnum {
  /** アラビア語 */
  Ara = 'ara',
  /** 中国語 */
  Chi = 'chi',
  /** 英語 */
  Eng = 'eng',
  /** エスペラント */
  Epo = 'epo',
  /** フランス語 */
  Fre = 'fre',
  /** ドイツ語 */
  Ger = 'ger',
  /** ギリシャ語 */
  Gre = 'gre',
  /** イタリア語 */
  Ita = 'ita',
  /** 日本語 */
  Jpn = 'jpn',
  /** 韓国語 */
  Kor = 'kor',
  /** ラテン語 */
  Lat = 'lat',
  /** マレー語 */
  May = 'may',
  /** ロシア語 */
  Rus = 'rus',
  /** スペイン語 */
  Spa = 'spa'
}

/** Autogenerated input type of MoveBanner */
export type MoveBannerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  position: Scalars['Int'];
};

/** Autogenerated return type of MoveBanner */
export type MoveBannerPayload = {
  __typename?: 'MoveBannerPayload';
  banner: Banner;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of MoveBigBanner */
export type MoveBigBannerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  position: Scalars['Int'];
};

/** Autogenerated return type of MoveBigBanner */
export type MoveBigBannerPayload = {
  __typename?: 'MoveBigBannerPayload';
  bigBanner: BigBanner;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of MoveCompanyCategory */
export type MoveCompanyCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  position: TreePosition;
  targetId: Scalars['ID'];
};

/** Autogenerated return type of MoveCompanyCategory */
export type MoveCompanyCategoryPayload = {
  __typename?: 'MoveCompanyCategoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  companyCategory: CompanyCategory;
};

/** Autogenerated input type of MoveGenre */
export type MoveGenreInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  position: TreePosition;
  targetId: Scalars['ID'];
};

/** Autogenerated return type of MoveGenre */
export type MoveGenrePayload = {
  __typename?: 'MoveGenrePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  genre: Genre;
};

/** Autogenerated input type of MoveNewlyPublishedBook */
export type MoveNewlyPublishedBookInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  position: Scalars['Int'];
};

/** Autogenerated return type of MoveNewlyPublishedBook */
export type MoveNewlyPublishedBookPayload = {
  __typename?: 'MoveNewlyPublishedBookPayload';
  book: Book;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of MoveNewsCategory */
export type MoveNewsCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  position: TreePosition;
  targetId: Scalars['ID'];
};

/** Autogenerated return type of MoveNewsCategory */
export type MoveNewsCategoryPayload = {
  __typename?: 'MoveNewsCategoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  newsCategory: NewsCategory;
};

/** Autogenerated input type of MovePublisherEbookstore */
export type MovePublisherEbookstoreInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  position: Scalars['Int'];
};

/** Autogenerated return type of MovePublisherEbookstore */
export type MovePublisherEbookstorePayload = {
  __typename?: 'MovePublisherEbookstorePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ebookstore: PublisherEbookstore;
};

/** Autogenerated input type of MovePublisherNetshop */
export type MovePublisherNetshopInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  position: Scalars['Int'];
};

/** Autogenerated return type of MovePublisherNetshop */
export type MovePublisherNetshopPayload = {
  __typename?: 'MovePublisherNetshopPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  netshop: PublisherNetshop;
};

/** Autogenerated input type of MoveSeries */
export type MoveSeriesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  position: TreePosition;
  targetId: Scalars['ID'];
};

/** Autogenerated return type of MoveSeries */
export type MoveSeriesPayload = {
  __typename?: 'MoveSeriesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  series: Series;
};

export type Mutation = {
  __typename?: 'Mutation';
  bulkUpdateBooks?: Maybe<BulkUpdateBooksPayload>;
  deleteAuthors?: Maybe<DeleteAuthorsPayload>;
  deleteBanners?: Maybe<DeleteBannersPayload>;
  deleteBigBanners?: Maybe<DeleteBigBannersPayload>;
  deleteBooks?: Maybe<DeleteBooksPayload>;
  deleteCompanies?: Maybe<DeleteCompaniesPayload>;
  deleteCompanyCategory?: Maybe<DeleteCompanyCategoryPayload>;
  deleteGenre?: Maybe<DeleteGenrePayload>;
  deleteHondanaEbook?: Maybe<DeleteHondanaEbookPayload>;
  deleteInfos?: Maybe<DeleteInfosPayload>;
  deleteNewsCategory?: Maybe<DeleteNewsCategoryPayload>;
  deleteNewsPosts?: Maybe<DeleteNewsPostsPayload>;
  deleteSeries?: Maybe<DeleteSeriesPayload>;
  editAdminPublisher?: Maybe<EditAdminPublisherPayload>;
  editAuthor?: Maybe<EditAuthorPayload>;
  editBanner?: Maybe<EditBannerPayload>;
  editBanners?: Maybe<EditBannersPayload>;
  editBigBanner?: Maybe<EditBigBannerPayload>;
  editBigBanners?: Maybe<EditBigBannersPayload>;
  editBook?: Maybe<EditBookPayload>;
  editBooks?: Maybe<EditBooksPayload>;
  editCompanies?: Maybe<EditCompaniesPayload>;
  editCompany?: Maybe<EditCompanyPayload>;
  editCompanyCategory?: Maybe<EditCompanyCategoryPayload>;
  editGenre?: Maybe<EditGenrePayload>;
  editHondanaEbook?: Maybe<EditHondanaEbookPayload>;
  editInfo?: Maybe<EditInfoPayload>;
  editNewsCategory?: Maybe<EditNewsCategoryPayload>;
  editNewsPost?: Maybe<EditNewsPostPayload>;
  editNewsPosts?: Maybe<EditNewsPostsPayload>;
  editPublisher?: Maybe<EditPublisherPayload>;
  editPublisherEbookstores?: Maybe<EditPublisherEbookstoresPayload>;
  editPublisherNetshops?: Maybe<EditPublisherNetshopsPayload>;
  editSeries?: Maybe<EditSeriesPayload>;
  exportBooks?: Maybe<ExportBooksPayload>;
  importBooks?: Maybe<ImportBooksPayload>;
  importPublisher?: Maybe<ImportPublisherPayload>;
  moveBanner?: Maybe<MoveBannerPayload>;
  moveBigBanner?: Maybe<MoveBigBannerPayload>;
  moveCompanyCategory?: Maybe<MoveCompanyCategoryPayload>;
  moveGenre?: Maybe<MoveGenrePayload>;
  moveNewlyPublishedBook?: Maybe<MoveNewlyPublishedBookPayload>;
  moveNewsCategory?: Maybe<MoveNewsCategoryPayload>;
  movePublisherEbookstore?: Maybe<MovePublisherEbookstorePayload>;
  movePublisherNetshop?: Maybe<MovePublisherNetshopPayload>;
  moveSeries?: Maybe<MoveSeriesPayload>;
  newAuthor?: Maybe<NewAuthorPayload>;
  newBanner?: Maybe<NewBannerPayload>;
  newBigBanner?: Maybe<NewBigBannerPayload>;
  newBook?: Maybe<NewBookPayload>;
  newCompany?: Maybe<NewCompanyPayload>;
  newCompanyCategory?: Maybe<NewCompanyCategoryPayload>;
  newGenre?: Maybe<NewGenrePayload>;
  newHondanaEbook?: Maybe<NewHondanaEbookPayload>;
  newInfo?: Maybe<NewInfoPayload>;
  newNewsCategory?: Maybe<NewNewsCategoryPayload>;
  newNewsPost?: Maybe<NewNewsPostPayload>;
  newPreviewBook?: Maybe<NewPreviewBookPayload>;
  newPreviewCompany?: Maybe<NewPreviewCompanyPayload>;
  newPreviewNews?: Maybe<NewPreviewNewsPayload>;
  newPublisher?: Maybe<NewPublisherPayload>;
  newSeries?: Maybe<NewSeriesPayload>;
  revertAdminJproHistory?: Maybe<RevertAdminJproHistoryPayload>;
  sendAllBooksToShopify?: Maybe<SendAllBooksToShopifyPayload>;
  sortNewlyPublishedBooks?: Maybe<SortNewlyPublishedBooksPayload>;
};


export type MutationBulkUpdateBooksArgs = {
  input: BulkUpdateBooksInput;
};


export type MutationDeleteAuthorsArgs = {
  input: DeleteAuthorsInput;
};


export type MutationDeleteBannersArgs = {
  input: DeleteBannersInput;
};


export type MutationDeleteBigBannersArgs = {
  input: DeleteBigBannersInput;
};


export type MutationDeleteBooksArgs = {
  input: DeleteBooksInput;
};


export type MutationDeleteCompaniesArgs = {
  input: DeleteCompaniesInput;
};


export type MutationDeleteCompanyCategoryArgs = {
  input: DeleteCompanyCategoryInput;
};


export type MutationDeleteGenreArgs = {
  input: DeleteGenreInput;
};


export type MutationDeleteHondanaEbookArgs = {
  input: DeleteHondanaEbookInput;
};


export type MutationDeleteInfosArgs = {
  input: DeleteInfosInput;
};


export type MutationDeleteNewsCategoryArgs = {
  input: DeleteNewsCategoryInput;
};


export type MutationDeleteNewsPostsArgs = {
  input: DeleteNewsPostsInput;
};


export type MutationDeleteSeriesArgs = {
  input: DeleteSeriesInput;
};


export type MutationEditAdminPublisherArgs = {
  input: EditAdminPublisherInput;
};


export type MutationEditAuthorArgs = {
  input: EditAuthorInput;
};


export type MutationEditBannerArgs = {
  input: EditBannerInput;
};


export type MutationEditBannersArgs = {
  input: EditBannersInput;
};


export type MutationEditBigBannerArgs = {
  input: EditBigBannerInput;
};


export type MutationEditBigBannersArgs = {
  input: EditBigBannersInput;
};


export type MutationEditBookArgs = {
  input: EditBookInput;
};


export type MutationEditBooksArgs = {
  input: EditBooksInput;
};


export type MutationEditCompaniesArgs = {
  input: EditCompaniesInput;
};


export type MutationEditCompanyArgs = {
  input: EditCompanyInput;
};


export type MutationEditCompanyCategoryArgs = {
  input: EditCompanyCategoryInput;
};


export type MutationEditGenreArgs = {
  input: EditGenreInput;
};


export type MutationEditHondanaEbookArgs = {
  input: EditHondanaEbookInput;
};


export type MutationEditInfoArgs = {
  input: EditInfoInput;
};


export type MutationEditNewsCategoryArgs = {
  input: EditNewsCategoryInput;
};


export type MutationEditNewsPostArgs = {
  input: EditNewsPostInput;
};


export type MutationEditNewsPostsArgs = {
  input: EditNewsPostsInput;
};


export type MutationEditPublisherArgs = {
  input: EditPublisherInput;
};


export type MutationEditPublisherEbookstoresArgs = {
  input: EditPublisherEbookstoresInput;
};


export type MutationEditPublisherNetshopsArgs = {
  input: EditPublisherNetshopsInput;
};


export type MutationEditSeriesArgs = {
  input: EditSeriesInput;
};


export type MutationExportBooksArgs = {
  input: ExportBooksInput;
};


export type MutationImportBooksArgs = {
  input: ImportBooksInput;
};


export type MutationImportPublisherArgs = {
  input: ImportPublisherInput;
};


export type MutationMoveBannerArgs = {
  input: MoveBannerInput;
};


export type MutationMoveBigBannerArgs = {
  input: MoveBigBannerInput;
};


export type MutationMoveCompanyCategoryArgs = {
  input: MoveCompanyCategoryInput;
};


export type MutationMoveGenreArgs = {
  input: MoveGenreInput;
};


export type MutationMoveNewlyPublishedBookArgs = {
  input: MoveNewlyPublishedBookInput;
};


export type MutationMoveNewsCategoryArgs = {
  input: MoveNewsCategoryInput;
};


export type MutationMovePublisherEbookstoreArgs = {
  input: MovePublisherEbookstoreInput;
};


export type MutationMovePublisherNetshopArgs = {
  input: MovePublisherNetshopInput;
};


export type MutationMoveSeriesArgs = {
  input: MoveSeriesInput;
};


export type MutationNewAuthorArgs = {
  input: NewAuthorInput;
};


export type MutationNewBannerArgs = {
  input: NewBannerInput;
};


export type MutationNewBigBannerArgs = {
  input: NewBigBannerInput;
};


export type MutationNewBookArgs = {
  input: NewBookInput;
};


export type MutationNewCompanyArgs = {
  input: NewCompanyInput;
};


export type MutationNewCompanyCategoryArgs = {
  input: NewCompanyCategoryInput;
};


export type MutationNewGenreArgs = {
  input: NewGenreInput;
};


export type MutationNewHondanaEbookArgs = {
  input: NewHondanaEbookInput;
};


export type MutationNewInfoArgs = {
  input: NewInfoInput;
};


export type MutationNewNewsCategoryArgs = {
  input: NewNewsCategoryInput;
};


export type MutationNewNewsPostArgs = {
  input: NewNewsPostInput;
};


export type MutationNewPreviewBookArgs = {
  input: NewPreviewBookInput;
};


export type MutationNewPreviewCompanyArgs = {
  input: NewPreviewCompanyInput;
};


export type MutationNewPreviewNewsArgs = {
  input: NewPreviewNewsInput;
};


export type MutationNewPublisherArgs = {
  input: NewPublisherInput;
};


export type MutationNewSeriesArgs = {
  input: NewSeriesInput;
};


export type MutationRevertAdminJproHistoryArgs = {
  input: RevertAdminJproHistoryInput;
};


export type MutationSendAllBooksToShopifyArgs = {
  input: SendAllBooksToShopifyInput;
};


export type MutationSortNewlyPublishedBooksArgs = {
  input: SortNewlyPublishedBooksInput;
};

export type Netshop = {
  __typename?: 'Netshop';
  /** サイト文字コード */
  charset?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** ID */
  id: Scalars['ID'];
  /** 識別子 */
  key: Scalars['String'];
  /** ネット書店名 */
  name: Scalars['String'];
  /** 書名による検索URL */
  textsearchUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** ISBNによる検索URL */
  url: Scalars['String'];
};

/** Autogenerated input type of NewAuthor */
export type NewAuthorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  kana: Scalars['String'];
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of NewAuthor */
export type NewAuthorPayload = {
  __typename?: 'NewAuthorPayload';
  author: Author;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of NewBanner */
export type NewBannerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  image: Scalars['Upload'];
  /** altに使用 */
  name: Scalars['String'];
  /** 公開・非公開の判定 */
  publicStatus: PublicStatus;
  /** 外部リンクかどうかの判定 */
  target: Scalars['Boolean'];
  /** リンク先 */
  url: Scalars['String'];
};

/** Autogenerated return type of NewBanner */
export type NewBannerPayload = {
  __typename?: 'NewBannerPayload';
  banner: Banner;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of NewBigBanner */
export type NewBigBannerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  image: Scalars['Upload'];
  /** altに使用 */
  name: Scalars['String'];
  /** 公開・非公開の判定 */
  publicStatus: PublicStatus;
  subImage?: Maybe<Scalars['Upload']>;
  /** 外部リンクかどうかの判定 */
  target: Scalars['Boolean'];
  /** リンク先 */
  url: Scalars['String'];
};

/** Autogenerated return type of NewBigBanner */
export type NewBigBannerPayload = {
  __typename?: 'NewBigBannerPayload';
  bigBanner: BigBanner;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of NewBook */
export type NewBookInput = {
  /** 出版年月日 */
  bookDate?: Maybe<Scalars['DateTime']>;
  bookEbookstores?: Maybe<Array<BookEbookstoreInput>>;
  bookFormatEnum?: Maybe<BookFormat>;
  bookFormatOther?: Maybe<Scalars['String']>;
  /** 書誌ID */
  bookIds?: Maybe<Array<Scalars['ID']>>;
  bookJproAttributes?: Maybe<BookJproInput>;
  bookSizeEnum?: Maybe<BookSize>;
  /** カート（有効／無効） */
  cartStatus?: Maybe<Scalars['Boolean']>;
  /** Cコード */
  cCode?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 内容説明 */
  content?: Maybe<Scalars['String']>;
  /** 電子書籍設定 */
  ebookStatus?: Maybe<Scalars['Boolean']>;
  /** 目次 */
  explain?: Maybe<Scalars['String']>;
  /** 書誌フォーマット プライマリーブックID */
  formatPrimaryId?: Maybe<Scalars['ID']>;
  /** フリー項目（シリアライズして保存 */
  freeitem?: Maybe<Scalars['String']>;
  freeItems?: Maybe<Array<FreeItemInput>>;
  /** ジャンルID */
  genreIds?: Maybe<Array<Scalars['ID']>>;
  image?: Maybe<Scalars['Upload']>;
  /** ISBN */
  isbn?: Maybe<Scalars['String']>;
  /** JANコード */
  janCode?: Maybe<Scalars['String']>;
  jpro?: Maybe<Scalars['Boolean']>;
  /** 書名カナ */
  kana?: Maybe<Scalars['String']>;
  /** キーワード */
  keyword?: Maybe<Scalars['String']>;
  /** 雑誌コード */
  magazineCode?: Maybe<Scalars['String']>;
  /** 独自商品コード */
  managementCode?: Maybe<Scalars['String']>;
  measureHeight?: Maybe<Scalars['Int']>;
  measureThickness?: Maybe<Scalars['Int']>;
  measureWidth?: Maybe<Scalars['Int']>;
  /** 書名 */
  name?: Maybe<Scalars['String']>;
  /** 新刊設定 */
  newStatus?: Maybe<Scalars['Boolean']>;
  /** これから出る本設定 */
  nextBook?: Maybe<Scalars['Boolean']>;
  opuses?: Maybe<Array<OpusInput>>;
  otherFormats?: Maybe<Array<BookFormatInput>>;
  /** 概要（長文） */
  outline?: Maybe<Scalars['String']>;
  /** 概要（短文） */
  outlineAbr?: Maybe<Scalars['String']>;
  /** ページ数 */
  page?: Maybe<Scalars['Int']>;
  /** 本体価格 */
  price?: Maybe<Scalars['Int']>;
  /** 公開日時 */
  publicDate?: Maybe<Scalars['DateTime']>;
  /** 公開の状態 （公開／非公開） */
  publicStatus?: Maybe<PublicScheduledStatus>;
  /** おすすめ表示順 */
  recommendOrder?: Maybe<Scalars['Int']>;
  /** おすすめ紹介文 */
  recommendSentence?: Maybe<Scalars['String']>;
  /** おすすめ設定 */
  recommendStatus?: Maybe<Scalars['Boolean']>;
  /** 書店発売日 */
  releaseDate?: Maybe<Scalars['DateTime']>;
  removeImage?: Maybe<Scalars['Boolean']>;
  removeSubImages?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  /** ジャンルID */
  seriesIds?: Maybe<Array<Scalars['ID']>>;
  /** 在庫ステータス */
  stockStatusEnum?: Maybe<StockStatus>;
  subImages?: Maybe<Array<Maybe<Scalars['Upload']>>>;
  /** サブタイトルカナ */
  subKana?: Maybe<Scalars['String']>;
  /** サブタイトル */
  subName?: Maybe<Scalars['String']>;
  /** 版 */
  version?: Maybe<Scalars['String']>;
  /** 巻次 */
  volume?: Maybe<Scalars['String']>;
  /** 試し読みURL */
  yondemillBookSalesUrl?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of NewBook */
export type NewBookPayload = {
  __typename?: 'NewBookPayload';
  book?: Maybe<Book>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of NewCompanyCategory */
export type NewCompanyCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId: Scalars['ID'];
};

/** Autogenerated return type of NewCompanyCategory */
export type NewCompanyCategoryPayload = {
  __typename?: 'NewCompanyCategoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  companyCategory: CompanyCategory;
};

/** Autogenerated input type of NewCompany */
export type NewCompanyInput = {
  /** カテゴリID */
  categoryId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** タイトル */
  name: Scalars['String'];
  /** 公開日時 */
  publicDate?: Maybe<Scalars['DateTime']>;
  /** 公開ステータス */
  publicStatus: PublicScheduledStatus;
  /** 予約投稿日時 */
  reservationDate?: Maybe<Scalars['DateTime']>;
  /** 内容 */
  value: Scalars['String'];
};

/** Autogenerated return type of NewCompany */
export type NewCompanyPayload = {
  __typename?: 'NewCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  company: Company;
};

/** Autogenerated input type of NewGenre */
export type NewGenreInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId: Scalars['ID'];
};

/** Autogenerated return type of NewGenre */
export type NewGenrePayload = {
  __typename?: 'NewGenrePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  genre: Genre;
};

/** Autogenerated input type of NewHondanaEbook */
export type NewHondanaEbookInput = {
  bookId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  renditionLayout?: Maybe<RenditionLayoutEnum>;
  trialPublicStatus?: Maybe<PublicStatus>;
  trialRange?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of NewHondanaEbook */
export type NewHondanaEbookPayload = {
  __typename?: 'NewHondanaEbookPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  fileUploadUrl: Scalars['String'];
  hondanaEbook: HondanaEbook;
  imageUploadUrl: Scalars['String'];
};

/** Autogenerated input type of NewInfo */
export type NewInfoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** タイトル */
  name: Scalars['String'];
  /** 公開日時 */
  publicDate: Scalars['DateTime'];
  /** 公開ステータス */
  publicStatus: PublicStatus;
  /** 内容 */
  value: Scalars['String'];
};

/** Autogenerated return type of NewInfo */
export type NewInfoPayload = {
  __typename?: 'NewInfoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  info: Info;
};

export enum NewlyPublishedBookSort {
  BookDateAsc = 'BOOK_DATE_ASC',
  BookDateDesc = 'BOOK_DATE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

/** Autogenerated input type of NewNewsCategory */
export type NewNewsCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId: Scalars['ID'];
};

/** Autogenerated return type of NewNewsCategory */
export type NewNewsCategoryPayload = {
  __typename?: 'NewNewsCategoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  newsCategory: NewsCategory;
};

/** Autogenerated input type of NewNewsPost */
export type NewNewsPostInput = {
  /** 関連書籍のID */
  bookIds?: Maybe<Array<Scalars['ID']>>;
  /** カテゴリID */
  categoryId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** タイトル */
  name: Scalars['String'];
  /** ナビ部分への表示 */
  naviDisplay: Scalars['Boolean'];
  /** 記事の日付 */
  publicDate?: Maybe<Scalars['DateTime']>;
  /** 公開ステータス */
  publicStatus: PublicScheduledStatus;
  /** 公開URL */
  publicUrl?: Maybe<Scalars['String']>;
  /** 予約投稿日時 */
  reservationDate?: Maybe<Scalars['DateTime']>;
  /** 内容 */
  value: Scalars['String'];
};

/** Autogenerated return type of NewNewsPost */
export type NewNewsPostPayload = {
  __typename?: 'NewNewsPostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  newsPost: NewsPost;
};

/** Autogenerated input type of NewPreviewBook */
export type NewPreviewBookInput = {
  /** 出版年月日 */
  bookDate?: Maybe<Scalars['DateTime']>;
  bookFormatEnum?: Maybe<BookFormat>;
  bookFormatOther?: Maybe<Scalars['String']>;
  /** 書誌ID */
  bookIds?: Maybe<Array<Scalars['ID']>>;
  bookSizeEnum?: Maybe<BookSize>;
  /** カート（有効／無効） */
  cartStatus?: Maybe<Scalars['Boolean']>;
  /** Cコード */
  cCode?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 内容説明 */
  content?: Maybe<Scalars['String']>;
  /** 電子書籍設定 */
  ebookStatus?: Maybe<Scalars['Boolean']>;
  /** 目次 */
  explain?: Maybe<Scalars['String']>;
  /** 書誌フォーマット プライマリーブックID */
  formatPrimaryId?: Maybe<Scalars['ID']>;
  /** ジャンルID */
  genreIds?: Maybe<Array<Scalars['ID']>>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['Upload']>;
  /** ISBN */
  isbn?: Maybe<Scalars['String']>;
  /** JANコード */
  janCode?: Maybe<Scalars['String']>;
  /** 書名カナ */
  kana?: Maybe<Scalars['String']>;
  /** キーワード */
  keyword?: Maybe<Scalars['String']>;
  /** 雑誌コード */
  magazineCode?: Maybe<Scalars['String']>;
  /** 独自商品コード */
  managementCode?: Maybe<Scalars['String']>;
  measureHeight?: Maybe<Scalars['Int']>;
  measureThickness?: Maybe<Scalars['Int']>;
  measureWidth?: Maybe<Scalars['Int']>;
  /** 書名 */
  name?: Maybe<Scalars['String']>;
  /** 新刊設定 */
  newStatus?: Maybe<Scalars['Boolean']>;
  /** これから出る本設定 */
  nextBook?: Maybe<Scalars['Boolean']>;
  otherFormats?: Maybe<Array<BookFormatInput>>;
  /** 概要（長文） */
  outline?: Maybe<Scalars['String']>;
  /** 概要（短文） */
  outlineAbr?: Maybe<Scalars['String']>;
  /** ページ数 */
  page?: Maybe<Scalars['Int']>;
  previewBookEbookstores?: Maybe<Array<BookEbookstoreInput>>;
  /** フリー項目（シリアライズして保存 */
  previewFreeitem?: Maybe<Scalars['String']>;
  previewFreeItems?: Maybe<Array<FreeItemInput>>;
  previewOpuses?: Maybe<Array<OpusInput>>;
  /** 本体価格 */
  price?: Maybe<Scalars['Int']>;
  /** 公開日時 */
  publicDate?: Maybe<Scalars['DateTime']>;
  /** 公開の状態 （公開／非公開） */
  publicStatus?: Maybe<PublicScheduledStatus>;
  /** おすすめ表示順 */
  recommendOrder?: Maybe<Scalars['Int']>;
  /** おすすめ紹介文 */
  recommendSentence?: Maybe<Scalars['String']>;
  /** おすすめ設定 */
  recommendStatus?: Maybe<Scalars['Boolean']>;
  /** 書店発売日 */
  releaseDate?: Maybe<Scalars['DateTime']>;
  removeImage?: Maybe<Scalars['Boolean']>;
  removeSubImages?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  /** ジャンルID */
  seriesIds?: Maybe<Array<Scalars['ID']>>;
  /** 在庫ステータス */
  stockStatusEnum?: Maybe<StockStatus>;
  subImages?: Maybe<Array<Maybe<Scalars['Upload']>>>;
  /** サブタイトルカナ */
  subKana?: Maybe<Scalars['String']>;
  /** サブタイトル */
  subName?: Maybe<Scalars['String']>;
  /** 版 */
  version?: Maybe<Scalars['String']>;
  /** 巻次 */
  volume?: Maybe<Scalars['String']>;
  /** 試し読みURL */
  yondemillBookSalesUrl?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of NewPreviewBook */
export type NewPreviewBookPayload = {
  __typename?: 'NewPreviewBookPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  previewBook?: Maybe<PreviewBook>;
};

/** Autogenerated input type of NewPreviewCompany */
export type NewPreviewCompanyInput = {
  /** カテゴリID */
  categoryId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** タイトル */
  name: Scalars['String'];
  /** 公開日時 */
  publicDate?: Maybe<Scalars['DateTime']>;
  /** 公開ステータス */
  publicStatus: PublicScheduledStatus;
  /** 予約投稿日時 */
  reservationDate?: Maybe<Scalars['DateTime']>;
  /** 内容 */
  value: Scalars['String'];
};

/** Autogenerated return type of NewPreviewCompany */
export type NewPreviewCompanyPayload = {
  __typename?: 'NewPreviewCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  previewCompany?: Maybe<PreviewCompany>;
};

/** Autogenerated input type of NewPreviewNews */
export type NewPreviewNewsInput = {
  /** 関連書籍のID */
  bookIds?: Maybe<Array<Scalars['ID']>>;
  /** カテゴリID */
  categoryId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** タイトル */
  name: Scalars['String'];
  /** ナビ部分への表示 */
  naviDisplay: Scalars['Boolean'];
  /** 記事の日付 */
  publicDate?: Maybe<Scalars['DateTime']>;
  /** 公開ステータス */
  publicStatus: PublicScheduledStatus;
  /** 公開URL */
  publicUrl?: Maybe<Scalars['String']>;
  /** 予約投稿日時 */
  reservationDate?: Maybe<Scalars['DateTime']>;
  /** 内容 */
  value: Scalars['String'];
};

/** Autogenerated return type of NewPreviewNews */
export type NewPreviewNewsPayload = {
  __typename?: 'NewPreviewNewsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  previewNews: PreviewNews;
};

/** Autogenerated input type of NewPublisher */
export type NewPublisherInput = {
  /** 住所 */
  address?: Maybe<Scalars['String']>;
  /** アフィリエイト（amazon）ID */
  amazonAssociatesId?: Maybe<Scalars['String']>;
  authUid?: Maybe<Scalars['String']>;
  /** ビッグバナーステータス */
  bannerBigStatus?: Maybe<Scalars['Boolean']>;
  /** キャッチフレーズ */
  catchphrase?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** お問い合わせ用メールアドレス */
  contactMail?: Maybe<Scalars['String']>;
  /** コピーライト */
  copyright?: Maybe<Scalars['String']>;
  /** 会社説明 */
  description?: Maybe<Scalars['String']>;
  /** Facebookアカウント */
  facebook?: Maybe<Scalars['String']>;
  /** FAX番号 */
  fax?: Maybe<Scalars['String']>;
  /** 部署 */
  fromPersonUnit?: Maybe<Scalars['String']>;
  /** グーグルAnalytics用タグ （アクセス解析タグ） */
  googleAnalyticsTag?: Maybe<Scalars['String']>;
  /** 会社コード */
  id?: Maybe<Scalars['ID']>;
  /** Instagramユーザーネーム */
  instagram?: Maybe<Scalars['String']>;
  /** JPRO連携 */
  jpro?: Maybe<Scalars['Boolean']>;
  /** 出版社名カナ */
  kana?: Maybe<Scalars['String']>;
  /** データ連携担当者メールアドレス */
  linkagePersonMail?: Maybe<Scalars['String']>;
  /** データ連携担当者名 */
  linkagePersonName?: Maybe<Scalars['String']>;
  /** ロゴ画像 */
  logo?: Maybe<Scalars['Upload']>;
  /** 出版社名 */
  name?: Maybe<Scalars['String']>;
  ogpDescription?: Maybe<Scalars['String']>;
  ogpImage?: Maybe<Scalars['Upload']>;
  ogpTitle?: Maybe<Scalars['String']>;
  /** ROOTのコンテンツオーナーID */
  ownerId?: Maybe<Scalars['String']>;
  /** 担当者メールアドレス */
  personMail?: Maybe<Scalars['String']>;
  /** 担当者 */
  personName?: Maybe<Scalars['String']>;
  /** ISBN 出版社記号 */
  publisherPrefix?: Maybe<Scalars['String']>;
  /** ログインアカウント（出版社ユーザー） */
  publisherUsersAttributes: Array<PublisherUserInput>;
  /** アフィリエイト（楽天）ID */
  rakutenAffiliateId?: Maybe<Scalars['String']>;
  removeLogo?: Maybe<Scalars['Boolean']>;
  removeOgpImage?: Maybe<Scalars['Boolean']>;
  /** 7&Y用アフィリエイト基本URL */
  sevenAndYUrl?: Maybe<Scalars['String']>;
  /** 電話番号 */
  tel?: Maybe<Scalars['String']>;
  /** 取引コード */
  transactionCode?: Maybe<Scalars['String']>;
  /** Twitterアカウント */
  twitter?: Maybe<Scalars['String']>;
  /** URL */
  url?: Maybe<Scalars['String']>;
  /** YONDEMILL 販売システム連携 */
  yondemillBookSales?: Maybe<Scalars['Boolean']>;
  /** 郵便番号 */
  zipcode?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of NewPublisher */
export type NewPublisherPayload = {
  __typename?: 'NewPublisherPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  publisher: Publisher;
};

export type NewsCategory = {
  __typename?: 'NewsCategory';
  branches: Array<NewsCategory>;
  createdAt: Scalars['DateTime'];
  /** 深度 （階層構造） */
  depth: Scalars['Int'];
  fixCategory?: Maybe<NewsFixCategory>;
  /** ID */
  id: Scalars['ID'];
  /** news_fix_categoryかどうか */
  isFix: Scalars['Boolean'];
  /** カテゴリ名 */
  name: Scalars['String'];
  newsCount: Scalars['Int'];
  /** @deprecated fixCategoryを使ってください */
  newsFixCategory?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Autogenerated input type of NewSeries */
export type NewSeriesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  kana: Scalars['String'];
  name: Scalars['String'];
  parentId: Scalars['ID'];
};

/** Autogenerated return type of NewSeries */
export type NewSeriesPayload = {
  __typename?: 'NewSeriesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  series: Series;
};

export enum NewsFixCategory {
  Corrigenda = 'corrigenda',
  Event = 'event',
  ForShop = 'forShop',
  Media = 'media',
  Pop = 'pop',
  PurchaseOrder = 'purchaseOrder',
  Recruit = 'recruit',
  Reprinting = 'reprinting'
}

export type NewsMonthlyCount = {
  __typename?: 'NewsMonthlyCount';
  month: Scalars['String'];
  newsCount: Scalars['Int'];
  year: Scalars['String'];
};

export type NewsPost = {
  __typename?: 'NewsPost';
  categories: Array<NewsCategory>;
  category: NewsCategory;
  createdAt: Scalars['DateTime'];
  /** ID */
  id: Scalars['ID'];
  /** タイトル */
  name: Scalars['String'];
  /** ナビ部分への表示 */
  naviDisplay: Scalars['Boolean'];
  /** 記事の日付 */
  publicDate?: Maybe<Scalars['DateTime']>;
  /** 公開・非公開の判定 */
  publicStatus: PublicScheduledStatus;
  /** 公開URL */
  publicUrl?: Maybe<Scalars['String']>;
  relatedBooks: BookConnection;
  /** 予約投稿日時 */
  reservationDate?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  /** 内容 */
  value: Scalars['String'];
};


export type NewsPostRelatedBooksArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};

/** The connection type for NewsPost. */
export type NewsPostConnection = {
  __typename?: 'NewsPostConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NewsPostEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<NewsPost>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NewsPostEdge = {
  __typename?: 'NewsPostEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<NewsPost>;
};

export type NewsPostPagy = {
  __typename?: 'NewsPostPagy';
  nodes: Array<NewsPost>;
  pageInfo: Pagy;
};

export type NewsPostSearchParams = {
  /** ID */
  categoryId?: Maybe<Scalars['ID']>;
  /** 除外カテゴリID */
  exclusionCategoryIds?: Maybe<Array<Scalars['ID']>>;
  /** 除外ID */
  exclusionIds?: Maybe<Array<Scalars['ID']>>;
  /** タイトル */
  name?: Maybe<Scalars['String']>;
  /** ナビ部分への表示 */
  naviDisplay?: Maybe<Scalars['Boolean']>;
  /** 公開ステータス */
  publicStatus?: Maybe<PublicScheduledStatus>;
  /** 公開URL */
  publicUrl?: Maybe<Scalars['String']>;
  /** 並び順 */
  sort?: Maybe<NewsPostSort>;
  /** 年月(YYYY-MM) */
  yearMonth?: Maybe<Scalars['String']>;
};

export enum NewsPostSort {
  CreatedAtDesc = 'CREATED_AT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PublicDateAsc = 'PUBLIC_DATE_ASC',
  PublicDateDesc = 'PUBLIC_DATE_DESC'
}

export enum NotificationTypeEnum {
  /** 削除 */
  Deleted = 'deleted',
  /** 確定前 */
  Drafted = 'drafted',
  /** 確定 */
  Released = 'released'
}

export type OperationLog = {
  __typename?: 'OperationLog';
  action?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  foreignKey?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  publisherId?: Maybe<Scalars['Int']>;
  resourceUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  table?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['Int']>;
  userName?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
};

export type OperationLogPagy = {
  __typename?: 'OperationLogPagy';
  nodes: Array<OperationLog>;
  pageInfo: Pagy;
};

export type OperationLogSearchParams = {
  /** アクション */
  action?: Maybe<Scalars['String']>;
};

export type Opus = {
  __typename?: 'Opus';
  author: Author;
  authorProfile?: Maybe<Scalars['String']>;
  authorType: Scalars['String'];
  authorTypeEnum: AuthorCategory;
  authorTypeOther?: Maybe<Scalars['String']>;
  contributorRole?: Maybe<Scalars['String']>;
  /** ID */
  id: Scalars['ID'];
};

/** The connection type for Opus. */
export type OpusConnection = {
  __typename?: 'OpusConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OpusEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Opus>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OpusEdge = {
  __typename?: 'OpusEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Opus>;
};

export type OpusInput = {
  /** ID */
  authorId: Scalars['ID'];
  /** 著者略歴 */
  authorProfile?: Maybe<Scalars['String']>;
  /** 著者区分 */
  authorTypeEnum: AuthorCategory;
  /** 著者区分 その他 （フリー入力） */
  authorTypeOther?: Maybe<Scalars['String']>;
  /** JPRO用著者区分 */
  contributorRole?: Maybe<Scalars['String']>;
};

export enum OtherNoticesEnum {
  /** 新装丁 */
  NewBinding = 'newBinding',
  /** 未選択 */
  None = 'none',
  /** その他 */
  Other = 'other',
  /** 改題 */
  Renamed = 'renamed'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Pagy = {
  __typename?: 'Pagy';
  /** 全体の件数 */
  count: Scalars['Int'];
  /** 現在の開始位置 */
  from: Scalars['Int'];
  /** 終端のページ番号 */
  last: Scalars['Int'];
  /** 次のページ番号 */
  next?: Maybe<Scalars['Int']>;
  /** 現在のページ番号 */
  page: Scalars['Int'];
  /** 全体のページ数 */
  pages: Scalars['Int'];
  /** 前のページ番号 */
  prev?: Maybe<Scalars['Int']>;
  /** 現在の終了位置 */
  to: Scalars['Int'];
};

export type PreviewBook = {
  __typename?: 'PreviewBook';
  /** 出版年月日 */
  bookDate?: Maybe<Scalars['DateTime']>;
  bookFormat?: Maybe<Scalars['String']>;
  bookFormatEnum?: Maybe<BookFormat>;
  bookFormatOther?: Maybe<Scalars['String']>;
  bookSize?: Maybe<Scalars['String']>;
  bookSizeEnum?: Maybe<BookSize>;
  /** カート（有効／無効） */
  cartStatus: Scalars['Boolean'];
  /** Cコード */
  cCode?: Maybe<Scalars['String']>;
  /** 内容説明 */
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** 電子書籍設定 */
  ebookStatus: Scalars['Boolean'];
  ebookstores: PreviewBookEbookstoreConnection;
  /** 目次 */
  explain?: Maybe<Scalars['String']>;
  formatGroup?: Maybe<PreviewFormatGroup>;
  formatGroupId?: Maybe<Scalars['ID']>;
  formatPrimary: Scalars['Boolean'];
  freeItems: Array<PreviewFreeItem>;
  genres: Array<Genre>;
  genreTree: Array<Genre>;
  /** ID */
  id: Scalars['ID'];
  /**
   * 書影
   * @deprecated imageUrlを使用するようにしてください
   */
  image?: Maybe<Scalars['String']>;
  /** 書影URL */
  imageUrl?: Maybe<Scalars['String']>;
  /** ISBN */
  isbn?: Maybe<Scalars['String']>;
  janCode?: Maybe<Scalars['String']>;
  jpro: Scalars['Boolean'];
  /** 書名カナ */
  kana?: Maybe<Scalars['String']>;
  /** キーワード */
  keyword?: Maybe<Scalars['String']>;
  /** 雑誌コード */
  magazineCode?: Maybe<Scalars['String']>;
  /** 独自商品コード */
  managementCode?: Maybe<Scalars['String']>;
  measureHeight?: Maybe<Scalars['Int']>;
  measureThickness?: Maybe<Scalars['Int']>;
  measureWidth?: Maybe<Scalars['Int']>;
  /** 書名 */
  name: Scalars['String'];
  newOrder: Scalars['Int'];
  /** 新刊設定 */
  newStatus: Scalars['Boolean'];
  /** これから出る本設定 */
  nextBook: Scalars['Boolean'];
  opuses: PreviewOpusConnection;
  /** 概要（長文） */
  outline?: Maybe<Scalars['String']>;
  /** 概要（短文） */
  outlineAbr?: Maybe<Scalars['String']>;
  /** ページ数 */
  page?: Maybe<Scalars['Int']>;
  previewFreeItems: Array<PreviewFreeItem>;
  previewOpuses: PreviewOpusConnection;
  /** 本体価格 */
  price?: Maybe<Scalars['Int']>;
  /** 公開日時 */
  publicDate?: Maybe<Scalars['DateTime']>;
  /** 公開の状態 */
  publicStatus?: Maybe<PublicScheduledStatus>;
  /** おすすめ表示順 */
  recommendOrder?: Maybe<Scalars['Int']>;
  /** おすすめ紹介文 */
  recommendSentence?: Maybe<Scalars['String']>;
  /** おすすめ設定 */
  recommendStatus: Scalars['Boolean'];
  relatedBooks: BookConnection;
  relatedNewsPosts: NewsPostConnection;
  /** 書店発売日 */
  releaseDate?: Maybe<Scalars['DateTime']>;
  series: Array<Series>;
  seriesTree: Array<Series>;
  shopifyProductId?: Maybe<Scalars['String']>;
  stockStatus?: Maybe<Scalars['String']>;
  stockStatusEnum?: Maybe<StockStatus>;
  /** サブの書影URL */
  subImageUrls: Array<Scalars['String']>;
  /** サブタイトルカナ */
  subKana?: Maybe<Scalars['String']>;
  /** サブタイトル */
  subName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** 版 */
  version?: Maybe<Scalars['String']>;
  /** 巻次 */
  volume?: Maybe<Scalars['String']>;
  /** 試し読みURL */
  yondemillBookSalesUrl?: Maybe<Scalars['String']>;
};


export type PreviewBookEbookstoresArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PreviewBookImageUrlArgs = {
  sizeType?: Maybe<ImageSize>;
};


export type PreviewBookOpusesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PreviewBookPreviewOpusesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PreviewBookRelatedBooksArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PreviewBookRelatedNewsPostsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PreviewBookSubImageUrlsArgs = {
  limit?: Maybe<Scalars['Int']>;
  sizeType?: Maybe<ImageSize>;
};

export type PreviewBookEbookstore = {
  __typename?: 'PreviewBookEbookstore';
  book: PreviewBook;
  createdAt: Scalars['DateTime'];
  ebookstore: Ebookstore;
  /** ID */
  id: Scalars['ID'];
  /** 公開・非公開の判定 */
  publicStatus: PublicStatus;
  /** 電子書籍書店の検索結果URL */
  searchUrl: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** 書籍ごとの電子書籍書店のURL */
  url: Scalars['String'];
};

/** The connection type for PreviewBookEbookstore. */
export type PreviewBookEbookstoreConnection = {
  __typename?: 'PreviewBookEbookstoreConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PreviewBookEbookstoreEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PreviewBookEbookstore>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PreviewBookEbookstoreEdge = {
  __typename?: 'PreviewBookEbookstoreEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PreviewBookEbookstore>;
};

export type PreviewBookPagy = {
  __typename?: 'PreviewBookPagy';
  nodes: Array<PreviewBook>;
  pageInfo: Pagy;
};

export type PreviewCompany = {
  __typename?: 'PreviewCompany';
  category: CompanyCategory;
  /** @deprecated categoryを使ってください */
  companyCategory: CompanyCategory;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 会社情報タイトル */
  name: Scalars['String'];
  /** 公開日時 */
  publicDate?: Maybe<Scalars['DateTime']>;
  /** 公開ステータス */
  publicStatus: PublicScheduledStatus;
  /** 予約投稿日時 */
  reservationDate?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  /** 会社情報内容 */
  value: Scalars['String'];
};

export type PreviewCompanyPagy = {
  __typename?: 'PreviewCompanyPagy';
  nodes: Array<PreviewCompany>;
  pageInfo: Pagy;
};

export type PreviewFormatBook = {
  __typename?: 'PreviewFormatBook';
  /** 出版年月日 */
  bookDate?: Maybe<Scalars['DateTime']>;
  bookFormat?: Maybe<Scalars['String']>;
  bookFormatEnum?: Maybe<BookFormat>;
  bookFormatOther?: Maybe<Scalars['String']>;
  bookJpro?: Maybe<BookJpro>;
  bookSize?: Maybe<Scalars['String']>;
  bookSizeEnum?: Maybe<BookSize>;
  /** カート（有効／無効） */
  cartStatus: Scalars['Boolean'];
  /** Cコード */
  cCode?: Maybe<Scalars['String']>;
  /** 内容説明 */
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** 電子書籍設定 */
  ebookStatus: Scalars['Boolean'];
  ebookstores: BookEbookstoreConnection;
  /** 目次 */
  explain?: Maybe<Scalars['String']>;
  formatGroup?: Maybe<FormatGroup>;
  formatGroupId?: Maybe<Scalars['ID']>;
  formatPrimary: Scalars['Boolean'];
  freeItems: Array<FreeItem>;
  genres: Array<Genre>;
  genreTree: Array<Genre>;
  /** ID */
  id: Scalars['ID'];
  /**
   * 書影
   * @deprecated imageUrlを使用するようにしてください
   */
  image?: Maybe<Scalars['String']>;
  /** 書影URL */
  imageUrl?: Maybe<Scalars['String']>;
  /** ISBN */
  isbn?: Maybe<Scalars['String']>;
  janCode?: Maybe<Scalars['String']>;
  jpro: Scalars['Boolean'];
  /** 書名カナ */
  kana?: Maybe<Scalars['String']>;
  /** キーワード */
  keyword?: Maybe<Scalars['String']>;
  /** 雑誌コード */
  magazineCode?: Maybe<Scalars['String']>;
  /** 独自商品コード */
  managementCode?: Maybe<Scalars['String']>;
  measureHeight?: Maybe<Scalars['Int']>;
  measureThickness?: Maybe<Scalars['Int']>;
  measureWidth?: Maybe<Scalars['Int']>;
  /** 書名 */
  name: Scalars['String'];
  newOrder: Scalars['Int'];
  /** 新刊設定 */
  newStatus: Scalars['Boolean'];
  /** これから出る本設定 */
  nextBook: Scalars['Boolean'];
  opuses: OpusConnection;
  /** @deprecated format_groupを使ってください */
  otherFormats: Array<Book>;
  /** 概要（長文） */
  outline?: Maybe<Scalars['String']>;
  /** 概要（短文） */
  outlineAbr?: Maybe<Scalars['String']>;
  /** ページ数 */
  page?: Maybe<Scalars['Int']>;
  /** 本体価格 */
  price?: Maybe<Scalars['Int']>;
  /** 公開日時 */
  publicDate?: Maybe<Scalars['DateTime']>;
  /** 公開の状態 */
  publicStatus?: Maybe<PublicScheduledStatus>;
  /** おすすめ表示順 */
  recommendOrder?: Maybe<Scalars['Int']>;
  /** おすすめ紹介文 */
  recommendSentence?: Maybe<Scalars['String']>;
  /** おすすめ設定 */
  recommendStatus: Scalars['Boolean'];
  relatedBooks: BookConnection;
  relatedNewsPosts: NewsPostConnection;
  /** 書店発売日 */
  releaseDate?: Maybe<Scalars['DateTime']>;
  series: Array<Series>;
  seriesTree: Array<Series>;
  shopifyProductId?: Maybe<Scalars['String']>;
  stockStatus?: Maybe<Scalars['String']>;
  stockStatusEnum?: Maybe<StockStatus>;
  /** サブの書影URL */
  subImageUrls: Array<Scalars['String']>;
  /** サブタイトルカナ */
  subKana?: Maybe<Scalars['String']>;
  /** サブタイトル */
  subName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** 版 */
  version?: Maybe<Scalars['String']>;
  /** 巻次 */
  volume?: Maybe<Scalars['String']>;
  /** 試し読みURL */
  yondemillBookSalesUrl?: Maybe<Scalars['String']>;
};


export type PreviewFormatBookEbookstoresArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PreviewFormatBookImageUrlArgs = {
  sizeType?: Maybe<ImageSize>;
};


export type PreviewFormatBookOpusesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PreviewFormatBookOtherFormatsArgs = {
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PreviewFormatBookRelatedBooksArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PreviewFormatBookRelatedNewsPostsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PreviewFormatBookSubImageUrlsArgs = {
  sizeType?: Maybe<ImageSize>;
};

export type PreviewFormatGroup = {
  __typename?: 'PreviewFormatGroup';
  books: Array<PreviewFormatBook>;
  /** ID */
  id: Scalars['ID'];
  primaryBookId: Scalars['ID'];
};


export type PreviewFormatGroupBooksArgs = {
  withPrivate?: Maybe<Scalars['Boolean']>;
};

export type PreviewFreeItem = {
  __typename?: 'PreviewFreeItem';
  /** ID */
  id: Scalars['ID'];
  /** フリー項目設定 */
  publisherFreeItem: PublisherFreeItem;
  /** フリー項目設定ID */
  publisherFreeItemId: Scalars['ID'];
  /** 値 */
  value?: Maybe<Scalars['String']>;
};

export type PreviewNews = {
  __typename?: 'PreviewNews';
  categories: Array<NewsCategory>;
  category: NewsCategory;
  createdAt: Scalars['DateTime'];
  /** ID */
  id: Scalars['ID'];
  /** タイトル */
  name: Scalars['String'];
  /** ナビ部分への表示 */
  naviDisplay: Scalars['Boolean'];
  /** 記事の日付 */
  publicDate?: Maybe<Scalars['DateTime']>;
  /** 公開・非公開の判定 */
  publicStatus: PublicScheduledStatus;
  /** 公開URL */
  publicUrl?: Maybe<Scalars['String']>;
  relatedBooks: BookConnection;
  /** 予約投稿日時 */
  reservationDate?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  /** 内容 */
  value: Scalars['String'];
};


export type PreviewNewsRelatedBooksArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};

export type PreviewNewsPagy = {
  __typename?: 'PreviewNewsPagy';
  nodes: Array<PreviewNews>;
  pageInfo: Pagy;
};

export type PreviewOpus = {
  __typename?: 'PreviewOpus';
  author: Author;
  authorProfile?: Maybe<Scalars['String']>;
  authorType: Scalars['String'];
  authorTypeEnum: AuthorCategory;
  authorTypeOther?: Maybe<Scalars['String']>;
  contributorRole?: Maybe<Scalars['String']>;
  /** ID */
  id: Scalars['ID'];
};

/** The connection type for PreviewOpus. */
export type PreviewOpusConnection = {
  __typename?: 'PreviewOpusConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PreviewOpusEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PreviewOpus>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PreviewOpusEdge = {
  __typename?: 'PreviewOpusEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PreviewOpus>;
};

export enum ProductionNotesItemEnum {
  /** 大活字 */
  LargePrint = 'largePrint',
  /** 未選択 */
  None = 'none',
  /** オンデマンド */
  OnDemand = 'onDemand',
  /** 紙芝居 */
  PictureStory = 'pictureStory',
  /** ポストカード */
  Postcard = 'postcard',
  /** 復刊 */
  Reissue = 'reissue',
  /** 複製本 */
  Reprint = 'reprint',
  /** シールブック */
  StickerBook = 'stickerBook'
}

export enum PublicationFormEnum {
  /** オーディオ商品 */
  Audio = 'audio',
  /** コミックス */
  Comic = 'comic',
  /** 直販誌 */
  Direct = 'direct',
  /** 月刊誌（隔月刊・季刊含む） */
  Monthly = 'monthly',
  /** ムック */
  Mook = 'mook',
  /** 未選択 */
  None = 'none',
  /** PB商品 */
  PrivateBrand = 'privateBrand',
  /** 週刊誌（隔週刊・月2回刊含む） */
  Weekly = 'weekly'
}

export enum PublicScheduledStatus {
  Private = 'private',
  Public = 'public',
  Reservation = 'reservation'
}

export enum PublicStatus {
  Private = 'private',
  Public = 'public'
}

export type Publisher = {
  __typename?: 'Publisher';
  /**
   * 住所
   * @deprecated PublisherProfileを参照するようにしてください
   */
  address?: Maybe<Scalars['String']>;
  /**
   * アフィリエイト（amazon）ID
   * @deprecated PublisherProfileを参照するようにしてください
   */
  amazonAssociatesId?: Maybe<Scalars['String']>;
  author?: Maybe<Author>;
  authors: AuthorPagy;
  banner?: Maybe<Banner>;
  /**
   * ビッグバナーステータス
   * @deprecated PublisherProfileを参照するようにしてください
   */
  bannerBigStatus?: Maybe<Scalars['Boolean']>;
  banners: BannerConnection;
  bigBanner?: Maybe<BigBanner>;
  bigBanners: BigBannerConnection;
  book?: Maybe<Book>;
  /**
   * 書誌情報変更お知らせメールアドレス
   * @deprecated PublisherProfileを参照するようにしてください
   */
  bookNoticeMail?: Maybe<Scalars['String']>;
  books?: Maybe<BookPagy>;
  /**
   * ブックサービス用No
   * @deprecated PublisherProfileを参照するようにしてください
   */
  bookservice?: Maybe<Scalars['String']>;
  /**
   * カート機能
   * @deprecated PublisherProfileを参照するようにしてください
   */
  cart?: Maybe<Scalars['Boolean']>;
  /**
   * ショッピングカート メール送信先
   * @deprecated PublisherProfileを参照するようにしてください
   */
  cartMail?: Maybe<Scalars['String']>;
  /**
   * キャッチフレーズ
   * @deprecated PublisherProfileを参照するようにしてください
   */
  catchphrase?: Maybe<Scalars['String']>;
  companies: CompanyPagy;
  company?: Maybe<Company>;
  companyCategories: Array<CompanyCategory>;
  companyCategory?: Maybe<CompanyCategory>;
  companyCategoryTree: Array<CompanyCategory>;
  /**
   * お問い合わせ用メールアドレス
   * @deprecated PublisherProfileを参照するようにしてください
   */
  contactMail?: Maybe<Scalars['String']>;
  /**
   * コピーライト
   * @deprecated PublisherProfileを参照するようにしてください
   */
  copyright?: Maybe<Scalars['String']>;
  /** @deprecated PublisherProfileを参照するようにしてください */
  createdAt: Scalars['DateTime'];
  /**
   * 会社説明
   * @deprecated PublisherProfileを参照するようにしてください
   */
  description?: Maybe<Scalars['String']>;
  /**
   * デザインテンプレート指定
   * @deprecated PublisherProfileを参照するようにしてください
   */
  design?: Maybe<Scalars['String']>;
  ebookstores: PublisherEbookstoreConnection;
  /**
   * 電子書籍機能 フラグ
   * @deprecated PublisherProfileを参照するようにしてください
   */
  ebookStoreStatus?: Maybe<Scalars['Boolean']>;
  /**
   * エルパカBOOKS用アフィリエイト基本URL
   * @deprecated PublisherProfileを参照するようにしてください
   */
  erupakabooksUrl?: Maybe<Scalars['String']>;
  /**
   * Facebookアカウント
   * @deprecated PublisherProfileを参照するようにしてください
   */
  facebook?: Maybe<Scalars['String']>;
  /**
   * FAX番号
   * @deprecated PublisherProfileを参照するようにしてください
   */
  fax?: Maybe<Scalars['String']>;
  /**
   * 部署
   * @deprecated PublisherProfileを参照するようにしてください
   */
  fromPersonUnit?: Maybe<Scalars['String']>;
  genres: Array<Genre>;
  genreTree: Array<Genre>;
  /**
   * グーグルAnalytics用タグ （アクセス解析タグ）
   * @deprecated PublisherProfileを参照するようにしてください
   */
  googleAnalyticsTag?: Maybe<Scalars['String']>;
  hondanaEbooks?: Maybe<HondanaEbookPagy>;
  /** 会社コード */
  id: Scalars['ID'];
  /**
   * Instagramユーザーネーム
   * @deprecated PublisherProfileを参照するようにしてください
   */
  instagram?: Maybe<Scalars['String']>;
  /**
   * JPRO連携機能
   * @deprecated PublisherProfileを参照するようにしてください
   */
  jpro: Scalars['Boolean'];
  /**
   * 出版社名カナ
   * @deprecated PublisherProfileを参照するようにしてください
   */
  kana?: Maybe<Scalars['String']>;
  /**
   * データ連携担当者メールアドレス
   * @deprecated PublisherProfileを参照するようにしてください
   */
  linkagePersonMail?: Maybe<Scalars['String']>;
  /**
   * データ連携担当者名
   * @deprecated PublisherProfileを参照するようにしてください
   */
  linkagePersonName?: Maybe<Scalars['String']>;
  /**
   * ロゴ画像URL
   * @deprecated PublisherProfileを参照するようにしてください
   */
  logoUrl?: Maybe<Scalars['String']>;
  /** LP用お知らせカテゴリID */
  lpNewsCategoryId?: Maybe<Scalars['String']>;
  /** @deprecated PublisherProfileを参照するようにしてください */
  name: Scalars['String'];
  netshops: PublisherNetshopConnection;
  newlyPublishedBooks: BookPagy;
  newsCategories: Array<NewsCategory>;
  /**
   * お知らせカテゴリ 複数登録機能 フラグ
   * @deprecated PublisherProfileを参照するようにしてください
   */
  newsCategoryEdit: Scalars['Boolean'];
  newsCategoryTree: Array<NewsCategory>;
  newsMonthlyCount: Array<NewsMonthlyCount>;
  newsPost?: Maybe<NewsPost>;
  newsPosts: NewsPostPagy;
  /** 記事公開URL指定ステータス */
  newsPublicUrlStatus?: Maybe<Scalars['Boolean']>;
  /** @deprecated PublisherProfileを参照するようにしてください */
  ogpDescription?: Maybe<Scalars['String']>;
  /** @deprecated PublisherProfileを参照するようにしてください */
  ogpImageUrl?: Maybe<Scalars['String']>;
  /** @deprecated PublisherProfileを参照するようにしてください */
  ogpTitle?: Maybe<Scalars['String']>;
  /**
   * 担当者メールアドレス
   * @deprecated PublisherProfileを参照するようにしてください
   */
  personMail?: Maybe<Scalars['String']>;
  /**
   * 担当者
   * @deprecated PublisherProfileを参照するようにしてください
   */
  personName?: Maybe<Scalars['String']>;
  previewBook?: Maybe<PreviewBook>;
  previewBooks?: Maybe<PreviewBookPagy>;
  previewCompanies: PreviewCompanyPagy;
  previewCompany?: Maybe<PreviewCompany>;
  previewNews?: Maybe<PreviewNews>;
  previewNewsList: PreviewNewsPagy;
  profile: PublisherProfile;
  /**
   * ISBN 出版社記号
   * @deprecated PublisherProfileを参照するようにしてください
   */
  publisherPrefix?: Maybe<Scalars['String']>;
  /**
   * アフィリエイト（楽天）ID
   * @deprecated PublisherProfileを参照するようにしてください
   */
  rakutenAffiliateId?: Maybe<Scalars['String']>;
  recommendedBooks: BookConnection;
  sameGenreBooks: BookConnection;
  series: Array<Series>;
  seriesTree: Array<Series>;
  /**
   * 7&Y用アフィリエイト基本URL
   * @deprecated PublisherProfileを参照するようにしてください
   */
  sevenAndYUrl?: Maybe<Scalars['String']>;
  /**
   * スマートフォンサイト フラグ
   * @deprecated PublisherProfileを参照するようにしてください
   */
  smp: Scalars['Boolean'];
  /**
   * スマートフォンサイト テンプレートパス
   * @deprecated PublisherProfileを参照するようにしてください
   */
  smpPath?: Maybe<Scalars['String']>;
  /**
   * 電話番号
   * @deprecated PublisherProfileを参照するようにしてください
   */
  tel?: Maybe<Scalars['String']>;
  /**
   * 取引コード
   * @deprecated PublisherProfileを参照するようにしてください
   */
  transactionCode?: Maybe<Scalars['String']>;
  /**
   * Twitterアカウント
   * @deprecated PublisherProfileを参照するようにしてください
   */
  twitter?: Maybe<Scalars['String']>;
  /** @deprecated PublisherProfileを参照するようにしてください */
  updatedAt: Scalars['DateTime'];
  /** @deprecated PublisherProfileを参照するようにしてください */
  url?: Maybe<Scalars['String']>;
  users: Array<PublisherUser>;
  /**
   * YONDEMILL 販売システム連携
   * @deprecated PublisherProfileを参照するようにしてください
   */
  yondemillBookSales?: Maybe<Scalars['Boolean']>;
  /**
   * 郵便番号
   * @deprecated PublisherProfileを参照するようにしてください
   */
  zipcode?: Maybe<Scalars['String']>;
};


export type PublisherAuthorArgs = {
  id: Scalars['ID'];
};


export type PublisherAuthorsArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  q?: Maybe<AuthorSearchParams>;
};


export type PublisherBannerArgs = {
  id: Scalars['ID'];
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherBannersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherBigBannerArgs = {
  id: Scalars['ID'];
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherBigBannersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherBookArgs = {
  id: Scalars['ID'];
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherBooksArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  q?: Maybe<BookSearchParams>;
  withOtherFormat?: Maybe<Scalars['Boolean']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherCompaniesArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  q?: Maybe<CompanySearchParams>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherCompanyArgs = {
  id: Scalars['ID'];
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherCompanyCategoryArgs = {
  id: Scalars['ID'];
};


export type PublisherEbookstoresArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherHondanaEbooksArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type PublisherNetshopsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherNewlyPublishedBooksArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  withOtherFormat?: Maybe<Scalars['Boolean']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherNewsPostArgs = {
  id: Scalars['ID'];
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherNewsPostsArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  q?: Maybe<NewsPostSearchParams>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherPreviewBookArgs = {
  id: Scalars['ID'];
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherPreviewBooksArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  q?: Maybe<BookSearchParams>;
  withOtherFormat?: Maybe<Scalars['Boolean']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherPreviewCompaniesArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  q?: Maybe<CompanySearchParams>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherPreviewCompanyArgs = {
  id: Scalars['ID'];
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherPreviewNewsArgs = {
  id: Scalars['ID'];
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherPreviewNewsListArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  q?: Maybe<NewsPostSearchParams>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherRecommendedBooksArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};


export type PublisherSameGenreBooksArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  bookId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  withOtherFormat?: Maybe<Scalars['Boolean']>;
  withPrivate?: Maybe<Scalars['Boolean']>;
};

export type PublisherEbookstore = {
  __typename?: 'PublisherEbookstore';
  createdAt: Scalars['DateTime'];
  /** 並び順 */
  displayOrder: Scalars['Int'];
  ebookstore: Ebookstore;
  /** ID */
  id: Scalars['ID'];
  /** 公開フラグ */
  publicStatus: PublicStatus;
  updatedAt: Scalars['DateTime'];
};

/** The connection type for PublisherEbookstore. */
export type PublisherEbookstoreConnection = {
  __typename?: 'PublisherEbookstoreConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PublisherEbookstoreEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PublisherEbookstore>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PublisherEbookstoreEdge = {
  __typename?: 'PublisherEbookstoreEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PublisherEbookstore>;
};

export type PublisherFreeItem = {
  __typename?: 'PublisherFreeItem';
  createdAt: Scalars['DateTime'];
  /** 並び順 */
  displayOrder: Scalars['Int'];
  /** ID */
  id: Scalars['ID'];
  /** タイプ */
  itemType: FreeItemEnum;
  /** キー名 */
  key: Scalars['String'];
  /** 項目名 */
  name: Scalars['String'];
  /** 説明 */
  note?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PublisherFreeItemInput = {
  displayOrder: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  itemType: FreeItemEnum;
  key: Scalars['String'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type PublisherNetshop = {
  __typename?: 'PublisherNetshop';
  createdAt: Scalars['DateTime'];
  /** 並び順 */
  displayOrder: Scalars['Int'];
  /** ID */
  id: Scalars['ID'];
  netshop: Netshop;
  /** 公開フラグ */
  publicStatus: PublicStatus;
  updatedAt: Scalars['DateTime'];
};

/** The connection type for PublisherNetshop. */
export type PublisherNetshopConnection = {
  __typename?: 'PublisherNetshopConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PublisherNetshopEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PublisherNetshop>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PublisherNetshopEdge = {
  __typename?: 'PublisherNetshopEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PublisherNetshop>;
};

export type PublisherPagy = {
  __typename?: 'PublisherPagy';
  nodes: Array<Publisher>;
  pageInfo: Pagy;
};

export type PublisherProfile = {
  __typename?: 'PublisherProfile';
  /** 住所 */
  address?: Maybe<Scalars['String']>;
  /** アフィリエイト（amazon）ID */
  amazonAssociatesId?: Maybe<Scalars['String']>;
  authUid?: Maybe<Scalars['String']>;
  /** ビッグバナーステータス */
  bannerBigStatus?: Maybe<Scalars['Boolean']>;
  /** 書誌情報変更お知らせメールアドレス */
  bookNoticeMail?: Maybe<Scalars['String']>;
  /** ブックサービス用No */
  bookservice?: Maybe<Scalars['String']>;
  /** カート機能 */
  cart?: Maybe<Scalars['Boolean']>;
  /** ショッピングカート メール送信先 */
  cartMail?: Maybe<Scalars['String']>;
  /** キャッチフレーズ */
  catchphrase?: Maybe<Scalars['String']>;
  /** お問い合わせ用メールアドレス */
  contactMail?: Maybe<Scalars['String']>;
  /** コピーライト */
  copyright?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** 会社説明 */
  description?: Maybe<Scalars['String']>;
  /** デザインテンプレート指定 */
  design?: Maybe<Scalars['String']>;
  /** 電子書籍機能 フラグ */
  ebookStoreStatus?: Maybe<Scalars['Boolean']>;
  /** エルパカBOOKS用アフィリエイト基本URL */
  erupakabooksUrl?: Maybe<Scalars['String']>;
  /** Facebookアカウント */
  facebook?: Maybe<Scalars['String']>;
  /** FAX番号 */
  fax?: Maybe<Scalars['String']>;
  /** フリー項目 */
  freeItems?: Maybe<Array<PublisherFreeItem>>;
  /** 部署 */
  fromPersonUnit?: Maybe<Scalars['String']>;
  /** グーグルAnalytics用タグ （アクセス解析タグ） */
  googleAnalyticsTag?: Maybe<Scalars['String']>;
  hasShopifyStore: Scalars['Boolean'];
  /** 会社コード */
  id: Scalars['ID'];
  /** Instagramユーザーネーム */
  instagram?: Maybe<Scalars['String']>;
  /** JPRO連携機能 */
  jpro: Scalars['Boolean'];
  /** 出版社名カナ */
  kana?: Maybe<Scalars['String']>;
  /** データ連携担当者メールアドレス */
  linkagePersonMail?: Maybe<Scalars['String']>;
  /** データ連携担当者名 */
  linkagePersonName?: Maybe<Scalars['String']>;
  /** ロゴ画像URL */
  logoUrl?: Maybe<Scalars['String']>;
  /** LP用お知らせカテゴリID */
  lpNewsCategoryId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** お知らせカテゴリ 複数登録機能 フラグ */
  newsCategoryEdit: Scalars['Boolean'];
  /** 記事公開URL指定ステータス */
  newsPublicUrlStatus?: Maybe<Scalars['Boolean']>;
  /** 書誌ページOGP画像生成ステータス */
  ogimageStatus?: Maybe<Scalars['Boolean']>;
  ogpDescription?: Maybe<Scalars['String']>;
  ogpImageUrl?: Maybe<Scalars['String']>;
  ogpTitle?: Maybe<Scalars['String']>;
  /** ROOTのコンテンツオーナーID */
  ownerId?: Maybe<Scalars['String']>;
  /** 担当者メールアドレス */
  personMail?: Maybe<Scalars['String']>;
  /** 担当者 */
  personName?: Maybe<Scalars['String']>;
  /** ISBN 出版社記号 */
  publisherPrefix?: Maybe<Scalars['String']>;
  /** アフィリエイト（楽天）ID */
  rakutenAffiliateId?: Maybe<Scalars['String']>;
  /** 7&Y用アフィリエイト基本URL */
  sevenAndYUrl?: Maybe<Scalars['String']>;
  /** Shopify ストアフロント アクセストークン */
  shopifyStorefrontAccessToken?: Maybe<Scalars['String']>;
  shopifyUrl?: Maybe<Scalars['String']>;
  /** スマートフォンサイト フラグ */
  smp: Scalars['Boolean'];
  /** スマートフォンサイト テンプレートパス */
  smpPath?: Maybe<Scalars['String']>;
  /** 電話番号 */
  tel?: Maybe<Scalars['String']>;
  /** 取引コード */
  transactionCode?: Maybe<Scalars['String']>;
  /** Twitterアカウント */
  twitter?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
  /** YONDEMILL 販売システム連携 */
  yondemillBookSales?: Maybe<Scalars['Boolean']>;
  /** 郵便番号 */
  zipcode?: Maybe<Scalars['String']>;
};

export type PublisherSearchParams = {
  /** JPRO連携 */
  jpro?: Maybe<Scalars['Boolean']>;
  /** 出版社カナ */
  kana?: Maybe<Scalars['String']>;
  /** LP機能 */
  lpStatus?: Maybe<Scalars['Boolean']>;
  /** 出版社名 */
  name?: Maybe<Scalars['String']>;
  /** 書誌ページOGP画像生成 */
  ogimageStatus?: Maybe<Scalars['Boolean']>;
  /** Shopify連携 */
  shopifyStatus?: Maybe<Scalars['Boolean']>;
  /** 並び順 */
  sort?: Maybe<PublisherSort>;
};

export enum PublisherSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC'
}

export type PublisherUser = {
  __typename?: 'PublisherUser';
  /** メールアドレス */
  email: Scalars['String'];
  /** ID */
  id: Scalars['ID'];
  /** 名前 */
  name: Scalars['String'];
};

export type PublisherUserInput = {
  email: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  admin?: Maybe<Admin>;
  infos?: Maybe<Array<Info>>;
  publisher?: Maybe<Publisher>;
};


export type QueryPublisherArgs = {
  id: Scalars['ID'];
};

export enum ReadersWriteEnum {
  /** あり */
  Exist = 'exist',
  /** 未選択 */
  None = 'none',
  /** なし */
  NotExist = 'notExist'
}

export enum RecentPublicationPeriodEnum {
  /** 上期 */
  FirstHalf = 'firstHalf',
  /** 未選択 */
  None = 'none',
  /** 下期 */
  SecondHalf = 'secondHalf'
}

export enum RecentPublicationReaderEnum {
  /** 教養 */
  Education = 'education',
  /** 小学 */
  ElementarySchool = 'elementarySchool',
  /** 小学初 */
  ElementarySchoolLowerGrade = 'elementarySchoolLowerGrade',
  /** 小学中 */
  ElementarySchoolMiddleGrade = 'elementarySchoolMiddleGrade',
  /** 小学上 */
  ElementarySchoolUpperGrade = 'elementarySchoolUpperGrade',
  /** 一般 */
  General = 'general',
  /** 高校 */
  HighSchool = 'highSchool',
  /** 幼児 */
  Infant = 'infant',
  /** 未選択 */
  None = 'none',
  /** 中学 */
  SecondarySchool = 'secondarySchool',
  /** 専門 */
  Specialty = 'specialty',
  /** 大学 */
  University = 'university'
}

export enum RecentPublicationTypeEnum {
  /** 建築 土木工学 都市工学 */
  Architecture = 'architecture',
  /** 芸術 */
  Arts = 'arts',
  /** ビジネス */
  Business = 'business',
  /** 化学 生物学 */
  Chemistry = 'chemistry',
  /** 児童 */
  Children = 'children',
  /** 漫画 コミック */
  Comics = 'comics',
  /** 経済 財政 統計 経営 */
  Economics = 'economics',
  /** 電気 機械 */
  Electricity = 'electricity',
  /** 環境問題 */
  EnvironmentalIssues = 'environmentalIssues',
  /** 外国文学 */
  ForeignLiterature = 'foreignLiterature',
  /** 総記 */
  GeneralNotes = 'generalNotes',
  /** 地理 */
  Geography = 'geography',
  /** 歴史 */
  History = 'history',
  /** スポーツ 趣味 娯楽 */
  Hobby = 'hobby',
  /** 家庭 家事 料理 */
  Housework = 'housework',
  /** 産業 */
  Industry = 'industry',
  /** 情報科学 */
  InformationScience = 'informationScience',
  /** 日本文学 */
  JapaneseLiterature = 'japaneseLiterature',
  /** 言語 語学 */
  Language = 'language',
  /** 文学総記 */
  Literature = 'literature',
  /** 数学 物理 天文・地学 */
  Mathematics = 'mathematics',
  /** 医学 歯学 薬学 */
  Medicine = 'medicine',
  /** 新書 */
  NewBooks = 'newBooks',
  /** 未選択 */
  None = 'none',
  /** その他の工学 */
  OtherEngineering = 'otherEngineering',
  /** 文庫 */
  Paperback = 'paperback',
  /** 哲学 心理学 思想 */
  Philosophy = 'philosophy',
  /** 政治 法律 */
  Politics = 'politics',
  /** 資格試験 */
  QualificationExaminations = 'qualificationExaminations',
  /** 宗教 */
  Religion = 'religion',
  /** 社会 */
  Society = 'society',
  /** 学習参考書 */
  StudyReferenceBooks = 'studyReferenceBooks',
  /** 旅 */
  Travel = 'travel',
  /** ヤングアダルト */
  YoungAdult = 'youngAdult'
}

export enum RelationshipWithOriginalBookEnum {
  /** 異版・オンデマンド版 */
  DifferentEdition = 'differentEdition',
  /** 新版・改訂版 */
  New = 'new',
  /** 未選択 */
  None = 'none'
}

export enum RenditionLayoutEnum {
  /** 固定型 */
  PrePaginated = 'prePaginated',
  /** リフロー型 */
  Reflowable = 'reflowable'
}

export enum ResellingEnum {
  /** 非再販商品 */
  NotReselling = 'notReselling',
  /** 再販商品 */
  Reselling = 'reselling'
}

/** Autogenerated input type of RevertAdminJproHistory */
export type RevertAdminJproHistoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of RevertAdminJproHistory */
export type RevertAdminJproHistoryPayload = {
  __typename?: 'RevertAdminJproHistoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  jproHistories: Array<JproHistory>;
};

export enum RubyEnum {
  /** あり */
  Exist = 'exist',
  /** 未選択 */
  None = 'none',
  /** なし */
  NotExist = 'notExist'
}

/** Autogenerated input type of SendAllBooksToShopify */
export type SendAllBooksToShopifyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** 会社コード */
  id: Scalars['ID'];
};

/** Autogenerated return type of SendAllBooksToShopify */
export type SendAllBooksToShopifyPayload = {
  __typename?: 'SendAllBooksToShopifyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  publisher: Publisher;
};

export type Series = {
  __typename?: 'Series';
  booksCount: Scalars['Int'];
  branches: Array<Series>;
  createdAt: Scalars['DateTime'];
  /** 深度 （階層構造） */
  depth: Scalars['Int'];
  fullName: Scalars['String'];
  /** ID */
  id: Scalars['ID'];
  /** シリーズ名カナ */
  kana?: Maybe<Scalars['String']>;
  /** シリーズ名 */
  name: Scalars['String'];
  parent?: Maybe<Series>;
  updatedAt: Scalars['DateTime'];
};

/** Autogenerated input type of SortNewlyPublishedBooks */
export type SortNewlyPublishedBooksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  sort: NewlyPublishedBookSort;
};

/** Autogenerated return type of SortNewlyPublishedBooks */
export type SortNewlyPublishedBooksPayload = {
  __typename?: 'SortNewlyPublishedBooksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum StockStatus {
  InStock = 'inStock',
  None = 'none',
  Ondemand = 'ondemand',
  OutOfPrint = 'outOfPrint',
  OutOfStock = 'outOfStock',
  Reprinting = 'reprinting',
  Reservation = 'reservation',
  Short = 'short'
}

export enum SubjectCodeEnum {
  /** 芸術 */
  Arts = 'arts',
  /** ビジネス */
  Business = 'business',
  /** 児童図書 */
  Children = 'children',
  /** コミックス(書籍) */
  Comic = 'comic',
  /** コミックス(欠番扱) */
  ComicBlanket = 'comicBlanket',
  /** コミックス(廉価版) */
  ComicInexpensive = 'comicInexpensive',
  /** コミックス(雑誌扱) */
  ComicMagazine = 'comicMagazine',
  /** コミック文庫 */
  ComicPaperback = 'comicPaperback',
  /** 全集 */
  CompleteWorks = 'completeWorks',
  /** コンピュータ */
  Computer = 'computer',
  /** 教育 */
  Education = 'education',
  /** 芸能・タレント */
  Entertainment = 'entertainment',
  /** ゲーム */
  Game = 'game',
  /** 歴史・地理 */
  History = 'history',
  /** 趣味・実用 */
  Hobby = 'hobby',
  /** 工業・工学 */
  Industry = 'industry',
  /** 語学・辞事典 */
  Language = 'language',
  /** 文芸 */
  Literature = 'literature',
  /** 医学 */
  Medicine = 'medicine',
  /** ムック */
  Mook = 'mook',
  /** 自然科学 */
  NaturalSciences = 'naturalSciences',
  /** 新書 */
  NewBook = 'newBook',
  /** 新刊セット */
  NewPublicationSet = 'newPublicationSet',
  /** 未選択 */
  None = 'none',
  /** 文庫 */
  Paperback = 'paperback',
  /** 哲学・宗教 */
  Philosophy = 'philosophy',
  /** 資格・試験 */
  Qualifications = 'qualifications',
  /** 社会科学 */
  SocialSciences = 'socialSciences',
  /** 社会一般 */
  Society = 'society',
  /** スポーツ・健康 */
  Sport = 'sport',
  /** 学参 */
  StudyReference = 'studyReference',
  /** テレビ・映画化 */
  Television = 'television',
  /** ヤングアダルト */
  YoungAdult = 'youngAdult'
}

export enum SupplyRestrictionDetailEnum {
  /** 買切 */
  FirmSale = 'firmSale',
  /** 未選択 */
  None = 'none',
  /** 委託 */
  SaleOrReturn = 'saleOrReturn',
  /** 注文 */
  Unspecified = 'unspecified'
}

export enum TreePosition {
  Child = 'child',
  Left = 'left',
  Right = 'right'
}

export enum UnpricedItemTypeEnum {
  /** 単品分売不可 */
  CannotSaleSeparately = 'cannotSaleSeparately',
  /** セット商品分売不可 */
  CannotSaleSet = 'cannotSaleSet',
  /** セット商品分売可 */
  CanSaleSet = 'canSaleSet'
}


export type AuthorDetailsFragment = (
  { __typename?: 'Author' }
  & Pick<Author, 'id' | 'name' | 'kana' | 'value' | 'imageUrl' | 'booksCount'>
);

export type BannerDetailsFragment = (
  { __typename?: 'Banner' }
  & Pick<Banner, 'id' | 'name' | 'url' | 'target' | 'publicStatus' | 'displayOrder' | 'imageUrl'>
);

export type BigBannerDetailsFragment = (
  { __typename?: 'BigBanner' }
  & Pick<BigBanner, 'id' | 'name' | 'url' | 'target' | 'publicStatus' | 'displayOrder' | 'imageUrl' | 'subImageUrl'>
);

export type BookDetailsFragment = (
  { __typename?: 'Book' }
  & Pick<Book, 'id' | 'name' | 'kana' | 'volume' | 'subName' | 'subKana' | 'managementCode' | 'isbn' | 'publicDate' | 'publicStatus' | 'newStatus' | 'nextBook' | 'recommendStatus' | 'recommendOrder' | 'recommendSentence' | 'stockStatus' | 'stockStatusEnum' | 'cartStatus' | 'bookDate' | 'imageUrl' | 'subImageUrls' | 'outline' | 'outlineAbr' | 'explain' | 'content' | 'keyword' | 'janCode' | 'magazineCode' | 'cCode' | 'releaseDate' | 'version' | 'bookSizeEnum' | 'bookFormatEnum' | 'bookFormatOther' | 'measureHeight' | 'measureWidth' | 'measureThickness' | 'page' | 'price' | 'shopifyProductId' | 'ebookStatus' | 'yondemillBookSalesUrl' | 'jpro'>
  & { originalImageurl: Book['imageUrl'], subOriginalImageUrls: Book['subImageUrls'] }
  & { genres: Array<(
    { __typename?: 'Genre' }
    & Pick<Genre, 'id' | 'name' | 'fullName'>
  )>, genreTree: Array<(
    { __typename?: 'Genre' }
    & Pick<Genre, 'name'>
    & { children: Array<(
      { __typename?: 'Genre' }
      & Pick<Genre, 'name'>
      & { children: Array<(
        { __typename?: 'Genre' }
        & Pick<Genre, 'name'>
      )> }
    )> }
  )>, series: Array<(
    { __typename?: 'Series' }
    & Pick<Series, 'id' | 'name' | 'fullName'>
  )>, seriesTree: Array<(
    { __typename?: 'Series' }
    & Pick<Series, 'name'>
    & { children: Array<(
      { __typename?: 'Series' }
      & Pick<Series, 'name'>
      & { children: Array<(
        { __typename?: 'Series' }
        & Pick<Series, 'name'>
      )> }
    )> }
  )>, opuses: (
    { __typename?: 'OpusConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Opus' }
      & Pick<Opus, 'id' | 'authorTypeEnum' | 'authorTypeOther' | 'contributorRole' | 'authorProfile'>
      & { author: (
        { __typename?: 'Author' }
        & AuthorDetailsFragment
      ) }
    )>>> }
  ), relatedBooks: (
    { __typename?: 'BookConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Book' }
      & Pick<Book, 'id' | 'name' | 'bookDate' | 'imageUrl'>
    )>>> }
  ), ebookstores: (
    { __typename?: 'BookEbookstoreConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'BookEbookstore' }
      & Pick<BookEbookstore, 'id' | 'url' | 'publicStatus'>
      & { ebookstore: (
        { __typename?: 'Ebookstore' }
        & Pick<Ebookstore, 'id' | 'name'>
      ) }
    )>>> }
  ), formatGroup?: Maybe<(
    { __typename?: 'FormatGroup' }
    & Pick<FormatGroup, 'id' | 'primaryBookId'>
    & { books: Array<(
      { __typename?: 'Book' }
      & Pick<Book, 'id' | 'name' | 'bookFormatEnum' | 'bookFormatOther'>
    )> }
  )>, freeItems: Array<(
    { __typename?: 'FreeItem' }
    & Pick<FreeItem, 'id' | 'publisherFreeItemId' | 'value'>
  )>, bookJpro?: Maybe<(
    { __typename?: 'BookJpro' }
    & BookJproDetailsFragment
  )>, hondanaEbook?: Maybe<(
    { __typename?: 'HondanaEbook' }
    & Pick<HondanaEbook, 'id' | 'fileName' | 'renditionLayout' | 'trialRange' | 'convertStatus' | 'trialPublicStatus'>
  )> }
);

export type BookJproDetailsFragment = (
  { __typename?: 'BookJpro' }
  & Pick<BookJpro, 'id' | 'announcementDate' | 'audienceCodeValueEnum' | 'audienceDescriptionEnum' | 'award' | 'bandEnum' | 'bandContents' | 'bindingEnum' | 'bindingKindEnum' | 'bindingPlace' | 'bondName' | 'cdDvdEnum' | 'childrensBookGenreEnum' | 'comments' | 'competition' | 'completionEnum' | 'coverEnum' | 'deliveryDate' | 'deliveryDateAmpmEnum' | 'eachVolumeKana' | 'eachVolumeName' | 'extentEnum' | 'extentValue' | 'fontSize' | 'imageSentAt' | 'imprintKana' | 'imprintName' | 'imprintVolume' | 'intermediaryCompanyHandlingEnum' | 'intermediaryName' | 'intermediaryTransactionCode' | 'issuedDate' | 'languageEnum' | 'librarySelectionContent' | 'longDescription' | 'monthlyIssue' | 'notificationTypeEnum' | 'numberOfCohesions' | 'numberOfFirstEdition' | 'oldIsbn' | 'onSaleDate' | 'otherNoticesEnum' | 'percentageOfManga' | 'preOrderLimit' | 'priceAmount' | 'priceEffectiveUntil' | 'productFormDescription' | 'productionNotesItemEnum' | 'publicationFormEnum' | 'publicityMagazinePublication' | 'publisherName' | 'publisherPrefix' | 'publisherTransactionCode' | 'readersWriteEnum' | 'readersWritePage' | 'recentPublicationAuthor' | 'recentPublicationContent' | 'recentPublicationDate' | 'recentPublicationPeriodEnum' | 'recentPublicationReaderEnum' | 'recentPublicationTypeEnum' | 'relationshipWithOriginalBook' | 'resellingEnum' | 'resellingDate' | 'returnDeadline' | 'rubyEnum' | 'separateMaterial' | 'seriesKana' | 'seriesName' | 'seriesVolume' | 'specialBinding' | 'subVolume' | 'subjectCodeEnum' | 'supplyRestrictionDetailEnum' | 'trick' | 'unpricedItemTypeEnum' | 'weight' | 'createdAt' | 'updatedAt'>
);

export type BookListDetailsFragment = (
  { __typename?: 'Book' }
  & Pick<Book, 'id' | 'name' | 'isbn' | 'publicStatus' | 'publicDate' | 'newStatus' | 'nextBook' | 'recommendStatus' | 'stockStatus' | 'cartStatus' | 'ebookStatus' | 'bookDate' | 'jpro'>
  & { hondanaEbook?: Maybe<(
    { __typename?: 'HondanaEbook' }
    & Pick<HondanaEbook, 'id'>
  )>, genreTree: Array<(
    { __typename?: 'Genre' }
    & Pick<Genre, 'name'>
    & { children: Array<(
      { __typename?: 'Genre' }
      & Pick<Genre, 'name'>
      & { children: Array<(
        { __typename?: 'Genre' }
        & Pick<Genre, 'name'>
      )> }
    )> }
  )>, seriesTree: Array<(
    { __typename?: 'Series' }
    & Pick<Series, 'name'>
    & { children: Array<(
      { __typename?: 'Series' }
      & Pick<Series, 'name'>
      & { children: Array<(
        { __typename?: 'Series' }
        & Pick<Series, 'name'>
      )> }
    )> }
  )> }
);

export type BookSelectionDetailsFragment = (
  { __typename?: 'Book' }
  & Pick<Book, 'imageUrl' | 'formatGroupId'>
  & BookListDetailsFragment
);

export type CompanyDetailsFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'publicDate' | 'reservationDate' | 'publicStatus' | 'value'>
  & { category: (
    { __typename?: 'CompanyCategory' }
    & Pick<CompanyCategory, 'id' | 'name'>
  ) }
);

export type HondanaEbookListDetailsFragment = (
  { __typename?: 'HondanaEbook' }
  & Pick<HondanaEbook, 'id' | 'convertStatus' | 'trialPublicStatus'>
  & { book: (
    { __typename?: 'Book' }
    & Pick<Book, 'id' | 'name' | 'bookDate'>
  ) }
);

export type InfoDetailsFragment = (
  { __typename?: 'Info' }
  & Pick<Info, 'id' | 'name' | 'publicDate' | 'publicStatus' | 'value'>
);

export type JproHistoryDetailsFragment = (
  { __typename?: 'JproHistory' }
  & Pick<JproHistory, 'id' | 'state' | 'stateEnum' | 'sentAt' | 'createdAt' | 'tsvFileUrl'>
);

export type NewBookDetailsFragment = (
  { __typename?: 'Book' }
  & Pick<Book, 'id' | 'name' | 'isbn' | 'publicStatus' | 'publicDate' | 'bookDate' | 'newOrder' | 'imageUrl'>
);

export type NewsPostDetailsFragment = (
  { __typename?: 'NewsPost' }
  & Pick<NewsPost, 'id' | 'name' | 'naviDisplay' | 'publicDate' | 'reservationDate' | 'publicStatus' | 'value' | 'publicUrl'>
  & { category: (
    { __typename?: 'NewsCategory' }
    & Pick<NewsCategory, 'id' | 'name'>
  ), relatedBooks: (
    { __typename?: 'BookConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Book' }
      & Pick<Book, 'id' | 'name' | 'imageUrl' | 'bookDate'>
    )>>> }
  ) }
);

export type NewsPostListDetailsFragment = (
  { __typename?: 'NewsPost' }
  & Pick<NewsPost, 'id' | 'name' | 'naviDisplay' | 'publicDate' | 'reservationDate' | 'publicStatus'>
  & { category: (
    { __typename?: 'NewsCategory' }
    & Pick<NewsCategory, 'id' | 'name'>
  ) }
);

export type OperationLogDetailsFragment = (
  { __typename?: 'OperationLog' }
  & Pick<OperationLog, 'id' | 'userType' | 'userId' | 'userName' | 'name' | 'action' | 'state' | 'body' | 'table' | 'foreignKey' | 'createdAt' | 'updatedAt' | 'resourceUrl'>
);

export type PagyDetailsFragment = (
  { __typename?: 'Pagy' }
  & Pick<Pagy, 'count' | 'next' | 'prev' | 'from' | 'to' | 'last' | 'page' | 'pages'>
);

export type PreviewBookDetailsFragment = (
  { __typename?: 'PreviewBook' }
  & Pick<PreviewBook, 'id' | 'name' | 'kana' | 'volume' | 'subName' | 'subKana' | 'managementCode' | 'isbn' | 'publicDate' | 'publicStatus' | 'newStatus' | 'nextBook' | 'recommendStatus' | 'recommendOrder' | 'recommendSentence' | 'stockStatus' | 'stockStatusEnum' | 'cartStatus' | 'bookDate' | 'imageUrl' | 'subImageUrls' | 'outline' | 'outlineAbr' | 'explain' | 'content' | 'keyword' | 'janCode' | 'magazineCode' | 'cCode' | 'releaseDate' | 'version' | 'bookSizeEnum' | 'measureHeight' | 'measureWidth' | 'measureThickness' | 'page' | 'price' | 'shopifyProductId' | 'ebookStatus' | 'yondemillBookSalesUrl'>
  & { originalImageurl: PreviewBook['imageUrl'], subOriginalImageUrls: PreviewBook['subImageUrls'] }
  & { genres: Array<(
    { __typename?: 'Genre' }
    & Pick<Genre, 'id' | 'name' | 'fullName'>
  )>, genreTree: Array<(
    { __typename?: 'Genre' }
    & Pick<Genre, 'name'>
    & { children: Array<(
      { __typename?: 'Genre' }
      & Pick<Genre, 'name'>
      & { children: Array<(
        { __typename?: 'Genre' }
        & Pick<Genre, 'name'>
      )> }
    )> }
  )>, series: Array<(
    { __typename?: 'Series' }
    & Pick<Series, 'id' | 'name' | 'fullName'>
  )>, seriesTree: Array<(
    { __typename?: 'Series' }
    & Pick<Series, 'name'>
    & { children: Array<(
      { __typename?: 'Series' }
      & Pick<Series, 'name'>
      & { children: Array<(
        { __typename?: 'Series' }
        & Pick<Series, 'name'>
      )> }
    )> }
  )>, previewOpuses: (
    { __typename?: 'PreviewOpusConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'PreviewOpus' }
      & Pick<PreviewOpus, 'id' | 'authorTypeEnum' | 'authorTypeOther' | 'contributorRole' | 'authorProfile'>
      & { author: (
        { __typename?: 'Author' }
        & AuthorDetailsFragment
      ) }
    )>>> }
  ), relatedBooks: (
    { __typename?: 'BookConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Book' }
      & Pick<Book, 'id' | 'name' | 'bookDate' | 'imageUrl'>
    )>>> }
  ), ebookstores: (
    { __typename?: 'PreviewBookEbookstoreConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'PreviewBookEbookstore' }
      & Pick<PreviewBookEbookstore, 'id' | 'url' | 'publicStatus'>
      & { ebookstore: (
        { __typename?: 'Ebookstore' }
        & Pick<Ebookstore, 'id' | 'name'>
      ) }
    )>>> }
  ), previewFreeItems: Array<(
    { __typename?: 'PreviewFreeItem' }
    & Pick<PreviewFreeItem, 'id' | 'publisherFreeItemId' | 'value'>
  )> }
);

export type PreviewBookListDetailsFragment = (
  { __typename?: 'PreviewBook' }
  & Pick<PreviewBook, 'id' | 'name' | 'publicStatus' | 'publicDate'>
);

export type PreviewCompanyDetailsFragment = (
  { __typename?: 'PreviewCompany' }
  & Pick<PreviewCompany, 'id' | 'name' | 'publicDate' | 'reservationDate' | 'publicStatus' | 'value'>
  & { category: (
    { __typename?: 'CompanyCategory' }
    & Pick<CompanyCategory, 'id' | 'name'>
  ) }
);

export type PreviewNewsDetailsFragment = (
  { __typename?: 'PreviewNews' }
  & Pick<PreviewNews, 'id' | 'name' | 'naviDisplay' | 'publicDate' | 'reservationDate' | 'publicStatus' | 'value' | 'publicUrl'>
  & { category: (
    { __typename?: 'NewsCategory' }
    & Pick<NewsCategory, 'id' | 'name'>
  ), relatedBooks: (
    { __typename?: 'BookConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Book' }
      & Pick<Book, 'id' | 'name' | 'imageUrl' | 'bookDate'>
    )>>> }
  ) }
);

export type PublisherDetailsFragment = (
  { __typename?: 'Publisher' }
  & Pick<Publisher, 'id'>
  & { profile: (
    { __typename?: 'PublisherProfile' }
    & Pick<PublisherProfile, 'name' | 'kana' | 'zipcode' | 'address' | 'tel' | 'fax' | 'logoUrl' | 'transactionCode' | 'publisherPrefix' | 'fromPersonUnit' | 'personName' | 'personMail' | 'copyright' | 'url' | 'jpro' | 'linkagePersonName' | 'linkagePersonMail' | 'description' | 'catchphrase' | 'amazonAssociatesId' | 'rakutenAffiliateId' | 'sevenAndYUrl' | 'googleAnalyticsTag' | 'contactMail' | 'bannerBigStatus' | 'yondemillBookSales' | 'authUid' | 'twitter' | 'facebook' | 'instagram' | 'ogpTitle' | 'ogpDescription' | 'ogpImageUrl' | 'createdAt' | 'hasShopifyStore' | 'lpNewsCategoryId' | 'newsPublicUrlStatus' | 'shopifyStorefrontAccessToken' | 'ownerId' | 'ogimageStatus'>
    & { freeItems?: Maybe<Array<(
      { __typename?: 'PublisherFreeItem' }
      & Pick<PublisherFreeItem, 'id' | 'name' | 'key' | 'itemType' | 'displayOrder' | 'note'>
    )>> }
  ) }
);

export type PublisherEbookstoreDetailsFragment = (
  { __typename?: 'PublisherEbookstore' }
  & Pick<PublisherEbookstore, 'id' | 'publicStatus' | 'displayOrder'>
  & { ebookstore: (
    { __typename?: 'Ebookstore' }
    & Pick<Ebookstore, 'id' | 'name'>
  ) }
);

export type PublisherFreeItemDetailsFragment = (
  { __typename?: 'PublisherFreeItem' }
  & Pick<PublisherFreeItem, 'id' | 'name' | 'key' | 'itemType' | 'displayOrder' | 'note'>
);

export type PublisherNetshopDetailsFragment = (
  { __typename?: 'PublisherNetshop' }
  & Pick<PublisherNetshop, 'id' | 'publicStatus' | 'displayOrder'>
  & { netshop: (
    { __typename?: 'Netshop' }
    & Pick<Netshop, 'id' | 'name'>
  ) }
);

export type BulkUpdateBooksMutationVariables = Exact<{
  input: BulkUpdateBooksInput;
}>;


export type BulkUpdateBooksMutation = (
  { __typename?: 'Mutation' }
  & { bulkUpdateBooks?: Maybe<(
    { __typename?: 'BulkUpdateBooksPayload' }
    & { publisher?: Maybe<(
      { __typename?: 'Publisher' }
      & Pick<Publisher, 'id'>
    )> }
  )> }
);

export type CreateAuthorMutationVariables = Exact<{
  input: NewAuthorInput;
}>;


export type CreateAuthorMutation = (
  { __typename?: 'Mutation' }
  & { newAuthor?: Maybe<(
    { __typename?: 'NewAuthorPayload' }
    & { author: (
      { __typename?: 'Author' }
      & AuthorDetailsFragment
    ) }
  )> }
);

export type CreateBannerMutationVariables = Exact<{
  input: NewBannerInput;
}>;


export type CreateBannerMutation = (
  { __typename?: 'Mutation' }
  & { newBanner?: Maybe<(
    { __typename?: 'NewBannerPayload' }
    & { banner: (
      { __typename?: 'Banner' }
      & BannerDetailsFragment
    ) }
  )> }
);

export type CreateBigBannerMutationVariables = Exact<{
  input: NewBigBannerInput;
}>;


export type CreateBigBannerMutation = (
  { __typename?: 'Mutation' }
  & { newBigBanner?: Maybe<(
    { __typename?: 'NewBigBannerPayload' }
    & { bigBanner: (
      { __typename?: 'BigBanner' }
      & BigBannerDetailsFragment
    ) }
  )> }
);

export type CreateBookMutationVariables = Exact<{
  input: NewBookInput;
}>;


export type CreateBookMutation = (
  { __typename?: 'Mutation' }
  & { newBook?: Maybe<(
    { __typename?: 'NewBookPayload' }
    & { book?: Maybe<(
      { __typename?: 'Book' }
      & BookDetailsFragment
    )> }
  )> }
);

export type CreateCompanyMutationVariables = Exact<{
  input: NewCompanyInput;
}>;


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { newCompany?: Maybe<(
    { __typename?: 'NewCompanyPayload' }
    & { company: (
      { __typename?: 'Company' }
      & CompanyDetailsFragment
    ) }
  )> }
);

export type CreateCompanyCategoryMutationVariables = Exact<{
  input: NewCompanyCategoryInput;
}>;


export type CreateCompanyCategoryMutation = (
  { __typename?: 'Mutation' }
  & { newCompanyCategory?: Maybe<(
    { __typename?: 'NewCompanyCategoryPayload' }
    & { companyCategory: (
      { __typename?: 'CompanyCategory' }
      & Pick<CompanyCategory, 'id' | 'depth'>
      & { key: CompanyCategory['id'], title: CompanyCategory['name'] }
    ) }
  )> }
);

export type CreateGenreMutationVariables = Exact<{
  input: NewGenreInput;
}>;


export type CreateGenreMutation = (
  { __typename?: 'Mutation' }
  & { newGenre?: Maybe<(
    { __typename?: 'NewGenrePayload' }
    & { genre: (
      { __typename?: 'Genre' }
      & Pick<Genre, 'id' | 'depth'>
      & { key: Genre['id'], title: Genre['name'] }
    ) }
  )> }
);

export type CreateHondanaEbookMutationVariables = Exact<{
  input: NewHondanaEbookInput;
}>;


export type CreateHondanaEbookMutation = (
  { __typename?: 'Mutation' }
  & { newHondanaEbook?: Maybe<(
    { __typename?: 'NewHondanaEbookPayload' }
    & Pick<NewHondanaEbookPayload, 'fileUploadUrl' | 'imageUploadUrl'>
    & { hondanaEbook: (
      { __typename?: 'HondanaEbook' }
      & Pick<HondanaEbook, 'id'>
    ) }
  )> }
);

export type CreateInfoMutationVariables = Exact<{
  input: NewInfoInput;
}>;


export type CreateInfoMutation = (
  { __typename?: 'Mutation' }
  & { newInfo?: Maybe<(
    { __typename?: 'NewInfoPayload' }
    & { info: (
      { __typename?: 'Info' }
      & InfoDetailsFragment
    ) }
  )> }
);

export type CreateNewsCategoryMutationVariables = Exact<{
  input: NewNewsCategoryInput;
}>;


export type CreateNewsCategoryMutation = (
  { __typename?: 'Mutation' }
  & { newNewsCategory?: Maybe<(
    { __typename?: 'NewNewsCategoryPayload' }
    & { newsCategory: (
      { __typename?: 'NewsCategory' }
      & Pick<NewsCategory, 'id' | 'depth'>
      & { key: NewsCategory['id'], title: NewsCategory['name'] }
    ) }
  )> }
);

export type CreateNewsPostMutationVariables = Exact<{
  input: NewNewsPostInput;
}>;


export type CreateNewsPostMutation = (
  { __typename?: 'Mutation' }
  & { newNewsPost?: Maybe<(
    { __typename?: 'NewNewsPostPayload' }
    & { newsPost: (
      { __typename?: 'NewsPost' }
      & NewsPostDetailsFragment
    ) }
  )> }
);

export type CreatePreviewBookMutationVariables = Exact<{
  input: NewPreviewBookInput;
}>;


export type CreatePreviewBookMutation = (
  { __typename?: 'Mutation' }
  & { newPreviewBook?: Maybe<(
    { __typename?: 'NewPreviewBookPayload' }
    & { previewBook?: Maybe<(
      { __typename?: 'PreviewBook' }
      & PreviewBookDetailsFragment
    )> }
  )> }
);

export type CreatePreviewCompanyMutationVariables = Exact<{
  input: NewPreviewCompanyInput;
}>;


export type CreatePreviewCompanyMutation = (
  { __typename?: 'Mutation' }
  & { newPreviewCompany?: Maybe<(
    { __typename?: 'NewPreviewCompanyPayload' }
    & { previewCompany?: Maybe<(
      { __typename?: 'PreviewCompany' }
      & PreviewCompanyDetailsFragment
    )> }
  )> }
);

export type CreatePreviewNewsMutationVariables = Exact<{
  input: NewPreviewNewsInput;
}>;


export type CreatePreviewNewsMutation = (
  { __typename?: 'Mutation' }
  & { newPreviewNews?: Maybe<(
    { __typename?: 'NewPreviewNewsPayload' }
    & { previewNews: (
      { __typename?: 'PreviewNews' }
      & PreviewNewsDetailsFragment
    ) }
  )> }
);

export type CreatePublisherMutationVariables = Exact<{
  input: NewPublisherInput;
}>;


export type CreatePublisherMutation = (
  { __typename?: 'Mutation' }
  & { newPublisher?: Maybe<(
    { __typename?: 'NewPublisherPayload' }
    & { publisher: (
      { __typename?: 'Publisher' }
      & PublisherDetailsFragment
    ) }
  )> }
);

export type CreateSeriesMutationVariables = Exact<{
  input: NewSeriesInput;
}>;


export type CreateSeriesMutation = (
  { __typename?: 'Mutation' }
  & { newSeries?: Maybe<(
    { __typename?: 'NewSeriesPayload' }
    & { series: (
      { __typename?: 'Series' }
      & Pick<Series, 'id' | 'kana' | 'depth'>
      & { key: Series['id'], title: Series['name'] }
    ) }
  )> }
);

export type DestroyAuthorsMutationVariables = Exact<{
  input: DeleteAuthorsInput;
}>;


export type DestroyAuthorsMutation = (
  { __typename?: 'Mutation' }
  & { deleteAuthors?: Maybe<(
    { __typename?: 'DeleteAuthorsPayload' }
    & { authors: Array<(
      { __typename?: 'Author' }
      & AuthorDetailsFragment
    )> }
  )> }
);

export type DestroyBannersMutationVariables = Exact<{
  input: DeleteBannersInput;
}>;


export type DestroyBannersMutation = (
  { __typename?: 'Mutation' }
  & { deleteBanners?: Maybe<(
    { __typename?: 'DeleteBannersPayload' }
    & { banners: Array<(
      { __typename?: 'Banner' }
      & BannerDetailsFragment
    )> }
  )> }
);

export type DestroyBigBannersMutationVariables = Exact<{
  input: DeleteBigBannersInput;
}>;


export type DestroyBigBannersMutation = (
  { __typename?: 'Mutation' }
  & { deleteBigBanners?: Maybe<(
    { __typename?: 'DeleteBigBannersPayload' }
    & { bigBanners: Array<(
      { __typename?: 'BigBanner' }
      & BigBannerDetailsFragment
    )> }
  )> }
);

export type DestroyBooksMutationVariables = Exact<{
  input: DeleteBooksInput;
}>;


export type DestroyBooksMutation = (
  { __typename?: 'Mutation' }
  & { deleteBooks?: Maybe<(
    { __typename?: 'DeleteBooksPayload' }
    & { books: Array<(
      { __typename?: 'Book' }
      & BookDetailsFragment
    )> }
  )> }
);

export type DestroyCompaniesMutationVariables = Exact<{
  input: DeleteCompaniesInput;
}>;


export type DestroyCompaniesMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompanies?: Maybe<(
    { __typename?: 'DeleteCompaniesPayload' }
    & { companies: Array<(
      { __typename?: 'Company' }
      & CompanyDetailsFragment
    )> }
  )> }
);

export type DestroyCompanyCategoryMutationVariables = Exact<{
  input: DeleteCompanyCategoryInput;
}>;


export type DestroyCompanyCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompanyCategory?: Maybe<(
    { __typename?: 'DeleteCompanyCategoryPayload' }
    & { companyCategory: (
      { __typename?: 'CompanyCategory' }
      & Pick<CompanyCategory, 'id'>
      & { key: CompanyCategory['id'], title: CompanyCategory['name'] }
    ) }
  )> }
);

export type DestroyGenreMutationVariables = Exact<{
  input: DeleteGenreInput;
}>;


export type DestroyGenreMutation = (
  { __typename?: 'Mutation' }
  & { deleteGenre?: Maybe<(
    { __typename?: 'DeleteGenrePayload' }
    & { genre: (
      { __typename?: 'Genre' }
      & Pick<Genre, 'id'>
      & { key: Genre['id'], title: Genre['name'] }
    ) }
  )> }
);

export type DestroyHondanaEbookMutationVariables = Exact<{
  input: DeleteHondanaEbookInput;
}>;


export type DestroyHondanaEbookMutation = (
  { __typename?: 'Mutation' }
  & { deleteHondanaEbook?: Maybe<(
    { __typename?: 'DeleteHondanaEbookPayload' }
    & { hondanaEbook: (
      { __typename?: 'HondanaEbook' }
      & Pick<HondanaEbook, 'id'>
    ) }
  )> }
);

export type DestroyInfosMutationVariables = Exact<{
  input: DeleteInfosInput;
}>;


export type DestroyInfosMutation = (
  { __typename?: 'Mutation' }
  & { deleteInfos?: Maybe<(
    { __typename?: 'DeleteInfosPayload' }
    & { infos: Array<(
      { __typename?: 'Info' }
      & InfoDetailsFragment
    )> }
  )> }
);

export type DestroyNewsCategoryMutationVariables = Exact<{
  input: DeleteNewsCategoryInput;
}>;


export type DestroyNewsCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteNewsCategory?: Maybe<(
    { __typename?: 'DeleteNewsCategoryPayload' }
    & { newsCategory: (
      { __typename?: 'NewsCategory' }
      & Pick<NewsCategory, 'id'>
      & { key: NewsCategory['id'], title: NewsCategory['name'] }
    ) }
  )> }
);

export type DestroyNewsPostsMutationVariables = Exact<{
  input: DeleteNewsPostsInput;
}>;


export type DestroyNewsPostsMutation = (
  { __typename?: 'Mutation' }
  & { deleteNewsPosts?: Maybe<(
    { __typename?: 'DeleteNewsPostsPayload' }
    & { newsPosts: Array<(
      { __typename?: 'NewsPost' }
      & NewsPostDetailsFragment
    )> }
  )> }
);

export type DestroySeriesMutationVariables = Exact<{
  input: DeleteSeriesInput;
}>;


export type DestroySeriesMutation = (
  { __typename?: 'Mutation' }
  & { deleteSeries?: Maybe<(
    { __typename?: 'DeleteSeriesPayload' }
    & { series: (
      { __typename?: 'Series' }
      & Pick<Series, 'id' | 'kana'>
      & { key: Series['id'], title: Series['name'] }
    ) }
  )> }
);

export type ExportBooksMutationVariables = Exact<{
  input: ExportBooksInput;
}>;


export type ExportBooksMutation = (
  { __typename?: 'Mutation' }
  & { exportBooks?: Maybe<(
    { __typename?: 'ExportBooksPayload' }
    & { publisher?: Maybe<(
      { __typename?: 'Publisher' }
      & Pick<Publisher, 'id'>
    )> }
  )> }
);

export type ImportBooksMutationVariables = Exact<{
  input: ImportBooksInput;
}>;


export type ImportBooksMutation = (
  { __typename?: 'Mutation' }
  & { importBooks?: Maybe<(
    { __typename?: 'ImportBooksPayload' }
    & { publisher?: Maybe<(
      { __typename?: 'Publisher' }
      & Pick<Publisher, 'id'>
    )> }
  )> }
);

export type ImportPublisherMutationVariables = Exact<{
  input: ImportPublisherInput;
}>;


export type ImportPublisherMutation = (
  { __typename?: 'Mutation' }
  & { importPublisher?: Maybe<(
    { __typename?: 'ImportPublisherPayload' }
    & { publisher?: Maybe<(
      { __typename?: 'Publisher' }
      & Pick<Publisher, 'id'>
    )> }
  )> }
);

export type MoveBannerMutationVariables = Exact<{
  input: MoveBannerInput;
}>;


export type MoveBannerMutation = (
  { __typename?: 'Mutation' }
  & { moveBanner?: Maybe<(
    { __typename?: 'MoveBannerPayload' }
    & { banner: (
      { __typename?: 'Banner' }
      & Pick<Banner, 'id' | 'displayOrder'>
    ) }
  )> }
);

export type MoveBigBannerMutationVariables = Exact<{
  input: MoveBigBannerInput;
}>;


export type MoveBigBannerMutation = (
  { __typename?: 'Mutation' }
  & { moveBigBanner?: Maybe<(
    { __typename?: 'MoveBigBannerPayload' }
    & { bigBanner: (
      { __typename?: 'BigBanner' }
      & Pick<BigBanner, 'id' | 'displayOrder'>
    ) }
  )> }
);

export type MoveCompanyCategoryMutationVariables = Exact<{
  input: MoveCompanyCategoryInput;
}>;


export type MoveCompanyCategoryMutation = (
  { __typename?: 'Mutation' }
  & { moveCompanyCategory?: Maybe<(
    { __typename?: 'MoveCompanyCategoryPayload' }
    & { companyCategory: (
      { __typename?: 'CompanyCategory' }
      & Pick<CompanyCategory, 'id' | 'depth'>
      & { key: CompanyCategory['id'], title: CompanyCategory['name'] }
    ) }
  )> }
);

export type MoveGenreMutationVariables = Exact<{
  input: MoveGenreInput;
}>;


export type MoveGenreMutation = (
  { __typename?: 'Mutation' }
  & { moveGenre?: Maybe<(
    { __typename?: 'MoveGenrePayload' }
    & { genre: (
      { __typename?: 'Genre' }
      & Pick<Genre, 'id' | 'depth'>
      & { key: Genre['id'], title: Genre['name'] }
    ) }
  )> }
);

export type MoveNewlyPublishedBookMutationVariables = Exact<{
  input: MoveNewlyPublishedBookInput;
}>;


export type MoveNewlyPublishedBookMutation = (
  { __typename?: 'Mutation' }
  & { moveNewlyPublishedBook?: Maybe<(
    { __typename?: 'MoveNewlyPublishedBookPayload' }
    & { book: (
      { __typename?: 'Book' }
      & Pick<Book, 'id' | 'newOrder'>
    ) }
  )> }
);

export type MoveNewsCategoryMutationVariables = Exact<{
  input: MoveNewsCategoryInput;
}>;


export type MoveNewsCategoryMutation = (
  { __typename?: 'Mutation' }
  & { moveNewsCategory?: Maybe<(
    { __typename?: 'MoveNewsCategoryPayload' }
    & { newsCategory: (
      { __typename?: 'NewsCategory' }
      & Pick<NewsCategory, 'id' | 'depth'>
      & { key: NewsCategory['id'], title: NewsCategory['name'] }
    ) }
  )> }
);

export type MovePublisherEbookstoreMutationVariables = Exact<{
  input: MovePublisherEbookstoreInput;
}>;


export type MovePublisherEbookstoreMutation = (
  { __typename?: 'Mutation' }
  & { movePublisherEbookstore?: Maybe<(
    { __typename?: 'MovePublisherEbookstorePayload' }
    & { ebookstore: (
      { __typename?: 'PublisherEbookstore' }
      & Pick<PublisherEbookstore, 'id' | 'displayOrder'>
    ) }
  )> }
);

export type MovePublisherNetshopMutationVariables = Exact<{
  input: MovePublisherNetshopInput;
}>;


export type MovePublisherNetshopMutation = (
  { __typename?: 'Mutation' }
  & { movePublisherNetshop?: Maybe<(
    { __typename?: 'MovePublisherNetshopPayload' }
    & { netshop: (
      { __typename?: 'PublisherNetshop' }
      & Pick<PublisherNetshop, 'id' | 'displayOrder'>
    ) }
  )> }
);

export type MoveSeriesMutationVariables = Exact<{
  input: MoveSeriesInput;
}>;


export type MoveSeriesMutation = (
  { __typename?: 'Mutation' }
  & { moveSeries?: Maybe<(
    { __typename?: 'MoveSeriesPayload' }
    & { series: (
      { __typename?: 'Series' }
      & Pick<Series, 'id' | 'kana' | 'depth'>
      & { key: Series['id'], title: Series['name'] }
    ) }
  )> }
);

export type RevertAdminJproHistoryMutationVariables = Exact<{
  input: RevertAdminJproHistoryInput;
}>;


export type RevertAdminJproHistoryMutation = (
  { __typename?: 'Mutation' }
  & { revertAdminJproHistory?: Maybe<(
    { __typename?: 'RevertAdminJproHistoryPayload' }
    & { jproHistories: Array<(
      { __typename?: 'JproHistory' }
      & Pick<JproHistory, 'id' | 'state'>
    )> }
  )> }
);

export type SendAllBooksToShopifyMutationVariables = Exact<{
  input: SendAllBooksToShopifyInput;
}>;


export type SendAllBooksToShopifyMutation = (
  { __typename?: 'Mutation' }
  & { sendAllBooksToShopify?: Maybe<(
    { __typename?: 'SendAllBooksToShopifyPayload' }
    & { publisher: (
      { __typename?: 'Publisher' }
      & Pick<Publisher, 'id'>
    ) }
  )> }
);

export type SortNewlyPublishedBooksMutationVariables = Exact<{
  input: SortNewlyPublishedBooksInput;
}>;


export type SortNewlyPublishedBooksMutation = (
  { __typename?: 'Mutation' }
  & { sortNewlyPublishedBooks?: Maybe<(
    { __typename?: 'SortNewlyPublishedBooksPayload' }
    & Pick<SortNewlyPublishedBooksPayload, 'success'>
  )> }
);

export type UpdateAdminPublisherMutationVariables = Exact<{
  input: EditAdminPublisherInput;
}>;


export type UpdateAdminPublisherMutation = (
  { __typename?: 'Mutation' }
  & { editAdminPublisher?: Maybe<(
    { __typename?: 'EditAdminPublisherPayload' }
    & { publisher: (
      { __typename?: 'Publisher' }
      & PublisherDetailsFragment
    ) }
  )> }
);

export type UpdateAuthorMutationVariables = Exact<{
  input: EditAuthorInput;
}>;


export type UpdateAuthorMutation = (
  { __typename?: 'Mutation' }
  & { editAuthor?: Maybe<(
    { __typename?: 'EditAuthorPayload' }
    & { author: (
      { __typename?: 'Author' }
      & AuthorDetailsFragment
    ) }
  )> }
);

export type UpdateBannerMutationVariables = Exact<{
  input: EditBannerInput;
}>;


export type UpdateBannerMutation = (
  { __typename?: 'Mutation' }
  & { editBanner?: Maybe<(
    { __typename?: 'EditBannerPayload' }
    & { banner: (
      { __typename?: 'Banner' }
      & BannerDetailsFragment
    ) }
  )> }
);

export type UpdateBannersMutationVariables = Exact<{
  input: EditBannersInput;
}>;


export type UpdateBannersMutation = (
  { __typename?: 'Mutation' }
  & { editBanners?: Maybe<(
    { __typename?: 'EditBannersPayload' }
    & { banners: Array<(
      { __typename?: 'Banner' }
      & Pick<Banner, 'id' | 'publicStatus'>
    )> }
  )> }
);

export type UpdateBigBannerMutationVariables = Exact<{
  input: EditBigBannerInput;
}>;


export type UpdateBigBannerMutation = (
  { __typename?: 'Mutation' }
  & { editBigBanner?: Maybe<(
    { __typename?: 'EditBigBannerPayload' }
    & { bigBanner: (
      { __typename?: 'BigBanner' }
      & BigBannerDetailsFragment
    ) }
  )> }
);

export type UpdateBigBannersMutationVariables = Exact<{
  input: EditBigBannersInput;
}>;


export type UpdateBigBannersMutation = (
  { __typename?: 'Mutation' }
  & { editBigBanners?: Maybe<(
    { __typename?: 'EditBigBannersPayload' }
    & { bigBanners: Array<(
      { __typename?: 'BigBanner' }
      & Pick<BigBanner, 'id' | 'publicStatus'>
    )> }
  )> }
);

export type UpdateBookMutationVariables = Exact<{
  input: EditBookInput;
}>;


export type UpdateBookMutation = (
  { __typename?: 'Mutation' }
  & { editBook?: Maybe<(
    { __typename?: 'EditBookPayload' }
    & { book?: Maybe<(
      { __typename?: 'Book' }
      & BookDetailsFragment
    )> }
  )> }
);

export type UpdateBooksMutationVariables = Exact<{
  input: EditBooksInput;
}>;


export type UpdateBooksMutation = (
  { __typename?: 'Mutation' }
  & { editBooks?: Maybe<(
    { __typename?: 'EditBooksPayload' }
    & { books: Array<(
      { __typename?: 'Book' }
      & BookDetailsFragment
    )> }
  )> }
);

export type UpdateCompaniesMutationVariables = Exact<{
  input: EditCompaniesInput;
}>;


export type UpdateCompaniesMutation = (
  { __typename?: 'Mutation' }
  & { editCompanies?: Maybe<(
    { __typename?: 'EditCompaniesPayload' }
    & { companies: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'publicDate' | 'publicStatus'>
      & { category: (
        { __typename?: 'CompanyCategory' }
        & Pick<CompanyCategory, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type UpdateCompanyMutationVariables = Exact<{
  input: EditCompanyInput;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { editCompany?: Maybe<(
    { __typename?: 'EditCompanyPayload' }
    & { company: (
      { __typename?: 'Company' }
      & CompanyDetailsFragment
    ) }
  )> }
);

export type UpdateCompanyCategoryMutationVariables = Exact<{
  input: EditCompanyCategoryInput;
}>;


export type UpdateCompanyCategoryMutation = (
  { __typename?: 'Mutation' }
  & { editCompanyCategory?: Maybe<(
    { __typename?: 'EditCompanyCategoryPayload' }
    & { companyCategory: (
      { __typename?: 'CompanyCategory' }
      & Pick<CompanyCategory, 'id' | 'depth'>
      & { key: CompanyCategory['id'], title: CompanyCategory['name'] }
    ) }
  )> }
);

export type UpdateGenreMutationVariables = Exact<{
  input: EditGenreInput;
}>;


export type UpdateGenreMutation = (
  { __typename?: 'Mutation' }
  & { editGenre?: Maybe<(
    { __typename?: 'EditGenrePayload' }
    & { genre: (
      { __typename?: 'Genre' }
      & Pick<Genre, 'id' | 'depth'>
      & { key: Genre['id'], title: Genre['name'] }
    ) }
  )> }
);

export type UpdateHondanaEbookMutationVariables = Exact<{
  input: EditHondanaEbookInput;
}>;


export type UpdateHondanaEbookMutation = (
  { __typename?: 'Mutation' }
  & { editHondanaEbook?: Maybe<(
    { __typename?: 'EditHondanaEbookPayload' }
    & Pick<EditHondanaEbookPayload, 'fileUploadUrl' | 'imageUploadUrl'>
    & { hondanaEbook: (
      { __typename?: 'HondanaEbook' }
      & Pick<HondanaEbook, 'id'>
    ) }
  )> }
);

export type UpdateInfoMutationVariables = Exact<{
  input: EditInfoInput;
}>;


export type UpdateInfoMutation = (
  { __typename?: 'Mutation' }
  & { editInfo?: Maybe<(
    { __typename?: 'EditInfoPayload' }
    & { info: (
      { __typename?: 'Info' }
      & InfoDetailsFragment
    ) }
  )> }
);

export type UpdateNewsCategoryMutationVariables = Exact<{
  input: EditNewsCategoryInput;
}>;


export type UpdateNewsCategoryMutation = (
  { __typename?: 'Mutation' }
  & { editNewsCategory?: Maybe<(
    { __typename?: 'EditNewsCategoryPayload' }
    & { newsCategory: (
      { __typename?: 'NewsCategory' }
      & Pick<NewsCategory, 'id' | 'depth'>
      & { key: NewsCategory['id'], title: NewsCategory['name'] }
    ) }
  )> }
);

export type UpdateNewsPostMutationVariables = Exact<{
  input: EditNewsPostInput;
}>;


export type UpdateNewsPostMutation = (
  { __typename?: 'Mutation' }
  & { editNewsPost?: Maybe<(
    { __typename?: 'EditNewsPostPayload' }
    & { newsPost: (
      { __typename?: 'NewsPost' }
      & NewsPostDetailsFragment
    ) }
  )> }
);

export type UpdateNewsPostsMutationVariables = Exact<{
  input: EditNewsPostsInput;
}>;


export type UpdateNewsPostsMutation = (
  { __typename?: 'Mutation' }
  & { editNewsPosts?: Maybe<(
    { __typename?: 'EditNewsPostsPayload' }
    & { newsPosts: Array<(
      { __typename?: 'NewsPost' }
      & Pick<NewsPost, 'id' | 'publicDate' | 'publicStatus' | 'naviDisplay'>
      & { category: (
        { __typename?: 'NewsCategory' }
        & Pick<NewsCategory, 'id'>
      ) }
    )> }
  )> }
);

export type UpdatePublisherMutationVariables = Exact<{
  input: EditPublisherInput;
}>;


export type UpdatePublisherMutation = (
  { __typename?: 'Mutation' }
  & { editPublisher?: Maybe<(
    { __typename?: 'EditPublisherPayload' }
    & { publisher: (
      { __typename?: 'Publisher' }
      & PublisherDetailsFragment
    ) }
  )> }
);

export type UpdatePublisherEbookstoresMutationVariables = Exact<{
  input: EditPublisherEbookstoresInput;
}>;


export type UpdatePublisherEbookstoresMutation = (
  { __typename?: 'Mutation' }
  & { editPublisherEbookstores?: Maybe<(
    { __typename?: 'EditPublisherEbookstoresPayload' }
    & { publisherEbookstores: Array<(
      { __typename?: 'PublisherEbookstore' }
      & Pick<PublisherEbookstore, 'id' | 'publicStatus'>
    )> }
  )> }
);

export type UpdatePublisherNetshopsMutationVariables = Exact<{
  input: EditPublisherNetshopsInput;
}>;


export type UpdatePublisherNetshopsMutation = (
  { __typename?: 'Mutation' }
  & { editPublisherNetshops?: Maybe<(
    { __typename?: 'EditPublisherNetshopsPayload' }
    & { publisherNetshops: Array<(
      { __typename?: 'PublisherNetshop' }
      & Pick<PublisherNetshop, 'id' | 'publicStatus'>
    )> }
  )> }
);

export type UpdateSeriesMutationVariables = Exact<{
  input: EditSeriesInput;
}>;


export type UpdateSeriesMutation = (
  { __typename?: 'Mutation' }
  & { editSeries?: Maybe<(
    { __typename?: 'EditSeriesPayload' }
    & { series: (
      { __typename?: 'Series' }
      & Pick<Series, 'id' | 'kana' | 'depth'>
      & { key: Series['id'], title: Series['name'] }
    ) }
  )> }
);

export type AdminInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AdminInfoQuery = (
  { __typename?: 'Query' }
  & { admin?: Maybe<(
    { __typename?: 'Admin' }
    & { info?: Maybe<(
      { __typename?: 'Info' }
      & InfoDetailsFragment
    )> }
  )> }
);

export type AdminInfoListQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type AdminInfoListQuery = (
  { __typename?: 'Query' }
  & { admin?: Maybe<(
    { __typename?: 'Admin' }
    & { infos?: Maybe<(
      { __typename?: 'InfoPagy' }
      & { nodes: Array<(
        { __typename?: 'Info' }
        & InfoDetailsFragment
      )>, pageInfo: (
        { __typename?: 'Pagy' }
        & PagyDetailsFragment
      ) }
    )> }
  )> }
);

export type AdminJproHistoryListQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type AdminJproHistoryListQuery = (
  { __typename?: 'Query' }
  & { admin?: Maybe<(
    { __typename?: 'Admin' }
    & { jproHistories?: Maybe<(
      { __typename?: 'JproHistoryPagy' }
      & { nodes: Array<(
        { __typename?: 'JproHistory' }
        & JproHistoryDetailsFragment
      )>, pageInfo: (
        { __typename?: 'Pagy' }
        & PagyDetailsFragment
      ) }
    )> }
  )> }
);

export type AdminOperationLogListQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  q?: Maybe<OperationLogSearchParams>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type AdminOperationLogListQuery = (
  { __typename?: 'Query' }
  & { admin?: Maybe<(
    { __typename?: 'Admin' }
    & { operationLogs?: Maybe<(
      { __typename?: 'OperationLogPagy' }
      & { nodes: Array<(
        { __typename?: 'OperationLog' }
        & OperationLogDetailsFragment
      )>, pageInfo: (
        { __typename?: 'Pagy' }
        & PagyDetailsFragment
      ) }
    )> }
  )> }
);

export type AdminPublisherQueryVariables = Exact<{
  publisherId: Scalars['ID'];
}>;


export type AdminPublisherQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { users: Array<(
      { __typename?: 'PublisherUser' }
      & Pick<PublisherUser, 'id' | 'name' | 'email'>
    )> }
    & PublisherDetailsFragment
  )> }
);

export type AuthorQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type AuthorQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { author?: Maybe<(
      { __typename?: 'Author' }
      & AuthorDetailsFragment
    )> }
  )> }
);

export type AuthorListQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  q?: Maybe<AuthorSearchParams>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type AuthorListQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { authors: (
      { __typename?: 'AuthorPagy' }
      & { nodes: Array<(
        { __typename?: 'Author' }
        & AuthorDetailsFragment
      )>, pageInfo: (
        { __typename?: 'Pagy' }
        & Pick<Pagy, 'count' | 'next' | 'prev' | 'from' | 'to' | 'last' | 'page' | 'pages'>
      ) }
    ) }
  )> }
);

export type BannerQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type BannerQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { banner?: Maybe<(
      { __typename?: 'Banner' }
      & BannerDetailsFragment
    )> }
  )> }
);

export type BannerListQueryVariables = Exact<{
  publisherId: Scalars['ID'];
}>;


export type BannerListQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { banners: (
      { __typename?: 'BannerConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Banner' }
        & BannerDetailsFragment
      )>>> }
    ) }
  )> }
);

export type BigBannerQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type BigBannerQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { bigBanner?: Maybe<(
      { __typename?: 'BigBanner' }
      & BigBannerDetailsFragment
    )> }
  )> }
);

export type BigBannerListQueryVariables = Exact<{
  publisherId: Scalars['ID'];
}>;


export type BigBannerListQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { bigBanners: (
      { __typename?: 'BigBannerConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BigBanner' }
        & BigBannerDetailsFragment
      )>>> }
    ) }
  )> }
);

export type BookQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type BookQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { book?: Maybe<(
      { __typename?: 'Book' }
      & BookDetailsFragment
    )> }
  )> }
);

export type BookListQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  q?: Maybe<BookSearchParams>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type BookListQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { books?: Maybe<(
      { __typename?: 'BookPagy' }
      & { nodes: Array<(
        { __typename?: 'Book' }
        & BookListDetailsFragment
      )>, pageInfo: (
        { __typename?: 'Pagy' }
        & Pick<Pagy, 'count' | 'next' | 'prev' | 'from' | 'to' | 'last' | 'page' | 'pages'>
      ) }
    )> }
  )> }
);

export type BookSelectionListQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  q?: Maybe<BookSearchParams>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type BookSelectionListQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { books?: Maybe<(
      { __typename?: 'BookPagy' }
      & { nodes: Array<(
        { __typename?: 'Book' }
        & BookSelectionDetailsFragment
      )>, pageInfo: (
        { __typename?: 'Pagy' }
        & Pick<Pagy, 'count' | 'next' | 'prev' | 'from' | 'to' | 'last' | 'page' | 'pages'>
      ) }
    )> }
  )> }
);

export type CompanyQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type CompanyQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & CompanyDetailsFragment
    )> }
  )> }
);

export type CompanyCategoriesQueryVariables = Exact<{
  publisherId: Scalars['ID'];
}>;


export type CompanyCategoriesQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { companyCategories: Array<(
      { __typename?: 'CompanyCategory' }
      & Pick<CompanyCategory, 'id' | 'name' | 'depth'>
    )> }
  )> }
);

export type CompanyCategoryTreeQueryVariables = Exact<{
  publisherId: Scalars['ID'];
}>;


export type CompanyCategoryTreeQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { companyCategoryTree: Array<(
      { __typename?: 'CompanyCategory' }
      & Pick<CompanyCategory, 'id' | 'name' | 'depth'>
      & { key: CompanyCategory['id'], uneditable: CompanyCategory['isFix'] }
      & { children: Array<(
        { __typename?: 'CompanyCategory' }
        & Pick<CompanyCategory, 'id' | 'name' | 'depth'>
        & { key: CompanyCategory['id'], uneditable: CompanyCategory['isFix'] }
        & { children: Array<(
          { __typename?: 'CompanyCategory' }
          & Pick<CompanyCategory, 'id' | 'name' | 'depth'>
          & { key: CompanyCategory['id'], uneditable: CompanyCategory['isFix'] }
        )> }
      )> }
    )> }
  )> }
);

export type CompanyListQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  q?: Maybe<CompanySearchParams>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type CompanyListQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { companies: (
      { __typename?: 'CompanyPagy' }
      & { nodes: Array<(
        { __typename?: 'Company' }
        & CompanyDetailsFragment
      )>, pageInfo: (
        { __typename?: 'Pagy' }
        & PagyDetailsFragment
      ) }
    ) }
  )> }
);

export type GenreTreeQueryVariables = Exact<{
  publisherId: Scalars['ID'];
}>;


export type GenreTreeQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { genreTree: Array<(
      { __typename?: 'Genre' }
      & Pick<Genre, 'id' | 'name' | 'depth'>
      & { key: Genre['id'] }
      & { parent?: Maybe<(
        { __typename?: 'Genre' }
        & Pick<Genre, 'id' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'Genre' }
          & Pick<Genre, 'id' | 'name'>
        )> }
      )>, children: Array<(
        { __typename?: 'Genre' }
        & Pick<Genre, 'id' | 'name' | 'depth'>
        & { key: Genre['id'] }
        & { parent?: Maybe<(
          { __typename?: 'Genre' }
          & Pick<Genre, 'id' | 'name'>
          & { parent?: Maybe<(
            { __typename?: 'Genre' }
            & Pick<Genre, 'id' | 'name'>
          )> }
        )>, children: Array<(
          { __typename?: 'Genre' }
          & Pick<Genre, 'id' | 'name' | 'depth'>
          & { key: Genre['id'] }
          & { parent?: Maybe<(
            { __typename?: 'Genre' }
            & Pick<Genre, 'id' | 'name'>
            & { parent?: Maybe<(
              { __typename?: 'Genre' }
              & Pick<Genre, 'id' | 'name'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type GenresQueryVariables = Exact<{
  publisherId: Scalars['ID'];
}>;


export type GenresQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { genres: Array<(
      { __typename?: 'Genre' }
      & Pick<Genre, 'id' | 'name' | 'depth'>
    )> }
  )> }
);

export type HondanaEbookListQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type HondanaEbookListQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { hondanaEbooks?: Maybe<(
      { __typename?: 'HondanaEbookPagy' }
      & { nodes: Array<(
        { __typename?: 'HondanaEbook' }
        & HondanaEbookListDetailsFragment
      )>, pageInfo: (
        { __typename?: 'Pagy' }
        & Pick<Pagy, 'count' | 'next' | 'prev' | 'from' | 'to' | 'last' | 'page' | 'pages'>
      ) }
    )> }
  )> }
);

export type InfoListQueryVariables = Exact<{ [key: string]: never; }>;


export type InfoListQuery = (
  { __typename?: 'Query' }
  & { infos?: Maybe<Array<(
    { __typename?: 'Info' }
    & InfoDetailsFragment
  )>> }
);

export type NewBookListQueryVariables = Exact<{
  publisherId: Scalars['ID'];
}>;


export type NewBookListQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { newlyPublishedBooks: (
      { __typename?: 'BookPagy' }
      & { nodes: Array<(
        { __typename?: 'Book' }
        & NewBookDetailsFragment
      )> }
    ) }
  )> }
);

export type NewsCategoriesQueryVariables = Exact<{
  publisherId: Scalars['ID'];
}>;


export type NewsCategoriesQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { newsCategories: Array<(
      { __typename?: 'NewsCategory' }
      & Pick<NewsCategory, 'id' | 'name' | 'depth'>
    )> }
  )> }
);

export type NewsCategoryTreeQueryVariables = Exact<{
  publisherId: Scalars['ID'];
}>;


export type NewsCategoryTreeQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { newsCategoryTree: Array<(
      { __typename?: 'NewsCategory' }
      & Pick<NewsCategory, 'id' | 'name' | 'depth'>
      & { key: NewsCategory['id'], uneditable: NewsCategory['isFix'] }
      & { children: Array<(
        { __typename?: 'NewsCategory' }
        & Pick<NewsCategory, 'id' | 'name' | 'depth'>
        & { key: NewsCategory['id'], uneditable: NewsCategory['isFix'] }
        & { children: Array<(
          { __typename?: 'NewsCategory' }
          & Pick<NewsCategory, 'id' | 'name' | 'depth'>
          & { key: NewsCategory['id'], uneditable: NewsCategory['isFix'] }
        )> }
      )> }
    )> }
  )> }
);

export type NewsPostQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type NewsPostQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { newsPost?: Maybe<(
      { __typename?: 'NewsPost' }
      & NewsPostDetailsFragment
    )> }
  )> }
);

export type NewsPostListQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  q?: Maybe<NewsPostSearchParams>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type NewsPostListQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { newsPosts: (
      { __typename?: 'NewsPostPagy' }
      & { nodes: Array<(
        { __typename?: 'NewsPost' }
        & NewsPostListDetailsFragment
      )>, pageInfo: (
        { __typename?: 'Pagy' }
        & PagyDetailsFragment
      ) }
    ) }
  )> }
);

export type PreviewBookQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type PreviewBookQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { previewBook?: Maybe<(
      { __typename?: 'PreviewBook' }
      & PreviewBookDetailsFragment
    )> }
  )> }
);

export type PreviewBookListQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  q?: Maybe<BookSearchParams>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type PreviewBookListQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { previewBooks?: Maybe<(
      { __typename?: 'PreviewBookPagy' }
      & { nodes: Array<(
        { __typename?: 'PreviewBook' }
        & PreviewBookListDetailsFragment
      )>, pageInfo: (
        { __typename?: 'Pagy' }
        & Pick<Pagy, 'count' | 'next' | 'prev' | 'from' | 'to' | 'last' | 'page' | 'pages'>
      ) }
    )> }
  )> }
);

export type PreviewCompanyQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type PreviewCompanyQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { previewCompany?: Maybe<(
      { __typename?: 'PreviewCompany' }
      & PreviewCompanyDetailsFragment
    )> }
  )> }
);

export type PreviewCompanyListQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  q?: Maybe<CompanySearchParams>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type PreviewCompanyListQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { previewCompanies: (
      { __typename?: 'PreviewCompanyPagy' }
      & { nodes: Array<(
        { __typename?: 'PreviewCompany' }
        & PreviewCompanyDetailsFragment
      )>, pageInfo: (
        { __typename?: 'Pagy' }
        & PagyDetailsFragment
      ) }
    ) }
  )> }
);

export type PreviewNewsQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type PreviewNewsQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { previewNews?: Maybe<(
      { __typename?: 'PreviewNews' }
      & PreviewNewsDetailsFragment
    )> }
  )> }
);

export type PreviewNewsListQueryVariables = Exact<{
  publisherId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  q?: Maybe<NewsPostSearchParams>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type PreviewNewsListQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { previewNewsList: (
      { __typename?: 'PreviewNewsPagy' }
      & { nodes: Array<(
        { __typename?: 'PreviewNews' }
        & PreviewNewsDetailsFragment
      )>, pageInfo: (
        { __typename?: 'Pagy' }
        & PagyDetailsFragment
      ) }
    ) }
  )> }
);

export type PublisherQueryVariables = Exact<{
  publisherId: Scalars['ID'];
}>;


export type PublisherQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & PublisherDetailsFragment
  )> }
);

export type PublisherBookFormQueryVariables = Exact<{
  publisherId: Scalars['ID'];
}>;


export type PublisherBookFormQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { ebookstores: (
      { __typename?: 'PublisherEbookstoreConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'PublisherEbookstore' }
        & PublisherEbookstoreDetailsFragment
      )>>> }
    ), profile: (
      { __typename?: 'PublisherProfile' }
      & Pick<PublisherProfile, 'jpro'>
      & { freeItems?: Maybe<Array<(
        { __typename?: 'PublisherFreeItem' }
        & PublisherFreeItemDetailsFragment
      )>> }
    ) }
  )> }
);

export type PublisherEbookstoreListQueryVariables = Exact<{
  publisherId: Scalars['ID'];
}>;


export type PublisherEbookstoreListQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { ebookstores: (
      { __typename?: 'PublisherEbookstoreConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'PublisherEbookstore' }
        & PublisherEbookstoreDetailsFragment
      )>>> }
    ) }
  )> }
);

export type PublisherJproQueryVariables = Exact<{
  publisherId: Scalars['ID'];
}>;


export type PublisherJproQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & Pick<Publisher, 'id'>
    & { profile: (
      { __typename?: 'PublisherProfile' }
      & Pick<PublisherProfile, 'id' | 'jpro'>
    ) }
  )> }
);

export type PublisherNetshopListQueryVariables = Exact<{
  publisherId: Scalars['ID'];
}>;


export type PublisherNetshopListQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { netshops: (
      { __typename?: 'PublisherNetshopConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'PublisherNetshop' }
        & PublisherNetshopDetailsFragment
      )>>> }
    ) }
  )> }
);

export type PublisherSidebarQueryVariables = Exact<{
  publisherId: Scalars['ID'];
}>;


export type PublisherSidebarQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & Pick<Publisher, 'id'>
    & { profile: (
      { __typename?: 'PublisherProfile' }
      & Pick<PublisherProfile, 'id' | 'url' | 'shopifyUrl'>
    ) }
  )> }
);

export type PublishersQueryVariables = Exact<{
  q?: Maybe<PublisherSearchParams>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type PublishersQuery = (
  { __typename?: 'Query' }
  & { admin?: Maybe<(
    { __typename?: 'Admin' }
    & { publishers?: Maybe<(
      { __typename?: 'PublisherPagy' }
      & { nodes: Array<(
        { __typename?: 'Publisher' }
        & PublisherDetailsFragment
      )>, pageInfo: (
        { __typename?: 'Pagy' }
        & PagyDetailsFragment
      ) }
    )> }
  )> }
);

export type SeriesQueryVariables = Exact<{
  publisherId: Scalars['ID'];
}>;


export type SeriesQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { series: Array<(
      { __typename?: 'Series' }
      & Pick<Series, 'id' | 'name' | 'kana' | 'depth'>
    )> }
  )> }
);

export type SeriesTreeQueryVariables = Exact<{
  publisherId: Scalars['ID'];
}>;


export type SeriesTreeQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'Publisher' }
    & { seriesTree: Array<(
      { __typename?: 'Series' }
      & Pick<Series, 'id' | 'name' | 'kana' | 'depth'>
      & { key: Series['id'] }
      & { parent?: Maybe<(
        { __typename?: 'Series' }
        & Pick<Series, 'id' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'Series' }
          & Pick<Series, 'id' | 'name'>
        )> }
      )>, children: Array<(
        { __typename?: 'Series' }
        & Pick<Series, 'id' | 'name' | 'kana' | 'depth'>
        & { key: Series['id'] }
        & { parent?: Maybe<(
          { __typename?: 'Series' }
          & Pick<Series, 'id' | 'name'>
          & { parent?: Maybe<(
            { __typename?: 'Series' }
            & Pick<Series, 'id' | 'name'>
          )> }
        )>, children: Array<(
          { __typename?: 'Series' }
          & Pick<Series, 'id' | 'name' | 'kana' | 'depth'>
          & { key: Series['id'] }
          & { parent?: Maybe<(
            { __typename?: 'Series' }
            & Pick<Series, 'id' | 'name'>
            & { parent?: Maybe<(
              { __typename?: 'Series' }
              & Pick<Series, 'id' | 'name'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export const BannerDetailsFragmentDoc = gql`
    fragment BannerDetails on Banner {
  id
  name
  url
  target
  publicStatus
  displayOrder
  imageUrl
}
    `;
export const BigBannerDetailsFragmentDoc = gql`
    fragment BigBannerDetails on BigBanner {
  id
  name
  url
  target
  publicStatus
  displayOrder
  imageUrl
  subImageUrl
}
    `;
export const AuthorDetailsFragmentDoc = gql`
    fragment AuthorDetails on Author {
  id
  name
  kana
  value
  imageUrl
  booksCount
}
    `;
export const BookJproDetailsFragmentDoc = gql`
    fragment BookJproDetails on BookJpro {
  id
  announcementDate
  audienceCodeValueEnum
  audienceDescriptionEnum
  award
  bandEnum
  bandContents
  bindingEnum
  bindingKindEnum
  bindingPlace
  bondName
  cdDvdEnum
  childrensBookGenreEnum
  comments
  competition
  completionEnum
  coverEnum
  deliveryDate
  deliveryDateAmpmEnum
  eachVolumeKana
  eachVolumeName
  extentEnum
  extentValue
  fontSize
  imageSentAt
  imprintKana
  imprintName
  imprintVolume
  intermediaryCompanyHandlingEnum
  intermediaryName
  intermediaryTransactionCode
  issuedDate
  languageEnum
  librarySelectionContent
  longDescription
  monthlyIssue
  notificationTypeEnum
  numberOfCohesions
  numberOfFirstEdition
  oldIsbn
  onSaleDate
  otherNoticesEnum
  percentageOfManga
  preOrderLimit
  priceAmount
  priceEffectiveUntil
  productFormDescription
  productionNotesItemEnum
  publicationFormEnum
  publicityMagazinePublication
  publisherName
  publisherPrefix
  publisherTransactionCode
  readersWriteEnum
  readersWritePage
  recentPublicationAuthor
  recentPublicationContent
  recentPublicationDate
  recentPublicationPeriodEnum
  recentPublicationReaderEnum
  recentPublicationTypeEnum
  relationshipWithOriginalBook
  resellingEnum
  resellingDate
  returnDeadline
  rubyEnum
  separateMaterial
  seriesKana
  seriesName
  seriesVolume
  specialBinding
  subVolume
  subjectCodeEnum
  supplyRestrictionDetailEnum
  trick
  unpricedItemTypeEnum
  weight
  createdAt
  updatedAt
}
    `;
export const BookDetailsFragmentDoc = gql`
    fragment BookDetails on Book {
  id
  name
  kana
  volume
  subName
  subKana
  managementCode
  isbn
  publicDate
  publicStatus
  newStatus
  nextBook
  recommendStatus
  recommendOrder
  recommendSentence
  stockStatus
  stockStatusEnum
  cartStatus
  bookDate
  imageUrl
  originalImageurl: imageUrl(sizeType: original)
  subImageUrls
  subOriginalImageUrls: subImageUrls(sizeType: original)
  outline
  outlineAbr
  explain
  content
  keyword
  janCode
  magazineCode
  cCode
  releaseDate
  version
  bookSizeEnum
  bookFormatEnum
  bookFormatOther
  measureHeight
  measureWidth
  measureThickness
  page
  price
  shopifyProductId
  genres {
    id
    name
    fullName
  }
  genreTree {
    name
    children: branches {
      name
      children: branches {
        name
      }
    }
  }
  series {
    id
    name
    fullName
  }
  seriesTree {
    name
    children: branches {
      name
      children: branches {
        name
      }
    }
  }
  opuses {
    nodes {
      id
      authorTypeEnum
      authorTypeOther
      contributorRole
      authorProfile
      author {
        ...AuthorDetails
      }
    }
  }
  relatedBooks(withPrivate: true) {
    nodes {
      id
      name
      bookDate
      imageUrl
    }
  }
  ebookStatus
  ebookstores(withPrivate: true) {
    nodes {
      id
      url
      publicStatus
      ebookstore {
        id
        name
      }
    }
  }
  formatGroup {
    id
    primaryBookId
    books(withPrivate: true) {
      id
      name
      bookFormatEnum
      bookFormatOther
    }
  }
  yondemillBookSalesUrl
  freeItems {
    id
    publisherFreeItemId
    value
  }
  jpro
  bookJpro {
    ...BookJproDetails
  }
  hondanaEbook {
    id
    fileName
    renditionLayout
    trialRange
    convertStatus
    trialPublicStatus
  }
}
    ${AuthorDetailsFragmentDoc}
${BookJproDetailsFragmentDoc}`;
export const BookListDetailsFragmentDoc = gql`
    fragment BookListDetails on Book {
  id
  name
  isbn
  publicStatus
  publicDate
  newStatus
  nextBook
  recommendStatus
  stockStatus
  cartStatus
  ebookStatus
  bookDate
  jpro
  hondanaEbook {
    id
  }
  genreTree {
    name
    children: branches {
      name
      children: branches {
        name
      }
    }
  }
  seriesTree {
    name
    children: branches {
      name
      children: branches {
        name
      }
    }
  }
}
    `;
export const BookSelectionDetailsFragmentDoc = gql`
    fragment BookSelectionDetails on Book {
  ...BookListDetails
  imageUrl
  formatGroupId
}
    ${BookListDetailsFragmentDoc}`;
export const CompanyDetailsFragmentDoc = gql`
    fragment CompanyDetails on Company {
  id
  name
  publicDate
  reservationDate
  publicStatus
  value
  category {
    id
    name
  }
}
    `;
export const HondanaEbookListDetailsFragmentDoc = gql`
    fragment HondanaEbookListDetails on HondanaEbook {
  id
  convertStatus
  trialPublicStatus
  book {
    id
    name
    bookDate
  }
}
    `;
export const InfoDetailsFragmentDoc = gql`
    fragment InfoDetails on Info {
  id
  name
  publicDate
  publicStatus
  value
}
    `;
export const JproHistoryDetailsFragmentDoc = gql`
    fragment JproHistoryDetails on JproHistory {
  id
  state
  stateEnum
  sentAt
  createdAt
  tsvFileUrl
}
    `;
export const NewBookDetailsFragmentDoc = gql`
    fragment NewBookDetails on Book {
  id
  name
  isbn
  publicStatus
  publicDate
  bookDate
  newOrder
  imageUrl
}
    `;
export const NewsPostDetailsFragmentDoc = gql`
    fragment NewsPostDetails on NewsPost {
  id
  name
  naviDisplay
  publicDate
  reservationDate
  publicStatus
  value
  publicUrl
  category {
    id
    name
  }
  relatedBooks(withPrivate: true) {
    nodes {
      id
      name
      imageUrl
      bookDate
    }
  }
}
    `;
export const NewsPostListDetailsFragmentDoc = gql`
    fragment NewsPostListDetails on NewsPost {
  id
  name
  naviDisplay
  publicDate
  reservationDate
  publicStatus
  category {
    id
    name
  }
}
    `;
export const OperationLogDetailsFragmentDoc = gql`
    fragment OperationLogDetails on OperationLog {
  id
  userType
  userId
  userName
  name
  action
  state
  body
  table
  foreignKey
  createdAt
  updatedAt
  resourceUrl
}
    `;
export const PagyDetailsFragmentDoc = gql`
    fragment PagyDetails on Pagy {
  count
  next
  prev
  from
  to
  last
  page
  pages
}
    `;
export const PreviewBookDetailsFragmentDoc = gql`
    fragment PreviewBookDetails on PreviewBook {
  id
  name
  kana
  volume
  subName
  subKana
  managementCode
  isbn
  publicDate
  publicStatus
  newStatus
  nextBook
  recommendStatus
  recommendOrder
  recommendSentence
  stockStatus
  stockStatusEnum
  cartStatus
  bookDate
  imageUrl
  originalImageurl: imageUrl(sizeType: original)
  subImageUrls
  subOriginalImageUrls: subImageUrls(sizeType: original)
  outline
  outlineAbr
  explain
  content
  keyword
  janCode
  magazineCode
  cCode
  releaseDate
  version
  bookSizeEnum
  measureHeight
  measureWidth
  measureThickness
  page
  price
  shopifyProductId
  genres {
    id
    name
    fullName
  }
  genreTree {
    name
    children: branches {
      name
      children: branches {
        name
      }
    }
  }
  series {
    id
    name
    fullName
  }
  seriesTree {
    name
    children: branches {
      name
      children: branches {
        name
      }
    }
  }
  previewOpuses {
    nodes {
      id
      authorTypeEnum
      authorTypeOther
      contributorRole
      authorProfile
      author {
        ...AuthorDetails
      }
    }
  }
  relatedBooks(withPrivate: true) {
    nodes {
      id
      name
      bookDate
      imageUrl
    }
  }
  ebookStatus
  ebookstores(withPrivate: true) {
    nodes {
      id
      url
      publicStatus
      ebookstore {
        id
        name
      }
    }
  }
  yondemillBookSalesUrl
  previewFreeItems {
    id
    publisherFreeItemId
    value
  }
}
    ${AuthorDetailsFragmentDoc}`;
export const PreviewBookListDetailsFragmentDoc = gql`
    fragment PreviewBookListDetails on PreviewBook {
  id
  name
  publicStatus
  publicDate
}
    `;
export const PreviewCompanyDetailsFragmentDoc = gql`
    fragment PreviewCompanyDetails on PreviewCompany {
  id
  name
  publicDate
  reservationDate
  publicStatus
  value
  category {
    id
    name
  }
}
    `;
export const PreviewNewsDetailsFragmentDoc = gql`
    fragment PreviewNewsDetails on PreviewNews {
  id
  name
  naviDisplay
  publicDate
  reservationDate
  publicStatus
  value
  publicUrl
  category {
    id
    name
  }
  relatedBooks(withPrivate: true) {
    nodes {
      id
      name
      imageUrl
      bookDate
    }
  }
}
    `;
export const PublisherDetailsFragmentDoc = gql`
    fragment PublisherDetails on Publisher {
  id
  profile {
    name
    kana
    zipcode
    address
    tel
    fax
    logoUrl
    transactionCode
    publisherPrefix
    fromPersonUnit
    personName
    personMail
    copyright
    url
    jpro
    linkagePersonName
    linkagePersonMail
    description
    catchphrase
    amazonAssociatesId
    rakutenAffiliateId
    sevenAndYUrl
    googleAnalyticsTag
    contactMail
    bannerBigStatus
    yondemillBookSales
    authUid
    twitter
    facebook
    instagram
    ogpTitle
    ogpDescription
    ogpImageUrl
    createdAt
    hasShopifyStore
    lpNewsCategoryId
    newsPublicUrlStatus
    shopifyStorefrontAccessToken
    ownerId
    ogimageStatus
    freeItems {
      id
      name
      key
      itemType
      displayOrder
      note
    }
  }
}
    `;
export const PublisherEbookstoreDetailsFragmentDoc = gql`
    fragment PublisherEbookstoreDetails on PublisherEbookstore {
  id
  publicStatus
  displayOrder
  ebookstore {
    id
    name
  }
}
    `;
export const PublisherFreeItemDetailsFragmentDoc = gql`
    fragment PublisherFreeItemDetails on PublisherFreeItem {
  id
  name
  key
  itemType
  displayOrder
  note
}
    `;
export const PublisherNetshopDetailsFragmentDoc = gql`
    fragment PublisherNetshopDetails on PublisherNetshop {
  id
  publicStatus
  displayOrder
  netshop {
    id
    name
  }
}
    `;
export const BulkUpdateBooksDocument = gql`
    mutation bulkUpdateBooks($input: BulkUpdateBooksInput!) {
  bulkUpdateBooks(input: $input) {
    publisher {
      id
    }
  }
}
    `;
export type BulkUpdateBooksMutationFn = Apollo.MutationFunction<BulkUpdateBooksMutation, BulkUpdateBooksMutationVariables>;

/**
 * __useBulkUpdateBooksMutation__
 *
 * To run a mutation, you first call `useBulkUpdateBooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateBooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateBooksMutation, { data, loading, error }] = useBulkUpdateBooksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateBooksMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateBooksMutation, BulkUpdateBooksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateBooksMutation, BulkUpdateBooksMutationVariables>(BulkUpdateBooksDocument, options);
      }
export type BulkUpdateBooksMutationHookResult = ReturnType<typeof useBulkUpdateBooksMutation>;
export type BulkUpdateBooksMutationResult = Apollo.MutationResult<BulkUpdateBooksMutation>;
export type BulkUpdateBooksMutationOptions = Apollo.BaseMutationOptions<BulkUpdateBooksMutation, BulkUpdateBooksMutationVariables>;
export const CreateAuthorDocument = gql`
    mutation createAuthor($input: NewAuthorInput!) {
  newAuthor(input: $input) {
    author {
      ...AuthorDetails
    }
  }
}
    ${AuthorDetailsFragmentDoc}`;
export type CreateAuthorMutationFn = Apollo.MutationFunction<CreateAuthorMutation, CreateAuthorMutationVariables>;

/**
 * __useCreateAuthorMutation__
 *
 * To run a mutation, you first call `useCreateAuthorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuthorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuthorMutation, { data, loading, error }] = useCreateAuthorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAuthorMutation(baseOptions?: Apollo.MutationHookOptions<CreateAuthorMutation, CreateAuthorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAuthorMutation, CreateAuthorMutationVariables>(CreateAuthorDocument, options);
      }
export type CreateAuthorMutationHookResult = ReturnType<typeof useCreateAuthorMutation>;
export type CreateAuthorMutationResult = Apollo.MutationResult<CreateAuthorMutation>;
export type CreateAuthorMutationOptions = Apollo.BaseMutationOptions<CreateAuthorMutation, CreateAuthorMutationVariables>;
export const CreateBannerDocument = gql`
    mutation createBanner($input: NewBannerInput!) {
  newBanner(input: $input) {
    banner {
      ...BannerDetails
    }
  }
}
    ${BannerDetailsFragmentDoc}`;
export type CreateBannerMutationFn = Apollo.MutationFunction<CreateBannerMutation, CreateBannerMutationVariables>;

/**
 * __useCreateBannerMutation__
 *
 * To run a mutation, you first call `useCreateBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBannerMutation, { data, loading, error }] = useCreateBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBannerMutation(baseOptions?: Apollo.MutationHookOptions<CreateBannerMutation, CreateBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBannerMutation, CreateBannerMutationVariables>(CreateBannerDocument, options);
      }
export type CreateBannerMutationHookResult = ReturnType<typeof useCreateBannerMutation>;
export type CreateBannerMutationResult = Apollo.MutationResult<CreateBannerMutation>;
export type CreateBannerMutationOptions = Apollo.BaseMutationOptions<CreateBannerMutation, CreateBannerMutationVariables>;
export const CreateBigBannerDocument = gql`
    mutation createBigBanner($input: NewBigBannerInput!) {
  newBigBanner(input: $input) {
    bigBanner {
      ...BigBannerDetails
    }
  }
}
    ${BigBannerDetailsFragmentDoc}`;
export type CreateBigBannerMutationFn = Apollo.MutationFunction<CreateBigBannerMutation, CreateBigBannerMutationVariables>;

/**
 * __useCreateBigBannerMutation__
 *
 * To run a mutation, you first call `useCreateBigBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBigBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBigBannerMutation, { data, loading, error }] = useCreateBigBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBigBannerMutation(baseOptions?: Apollo.MutationHookOptions<CreateBigBannerMutation, CreateBigBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBigBannerMutation, CreateBigBannerMutationVariables>(CreateBigBannerDocument, options);
      }
export type CreateBigBannerMutationHookResult = ReturnType<typeof useCreateBigBannerMutation>;
export type CreateBigBannerMutationResult = Apollo.MutationResult<CreateBigBannerMutation>;
export type CreateBigBannerMutationOptions = Apollo.BaseMutationOptions<CreateBigBannerMutation, CreateBigBannerMutationVariables>;
export const CreateBookDocument = gql`
    mutation createBook($input: NewBookInput!) {
  newBook(input: $input) {
    book {
      ...BookDetails
    }
  }
}
    ${BookDetailsFragmentDoc}`;
export type CreateBookMutationFn = Apollo.MutationFunction<CreateBookMutation, CreateBookMutationVariables>;

/**
 * __useCreateBookMutation__
 *
 * To run a mutation, you first call `useCreateBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookMutation, { data, loading, error }] = useCreateBookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookMutation, CreateBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookMutation, CreateBookMutationVariables>(CreateBookDocument, options);
      }
export type CreateBookMutationHookResult = ReturnType<typeof useCreateBookMutation>;
export type CreateBookMutationResult = Apollo.MutationResult<CreateBookMutation>;
export type CreateBookMutationOptions = Apollo.BaseMutationOptions<CreateBookMutation, CreateBookMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($input: NewCompanyInput!) {
  newCompany(input: $input) {
    company {
      ...CompanyDetails
    }
  }
}
    ${CompanyDetailsFragmentDoc}`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const CreateCompanyCategoryDocument = gql`
    mutation createCompanyCategory($input: NewCompanyCategoryInput!) {
  newCompanyCategory(input: $input) {
    companyCategory {
      id
      key: id
      title: name
      depth
    }
  }
}
    `;
export type CreateCompanyCategoryMutationFn = Apollo.MutationFunction<CreateCompanyCategoryMutation, CreateCompanyCategoryMutationVariables>;

/**
 * __useCreateCompanyCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCompanyCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyCategoryMutation, { data, loading, error }] = useCreateCompanyCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyCategoryMutation, CreateCompanyCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyCategoryMutation, CreateCompanyCategoryMutationVariables>(CreateCompanyCategoryDocument, options);
      }
export type CreateCompanyCategoryMutationHookResult = ReturnType<typeof useCreateCompanyCategoryMutation>;
export type CreateCompanyCategoryMutationResult = Apollo.MutationResult<CreateCompanyCategoryMutation>;
export type CreateCompanyCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCompanyCategoryMutation, CreateCompanyCategoryMutationVariables>;
export const CreateGenreDocument = gql`
    mutation createGenre($input: NewGenreInput!) {
  newGenre(input: $input) {
    genre {
      id
      key: id
      title: name
      depth
    }
  }
}
    `;
export type CreateGenreMutationFn = Apollo.MutationFunction<CreateGenreMutation, CreateGenreMutationVariables>;

/**
 * __useCreateGenreMutation__
 *
 * To run a mutation, you first call `useCreateGenreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGenreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGenreMutation, { data, loading, error }] = useCreateGenreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGenreMutation(baseOptions?: Apollo.MutationHookOptions<CreateGenreMutation, CreateGenreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGenreMutation, CreateGenreMutationVariables>(CreateGenreDocument, options);
      }
export type CreateGenreMutationHookResult = ReturnType<typeof useCreateGenreMutation>;
export type CreateGenreMutationResult = Apollo.MutationResult<CreateGenreMutation>;
export type CreateGenreMutationOptions = Apollo.BaseMutationOptions<CreateGenreMutation, CreateGenreMutationVariables>;
export const CreateHondanaEbookDocument = gql`
    mutation createHondanaEbook($input: NewHondanaEbookInput!) {
  newHondanaEbook(input: $input) {
    fileUploadUrl
    imageUploadUrl
    hondanaEbook {
      id
    }
  }
}
    `;
export type CreateHondanaEbookMutationFn = Apollo.MutationFunction<CreateHondanaEbookMutation, CreateHondanaEbookMutationVariables>;

/**
 * __useCreateHondanaEbookMutation__
 *
 * To run a mutation, you first call `useCreateHondanaEbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHondanaEbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHondanaEbookMutation, { data, loading, error }] = useCreateHondanaEbookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHondanaEbookMutation(baseOptions?: Apollo.MutationHookOptions<CreateHondanaEbookMutation, CreateHondanaEbookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHondanaEbookMutation, CreateHondanaEbookMutationVariables>(CreateHondanaEbookDocument, options);
      }
export type CreateHondanaEbookMutationHookResult = ReturnType<typeof useCreateHondanaEbookMutation>;
export type CreateHondanaEbookMutationResult = Apollo.MutationResult<CreateHondanaEbookMutation>;
export type CreateHondanaEbookMutationOptions = Apollo.BaseMutationOptions<CreateHondanaEbookMutation, CreateHondanaEbookMutationVariables>;
export const CreateInfoDocument = gql`
    mutation createInfo($input: NewInfoInput!) {
  newInfo(input: $input) {
    info {
      ...InfoDetails
    }
  }
}
    ${InfoDetailsFragmentDoc}`;
export type CreateInfoMutationFn = Apollo.MutationFunction<CreateInfoMutation, CreateInfoMutationVariables>;

/**
 * __useCreateInfoMutation__
 *
 * To run a mutation, you first call `useCreateInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInfoMutation, { data, loading, error }] = useCreateInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInfoMutation(baseOptions?: Apollo.MutationHookOptions<CreateInfoMutation, CreateInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInfoMutation, CreateInfoMutationVariables>(CreateInfoDocument, options);
      }
export type CreateInfoMutationHookResult = ReturnType<typeof useCreateInfoMutation>;
export type CreateInfoMutationResult = Apollo.MutationResult<CreateInfoMutation>;
export type CreateInfoMutationOptions = Apollo.BaseMutationOptions<CreateInfoMutation, CreateInfoMutationVariables>;
export const CreateNewsCategoryDocument = gql`
    mutation createNewsCategory($input: NewNewsCategoryInput!) {
  newNewsCategory(input: $input) {
    newsCategory {
      id
      key: id
      title: name
      depth
    }
  }
}
    `;
export type CreateNewsCategoryMutationFn = Apollo.MutationFunction<CreateNewsCategoryMutation, CreateNewsCategoryMutationVariables>;

/**
 * __useCreateNewsCategoryMutation__
 *
 * To run a mutation, you first call `useCreateNewsCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsCategoryMutation, { data, loading, error }] = useCreateNewsCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewsCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewsCategoryMutation, CreateNewsCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewsCategoryMutation, CreateNewsCategoryMutationVariables>(CreateNewsCategoryDocument, options);
      }
export type CreateNewsCategoryMutationHookResult = ReturnType<typeof useCreateNewsCategoryMutation>;
export type CreateNewsCategoryMutationResult = Apollo.MutationResult<CreateNewsCategoryMutation>;
export type CreateNewsCategoryMutationOptions = Apollo.BaseMutationOptions<CreateNewsCategoryMutation, CreateNewsCategoryMutationVariables>;
export const CreateNewsPostDocument = gql`
    mutation createNewsPost($input: NewNewsPostInput!) {
  newNewsPost(input: $input) {
    newsPost {
      ...NewsPostDetails
    }
  }
}
    ${NewsPostDetailsFragmentDoc}`;
export type CreateNewsPostMutationFn = Apollo.MutationFunction<CreateNewsPostMutation, CreateNewsPostMutationVariables>;

/**
 * __useCreateNewsPostMutation__
 *
 * To run a mutation, you first call `useCreateNewsPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsPostMutation, { data, loading, error }] = useCreateNewsPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewsPostMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewsPostMutation, CreateNewsPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewsPostMutation, CreateNewsPostMutationVariables>(CreateNewsPostDocument, options);
      }
export type CreateNewsPostMutationHookResult = ReturnType<typeof useCreateNewsPostMutation>;
export type CreateNewsPostMutationResult = Apollo.MutationResult<CreateNewsPostMutation>;
export type CreateNewsPostMutationOptions = Apollo.BaseMutationOptions<CreateNewsPostMutation, CreateNewsPostMutationVariables>;
export const CreatePreviewBookDocument = gql`
    mutation createPreviewBook($input: NewPreviewBookInput!) {
  newPreviewBook(input: $input) {
    previewBook {
      ...PreviewBookDetails
    }
  }
}
    ${PreviewBookDetailsFragmentDoc}`;
export type CreatePreviewBookMutationFn = Apollo.MutationFunction<CreatePreviewBookMutation, CreatePreviewBookMutationVariables>;

/**
 * __useCreatePreviewBookMutation__
 *
 * To run a mutation, you first call `useCreatePreviewBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreviewBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreviewBookMutation, { data, loading, error }] = useCreatePreviewBookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePreviewBookMutation(baseOptions?: Apollo.MutationHookOptions<CreatePreviewBookMutation, CreatePreviewBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePreviewBookMutation, CreatePreviewBookMutationVariables>(CreatePreviewBookDocument, options);
      }
export type CreatePreviewBookMutationHookResult = ReturnType<typeof useCreatePreviewBookMutation>;
export type CreatePreviewBookMutationResult = Apollo.MutationResult<CreatePreviewBookMutation>;
export type CreatePreviewBookMutationOptions = Apollo.BaseMutationOptions<CreatePreviewBookMutation, CreatePreviewBookMutationVariables>;
export const CreatePreviewCompanyDocument = gql`
    mutation createPreviewCompany($input: NewPreviewCompanyInput!) {
  newPreviewCompany(input: $input) {
    previewCompany {
      ...PreviewCompanyDetails
    }
  }
}
    ${PreviewCompanyDetailsFragmentDoc}`;
export type CreatePreviewCompanyMutationFn = Apollo.MutationFunction<CreatePreviewCompanyMutation, CreatePreviewCompanyMutationVariables>;

/**
 * __useCreatePreviewCompanyMutation__
 *
 * To run a mutation, you first call `useCreatePreviewCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreviewCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreviewCompanyMutation, { data, loading, error }] = useCreatePreviewCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePreviewCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreatePreviewCompanyMutation, CreatePreviewCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePreviewCompanyMutation, CreatePreviewCompanyMutationVariables>(CreatePreviewCompanyDocument, options);
      }
export type CreatePreviewCompanyMutationHookResult = ReturnType<typeof useCreatePreviewCompanyMutation>;
export type CreatePreviewCompanyMutationResult = Apollo.MutationResult<CreatePreviewCompanyMutation>;
export type CreatePreviewCompanyMutationOptions = Apollo.BaseMutationOptions<CreatePreviewCompanyMutation, CreatePreviewCompanyMutationVariables>;
export const CreatePreviewNewsDocument = gql`
    mutation createPreviewNews($input: NewPreviewNewsInput!) {
  newPreviewNews(input: $input) {
    previewNews {
      ...PreviewNewsDetails
    }
  }
}
    ${PreviewNewsDetailsFragmentDoc}`;
export type CreatePreviewNewsMutationFn = Apollo.MutationFunction<CreatePreviewNewsMutation, CreatePreviewNewsMutationVariables>;

/**
 * __useCreatePreviewNewsMutation__
 *
 * To run a mutation, you first call `useCreatePreviewNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreviewNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreviewNewsMutation, { data, loading, error }] = useCreatePreviewNewsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePreviewNewsMutation(baseOptions?: Apollo.MutationHookOptions<CreatePreviewNewsMutation, CreatePreviewNewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePreviewNewsMutation, CreatePreviewNewsMutationVariables>(CreatePreviewNewsDocument, options);
      }
export type CreatePreviewNewsMutationHookResult = ReturnType<typeof useCreatePreviewNewsMutation>;
export type CreatePreviewNewsMutationResult = Apollo.MutationResult<CreatePreviewNewsMutation>;
export type CreatePreviewNewsMutationOptions = Apollo.BaseMutationOptions<CreatePreviewNewsMutation, CreatePreviewNewsMutationVariables>;
export const CreatePublisherDocument = gql`
    mutation createPublisher($input: NewPublisherInput!) {
  newPublisher(input: $input) {
    publisher {
      ...PublisherDetails
    }
  }
}
    ${PublisherDetailsFragmentDoc}`;
export type CreatePublisherMutationFn = Apollo.MutationFunction<CreatePublisherMutation, CreatePublisherMutationVariables>;

/**
 * __useCreatePublisherMutation__
 *
 * To run a mutation, you first call `useCreatePublisherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublisherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublisherMutation, { data, loading, error }] = useCreatePublisherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePublisherMutation(baseOptions?: Apollo.MutationHookOptions<CreatePublisherMutation, CreatePublisherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePublisherMutation, CreatePublisherMutationVariables>(CreatePublisherDocument, options);
      }
export type CreatePublisherMutationHookResult = ReturnType<typeof useCreatePublisherMutation>;
export type CreatePublisherMutationResult = Apollo.MutationResult<CreatePublisherMutation>;
export type CreatePublisherMutationOptions = Apollo.BaseMutationOptions<CreatePublisherMutation, CreatePublisherMutationVariables>;
export const CreateSeriesDocument = gql`
    mutation createSeries($input: NewSeriesInput!) {
  newSeries(input: $input) {
    series {
      id
      key: id
      title: name
      kana
      depth
    }
  }
}
    `;
export type CreateSeriesMutationFn = Apollo.MutationFunction<CreateSeriesMutation, CreateSeriesMutationVariables>;

/**
 * __useCreateSeriesMutation__
 *
 * To run a mutation, you first call `useCreateSeriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeriesMutation, { data, loading, error }] = useCreateSeriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSeriesMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeriesMutation, CreateSeriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSeriesMutation, CreateSeriesMutationVariables>(CreateSeriesDocument, options);
      }
export type CreateSeriesMutationHookResult = ReturnType<typeof useCreateSeriesMutation>;
export type CreateSeriesMutationResult = Apollo.MutationResult<CreateSeriesMutation>;
export type CreateSeriesMutationOptions = Apollo.BaseMutationOptions<CreateSeriesMutation, CreateSeriesMutationVariables>;
export const DestroyAuthorsDocument = gql`
    mutation destroyAuthors($input: DeleteAuthorsInput!) {
  deleteAuthors(input: $input) {
    authors {
      ...AuthorDetails
    }
  }
}
    ${AuthorDetailsFragmentDoc}`;
export type DestroyAuthorsMutationFn = Apollo.MutationFunction<DestroyAuthorsMutation, DestroyAuthorsMutationVariables>;

/**
 * __useDestroyAuthorsMutation__
 *
 * To run a mutation, you first call `useDestroyAuthorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyAuthorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyAuthorsMutation, { data, loading, error }] = useDestroyAuthorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyAuthorsMutation(baseOptions?: Apollo.MutationHookOptions<DestroyAuthorsMutation, DestroyAuthorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyAuthorsMutation, DestroyAuthorsMutationVariables>(DestroyAuthorsDocument, options);
      }
export type DestroyAuthorsMutationHookResult = ReturnType<typeof useDestroyAuthorsMutation>;
export type DestroyAuthorsMutationResult = Apollo.MutationResult<DestroyAuthorsMutation>;
export type DestroyAuthorsMutationOptions = Apollo.BaseMutationOptions<DestroyAuthorsMutation, DestroyAuthorsMutationVariables>;
export const DestroyBannersDocument = gql`
    mutation destroyBanners($input: DeleteBannersInput!) {
  deleteBanners(input: $input) {
    banners {
      ...BannerDetails
    }
  }
}
    ${BannerDetailsFragmentDoc}`;
export type DestroyBannersMutationFn = Apollo.MutationFunction<DestroyBannersMutation, DestroyBannersMutationVariables>;

/**
 * __useDestroyBannersMutation__
 *
 * To run a mutation, you first call `useDestroyBannersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyBannersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyBannersMutation, { data, loading, error }] = useDestroyBannersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyBannersMutation(baseOptions?: Apollo.MutationHookOptions<DestroyBannersMutation, DestroyBannersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyBannersMutation, DestroyBannersMutationVariables>(DestroyBannersDocument, options);
      }
export type DestroyBannersMutationHookResult = ReturnType<typeof useDestroyBannersMutation>;
export type DestroyBannersMutationResult = Apollo.MutationResult<DestroyBannersMutation>;
export type DestroyBannersMutationOptions = Apollo.BaseMutationOptions<DestroyBannersMutation, DestroyBannersMutationVariables>;
export const DestroyBigBannersDocument = gql`
    mutation destroyBigBanners($input: DeleteBigBannersInput!) {
  deleteBigBanners(input: $input) {
    bigBanners {
      ...BigBannerDetails
    }
  }
}
    ${BigBannerDetailsFragmentDoc}`;
export type DestroyBigBannersMutationFn = Apollo.MutationFunction<DestroyBigBannersMutation, DestroyBigBannersMutationVariables>;

/**
 * __useDestroyBigBannersMutation__
 *
 * To run a mutation, you first call `useDestroyBigBannersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyBigBannersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyBigBannersMutation, { data, loading, error }] = useDestroyBigBannersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyBigBannersMutation(baseOptions?: Apollo.MutationHookOptions<DestroyBigBannersMutation, DestroyBigBannersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyBigBannersMutation, DestroyBigBannersMutationVariables>(DestroyBigBannersDocument, options);
      }
export type DestroyBigBannersMutationHookResult = ReturnType<typeof useDestroyBigBannersMutation>;
export type DestroyBigBannersMutationResult = Apollo.MutationResult<DestroyBigBannersMutation>;
export type DestroyBigBannersMutationOptions = Apollo.BaseMutationOptions<DestroyBigBannersMutation, DestroyBigBannersMutationVariables>;
export const DestroyBooksDocument = gql`
    mutation destroyBooks($input: DeleteBooksInput!) {
  deleteBooks(input: $input) {
    books {
      ...BookDetails
    }
  }
}
    ${BookDetailsFragmentDoc}`;
export type DestroyBooksMutationFn = Apollo.MutationFunction<DestroyBooksMutation, DestroyBooksMutationVariables>;

/**
 * __useDestroyBooksMutation__
 *
 * To run a mutation, you first call `useDestroyBooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyBooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyBooksMutation, { data, loading, error }] = useDestroyBooksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyBooksMutation(baseOptions?: Apollo.MutationHookOptions<DestroyBooksMutation, DestroyBooksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyBooksMutation, DestroyBooksMutationVariables>(DestroyBooksDocument, options);
      }
export type DestroyBooksMutationHookResult = ReturnType<typeof useDestroyBooksMutation>;
export type DestroyBooksMutationResult = Apollo.MutationResult<DestroyBooksMutation>;
export type DestroyBooksMutationOptions = Apollo.BaseMutationOptions<DestroyBooksMutation, DestroyBooksMutationVariables>;
export const DestroyCompaniesDocument = gql`
    mutation destroyCompanies($input: DeleteCompaniesInput!) {
  deleteCompanies(input: $input) {
    companies {
      ...CompanyDetails
    }
  }
}
    ${CompanyDetailsFragmentDoc}`;
export type DestroyCompaniesMutationFn = Apollo.MutationFunction<DestroyCompaniesMutation, DestroyCompaniesMutationVariables>;

/**
 * __useDestroyCompaniesMutation__
 *
 * To run a mutation, you first call `useDestroyCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyCompaniesMutation, { data, loading, error }] = useDestroyCompaniesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<DestroyCompaniesMutation, DestroyCompaniesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyCompaniesMutation, DestroyCompaniesMutationVariables>(DestroyCompaniesDocument, options);
      }
export type DestroyCompaniesMutationHookResult = ReturnType<typeof useDestroyCompaniesMutation>;
export type DestroyCompaniesMutationResult = Apollo.MutationResult<DestroyCompaniesMutation>;
export type DestroyCompaniesMutationOptions = Apollo.BaseMutationOptions<DestroyCompaniesMutation, DestroyCompaniesMutationVariables>;
export const DestroyCompanyCategoryDocument = gql`
    mutation destroyCompanyCategory($input: DeleteCompanyCategoryInput!) {
  deleteCompanyCategory(input: $input) {
    companyCategory {
      id
      key: id
      title: name
    }
  }
}
    `;
export type DestroyCompanyCategoryMutationFn = Apollo.MutationFunction<DestroyCompanyCategoryMutation, DestroyCompanyCategoryMutationVariables>;

/**
 * __useDestroyCompanyCategoryMutation__
 *
 * To run a mutation, you first call `useDestroyCompanyCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyCompanyCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyCompanyCategoryMutation, { data, loading, error }] = useDestroyCompanyCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyCompanyCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DestroyCompanyCategoryMutation, DestroyCompanyCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyCompanyCategoryMutation, DestroyCompanyCategoryMutationVariables>(DestroyCompanyCategoryDocument, options);
      }
export type DestroyCompanyCategoryMutationHookResult = ReturnType<typeof useDestroyCompanyCategoryMutation>;
export type DestroyCompanyCategoryMutationResult = Apollo.MutationResult<DestroyCompanyCategoryMutation>;
export type DestroyCompanyCategoryMutationOptions = Apollo.BaseMutationOptions<DestroyCompanyCategoryMutation, DestroyCompanyCategoryMutationVariables>;
export const DestroyGenreDocument = gql`
    mutation destroyGenre($input: DeleteGenreInput!) {
  deleteGenre(input: $input) {
    genre {
      id
      key: id
      title: name
    }
  }
}
    `;
export type DestroyGenreMutationFn = Apollo.MutationFunction<DestroyGenreMutation, DestroyGenreMutationVariables>;

/**
 * __useDestroyGenreMutation__
 *
 * To run a mutation, you first call `useDestroyGenreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyGenreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyGenreMutation, { data, loading, error }] = useDestroyGenreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyGenreMutation(baseOptions?: Apollo.MutationHookOptions<DestroyGenreMutation, DestroyGenreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyGenreMutation, DestroyGenreMutationVariables>(DestroyGenreDocument, options);
      }
export type DestroyGenreMutationHookResult = ReturnType<typeof useDestroyGenreMutation>;
export type DestroyGenreMutationResult = Apollo.MutationResult<DestroyGenreMutation>;
export type DestroyGenreMutationOptions = Apollo.BaseMutationOptions<DestroyGenreMutation, DestroyGenreMutationVariables>;
export const DestroyHondanaEbookDocument = gql`
    mutation destroyHondanaEbook($input: DeleteHondanaEbookInput!) {
  deleteHondanaEbook(input: $input) {
    hondanaEbook {
      id
    }
  }
}
    `;
export type DestroyHondanaEbookMutationFn = Apollo.MutationFunction<DestroyHondanaEbookMutation, DestroyHondanaEbookMutationVariables>;

/**
 * __useDestroyHondanaEbookMutation__
 *
 * To run a mutation, you first call `useDestroyHondanaEbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyHondanaEbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyHondanaEbookMutation, { data, loading, error }] = useDestroyHondanaEbookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyHondanaEbookMutation(baseOptions?: Apollo.MutationHookOptions<DestroyHondanaEbookMutation, DestroyHondanaEbookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyHondanaEbookMutation, DestroyHondanaEbookMutationVariables>(DestroyHondanaEbookDocument, options);
      }
export type DestroyHondanaEbookMutationHookResult = ReturnType<typeof useDestroyHondanaEbookMutation>;
export type DestroyHondanaEbookMutationResult = Apollo.MutationResult<DestroyHondanaEbookMutation>;
export type DestroyHondanaEbookMutationOptions = Apollo.BaseMutationOptions<DestroyHondanaEbookMutation, DestroyHondanaEbookMutationVariables>;
export const DestroyInfosDocument = gql`
    mutation destroyInfos($input: DeleteInfosInput!) {
  deleteInfos(input: $input) {
    infos {
      ...InfoDetails
    }
  }
}
    ${InfoDetailsFragmentDoc}`;
export type DestroyInfosMutationFn = Apollo.MutationFunction<DestroyInfosMutation, DestroyInfosMutationVariables>;

/**
 * __useDestroyInfosMutation__
 *
 * To run a mutation, you first call `useDestroyInfosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyInfosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyInfosMutation, { data, loading, error }] = useDestroyInfosMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyInfosMutation(baseOptions?: Apollo.MutationHookOptions<DestroyInfosMutation, DestroyInfosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyInfosMutation, DestroyInfosMutationVariables>(DestroyInfosDocument, options);
      }
export type DestroyInfosMutationHookResult = ReturnType<typeof useDestroyInfosMutation>;
export type DestroyInfosMutationResult = Apollo.MutationResult<DestroyInfosMutation>;
export type DestroyInfosMutationOptions = Apollo.BaseMutationOptions<DestroyInfosMutation, DestroyInfosMutationVariables>;
export const DestroyNewsCategoryDocument = gql`
    mutation destroyNewsCategory($input: DeleteNewsCategoryInput!) {
  deleteNewsCategory(input: $input) {
    newsCategory {
      id
      key: id
      title: name
    }
  }
}
    `;
export type DestroyNewsCategoryMutationFn = Apollo.MutationFunction<DestroyNewsCategoryMutation, DestroyNewsCategoryMutationVariables>;

/**
 * __useDestroyNewsCategoryMutation__
 *
 * To run a mutation, you first call `useDestroyNewsCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyNewsCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyNewsCategoryMutation, { data, loading, error }] = useDestroyNewsCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyNewsCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DestroyNewsCategoryMutation, DestroyNewsCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyNewsCategoryMutation, DestroyNewsCategoryMutationVariables>(DestroyNewsCategoryDocument, options);
      }
export type DestroyNewsCategoryMutationHookResult = ReturnType<typeof useDestroyNewsCategoryMutation>;
export type DestroyNewsCategoryMutationResult = Apollo.MutationResult<DestroyNewsCategoryMutation>;
export type DestroyNewsCategoryMutationOptions = Apollo.BaseMutationOptions<DestroyNewsCategoryMutation, DestroyNewsCategoryMutationVariables>;
export const DestroyNewsPostsDocument = gql`
    mutation destroyNewsPosts($input: DeleteNewsPostsInput!) {
  deleteNewsPosts(input: $input) {
    newsPosts {
      ...NewsPostDetails
    }
  }
}
    ${NewsPostDetailsFragmentDoc}`;
export type DestroyNewsPostsMutationFn = Apollo.MutationFunction<DestroyNewsPostsMutation, DestroyNewsPostsMutationVariables>;

/**
 * __useDestroyNewsPostsMutation__
 *
 * To run a mutation, you first call `useDestroyNewsPostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyNewsPostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyNewsPostsMutation, { data, loading, error }] = useDestroyNewsPostsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyNewsPostsMutation(baseOptions?: Apollo.MutationHookOptions<DestroyNewsPostsMutation, DestroyNewsPostsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyNewsPostsMutation, DestroyNewsPostsMutationVariables>(DestroyNewsPostsDocument, options);
      }
export type DestroyNewsPostsMutationHookResult = ReturnType<typeof useDestroyNewsPostsMutation>;
export type DestroyNewsPostsMutationResult = Apollo.MutationResult<DestroyNewsPostsMutation>;
export type DestroyNewsPostsMutationOptions = Apollo.BaseMutationOptions<DestroyNewsPostsMutation, DestroyNewsPostsMutationVariables>;
export const DestroySeriesDocument = gql`
    mutation destroySeries($input: DeleteSeriesInput!) {
  deleteSeries(input: $input) {
    series {
      id
      key: id
      title: name
      kana
    }
  }
}
    `;
export type DestroySeriesMutationFn = Apollo.MutationFunction<DestroySeriesMutation, DestroySeriesMutationVariables>;

/**
 * __useDestroySeriesMutation__
 *
 * To run a mutation, you first call `useDestroySeriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroySeriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroySeriesMutation, { data, loading, error }] = useDestroySeriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroySeriesMutation(baseOptions?: Apollo.MutationHookOptions<DestroySeriesMutation, DestroySeriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroySeriesMutation, DestroySeriesMutationVariables>(DestroySeriesDocument, options);
      }
export type DestroySeriesMutationHookResult = ReturnType<typeof useDestroySeriesMutation>;
export type DestroySeriesMutationResult = Apollo.MutationResult<DestroySeriesMutation>;
export type DestroySeriesMutationOptions = Apollo.BaseMutationOptions<DestroySeriesMutation, DestroySeriesMutationVariables>;
export const ExportBooksDocument = gql`
    mutation exportBooks($input: ExportBooksInput!) {
  exportBooks(input: $input) {
    publisher {
      id
    }
  }
}
    `;
export type ExportBooksMutationFn = Apollo.MutationFunction<ExportBooksMutation, ExportBooksMutationVariables>;

/**
 * __useExportBooksMutation__
 *
 * To run a mutation, you first call `useExportBooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportBooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportBooksMutation, { data, loading, error }] = useExportBooksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportBooksMutation(baseOptions?: Apollo.MutationHookOptions<ExportBooksMutation, ExportBooksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportBooksMutation, ExportBooksMutationVariables>(ExportBooksDocument, options);
      }
export type ExportBooksMutationHookResult = ReturnType<typeof useExportBooksMutation>;
export type ExportBooksMutationResult = Apollo.MutationResult<ExportBooksMutation>;
export type ExportBooksMutationOptions = Apollo.BaseMutationOptions<ExportBooksMutation, ExportBooksMutationVariables>;
export const ImportBooksDocument = gql`
    mutation importBooks($input: ImportBooksInput!) {
  importBooks(input: $input) {
    publisher {
      id
    }
  }
}
    `;
export type ImportBooksMutationFn = Apollo.MutationFunction<ImportBooksMutation, ImportBooksMutationVariables>;

/**
 * __useImportBooksMutation__
 *
 * To run a mutation, you first call `useImportBooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportBooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importBooksMutation, { data, loading, error }] = useImportBooksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportBooksMutation(baseOptions?: Apollo.MutationHookOptions<ImportBooksMutation, ImportBooksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportBooksMutation, ImportBooksMutationVariables>(ImportBooksDocument, options);
      }
export type ImportBooksMutationHookResult = ReturnType<typeof useImportBooksMutation>;
export type ImportBooksMutationResult = Apollo.MutationResult<ImportBooksMutation>;
export type ImportBooksMutationOptions = Apollo.BaseMutationOptions<ImportBooksMutation, ImportBooksMutationVariables>;
export const ImportPublisherDocument = gql`
    mutation importPublisher($input: ImportPublisherInput!) {
  importPublisher(input: $input) {
    publisher {
      id
    }
  }
}
    `;
export type ImportPublisherMutationFn = Apollo.MutationFunction<ImportPublisherMutation, ImportPublisherMutationVariables>;

/**
 * __useImportPublisherMutation__
 *
 * To run a mutation, you first call `useImportPublisherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPublisherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPublisherMutation, { data, loading, error }] = useImportPublisherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportPublisherMutation(baseOptions?: Apollo.MutationHookOptions<ImportPublisherMutation, ImportPublisherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportPublisherMutation, ImportPublisherMutationVariables>(ImportPublisherDocument, options);
      }
export type ImportPublisherMutationHookResult = ReturnType<typeof useImportPublisherMutation>;
export type ImportPublisherMutationResult = Apollo.MutationResult<ImportPublisherMutation>;
export type ImportPublisherMutationOptions = Apollo.BaseMutationOptions<ImportPublisherMutation, ImportPublisherMutationVariables>;
export const MoveBannerDocument = gql`
    mutation moveBanner($input: MoveBannerInput!) {
  moveBanner(input: $input) {
    banner {
      id
      displayOrder
    }
  }
}
    `;
export type MoveBannerMutationFn = Apollo.MutationFunction<MoveBannerMutation, MoveBannerMutationVariables>;

/**
 * __useMoveBannerMutation__
 *
 * To run a mutation, you first call `useMoveBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveBannerMutation, { data, loading, error }] = useMoveBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveBannerMutation(baseOptions?: Apollo.MutationHookOptions<MoveBannerMutation, MoveBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveBannerMutation, MoveBannerMutationVariables>(MoveBannerDocument, options);
      }
export type MoveBannerMutationHookResult = ReturnType<typeof useMoveBannerMutation>;
export type MoveBannerMutationResult = Apollo.MutationResult<MoveBannerMutation>;
export type MoveBannerMutationOptions = Apollo.BaseMutationOptions<MoveBannerMutation, MoveBannerMutationVariables>;
export const MoveBigBannerDocument = gql`
    mutation moveBigBanner($input: MoveBigBannerInput!) {
  moveBigBanner(input: $input) {
    bigBanner {
      id
      displayOrder
    }
  }
}
    `;
export type MoveBigBannerMutationFn = Apollo.MutationFunction<MoveBigBannerMutation, MoveBigBannerMutationVariables>;

/**
 * __useMoveBigBannerMutation__
 *
 * To run a mutation, you first call `useMoveBigBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveBigBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveBigBannerMutation, { data, loading, error }] = useMoveBigBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveBigBannerMutation(baseOptions?: Apollo.MutationHookOptions<MoveBigBannerMutation, MoveBigBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveBigBannerMutation, MoveBigBannerMutationVariables>(MoveBigBannerDocument, options);
      }
export type MoveBigBannerMutationHookResult = ReturnType<typeof useMoveBigBannerMutation>;
export type MoveBigBannerMutationResult = Apollo.MutationResult<MoveBigBannerMutation>;
export type MoveBigBannerMutationOptions = Apollo.BaseMutationOptions<MoveBigBannerMutation, MoveBigBannerMutationVariables>;
export const MoveCompanyCategoryDocument = gql`
    mutation moveCompanyCategory($input: MoveCompanyCategoryInput!) {
  moveCompanyCategory(input: $input) {
    companyCategory {
      id
      key: id
      title: name
      depth
    }
  }
}
    `;
export type MoveCompanyCategoryMutationFn = Apollo.MutationFunction<MoveCompanyCategoryMutation, MoveCompanyCategoryMutationVariables>;

/**
 * __useMoveCompanyCategoryMutation__
 *
 * To run a mutation, you first call `useMoveCompanyCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveCompanyCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveCompanyCategoryMutation, { data, loading, error }] = useMoveCompanyCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveCompanyCategoryMutation(baseOptions?: Apollo.MutationHookOptions<MoveCompanyCategoryMutation, MoveCompanyCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveCompanyCategoryMutation, MoveCompanyCategoryMutationVariables>(MoveCompanyCategoryDocument, options);
      }
export type MoveCompanyCategoryMutationHookResult = ReturnType<typeof useMoveCompanyCategoryMutation>;
export type MoveCompanyCategoryMutationResult = Apollo.MutationResult<MoveCompanyCategoryMutation>;
export type MoveCompanyCategoryMutationOptions = Apollo.BaseMutationOptions<MoveCompanyCategoryMutation, MoveCompanyCategoryMutationVariables>;
export const MoveGenreDocument = gql`
    mutation moveGenre($input: MoveGenreInput!) {
  moveGenre(input: $input) {
    genre {
      id
      key: id
      title: name
      depth
    }
  }
}
    `;
export type MoveGenreMutationFn = Apollo.MutationFunction<MoveGenreMutation, MoveGenreMutationVariables>;

/**
 * __useMoveGenreMutation__
 *
 * To run a mutation, you first call `useMoveGenreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveGenreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveGenreMutation, { data, loading, error }] = useMoveGenreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveGenreMutation(baseOptions?: Apollo.MutationHookOptions<MoveGenreMutation, MoveGenreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveGenreMutation, MoveGenreMutationVariables>(MoveGenreDocument, options);
      }
export type MoveGenreMutationHookResult = ReturnType<typeof useMoveGenreMutation>;
export type MoveGenreMutationResult = Apollo.MutationResult<MoveGenreMutation>;
export type MoveGenreMutationOptions = Apollo.BaseMutationOptions<MoveGenreMutation, MoveGenreMutationVariables>;
export const MoveNewlyPublishedBookDocument = gql`
    mutation moveNewlyPublishedBook($input: MoveNewlyPublishedBookInput!) {
  moveNewlyPublishedBook(input: $input) {
    book {
      id
      newOrder
    }
  }
}
    `;
export type MoveNewlyPublishedBookMutationFn = Apollo.MutationFunction<MoveNewlyPublishedBookMutation, MoveNewlyPublishedBookMutationVariables>;

/**
 * __useMoveNewlyPublishedBookMutation__
 *
 * To run a mutation, you first call `useMoveNewlyPublishedBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveNewlyPublishedBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveNewlyPublishedBookMutation, { data, loading, error }] = useMoveNewlyPublishedBookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveNewlyPublishedBookMutation(baseOptions?: Apollo.MutationHookOptions<MoveNewlyPublishedBookMutation, MoveNewlyPublishedBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveNewlyPublishedBookMutation, MoveNewlyPublishedBookMutationVariables>(MoveNewlyPublishedBookDocument, options);
      }
export type MoveNewlyPublishedBookMutationHookResult = ReturnType<typeof useMoveNewlyPublishedBookMutation>;
export type MoveNewlyPublishedBookMutationResult = Apollo.MutationResult<MoveNewlyPublishedBookMutation>;
export type MoveNewlyPublishedBookMutationOptions = Apollo.BaseMutationOptions<MoveNewlyPublishedBookMutation, MoveNewlyPublishedBookMutationVariables>;
export const MoveNewsCategoryDocument = gql`
    mutation moveNewsCategory($input: MoveNewsCategoryInput!) {
  moveNewsCategory(input: $input) {
    newsCategory {
      id
      key: id
      title: name
      depth
    }
  }
}
    `;
export type MoveNewsCategoryMutationFn = Apollo.MutationFunction<MoveNewsCategoryMutation, MoveNewsCategoryMutationVariables>;

/**
 * __useMoveNewsCategoryMutation__
 *
 * To run a mutation, you first call `useMoveNewsCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveNewsCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveNewsCategoryMutation, { data, loading, error }] = useMoveNewsCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveNewsCategoryMutation(baseOptions?: Apollo.MutationHookOptions<MoveNewsCategoryMutation, MoveNewsCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveNewsCategoryMutation, MoveNewsCategoryMutationVariables>(MoveNewsCategoryDocument, options);
      }
export type MoveNewsCategoryMutationHookResult = ReturnType<typeof useMoveNewsCategoryMutation>;
export type MoveNewsCategoryMutationResult = Apollo.MutationResult<MoveNewsCategoryMutation>;
export type MoveNewsCategoryMutationOptions = Apollo.BaseMutationOptions<MoveNewsCategoryMutation, MoveNewsCategoryMutationVariables>;
export const MovePublisherEbookstoreDocument = gql`
    mutation movePublisherEbookstore($input: MovePublisherEbookstoreInput!) {
  movePublisherEbookstore(input: $input) {
    ebookstore {
      id
      displayOrder
    }
  }
}
    `;
export type MovePublisherEbookstoreMutationFn = Apollo.MutationFunction<MovePublisherEbookstoreMutation, MovePublisherEbookstoreMutationVariables>;

/**
 * __useMovePublisherEbookstoreMutation__
 *
 * To run a mutation, you first call `useMovePublisherEbookstoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovePublisherEbookstoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movePublisherEbookstoreMutation, { data, loading, error }] = useMovePublisherEbookstoreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMovePublisherEbookstoreMutation(baseOptions?: Apollo.MutationHookOptions<MovePublisherEbookstoreMutation, MovePublisherEbookstoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MovePublisherEbookstoreMutation, MovePublisherEbookstoreMutationVariables>(MovePublisherEbookstoreDocument, options);
      }
export type MovePublisherEbookstoreMutationHookResult = ReturnType<typeof useMovePublisherEbookstoreMutation>;
export type MovePublisherEbookstoreMutationResult = Apollo.MutationResult<MovePublisherEbookstoreMutation>;
export type MovePublisherEbookstoreMutationOptions = Apollo.BaseMutationOptions<MovePublisherEbookstoreMutation, MovePublisherEbookstoreMutationVariables>;
export const MovePublisherNetshopDocument = gql`
    mutation movePublisherNetshop($input: MovePublisherNetshopInput!) {
  movePublisherNetshop(input: $input) {
    netshop {
      id
      displayOrder
    }
  }
}
    `;
export type MovePublisherNetshopMutationFn = Apollo.MutationFunction<MovePublisherNetshopMutation, MovePublisherNetshopMutationVariables>;

/**
 * __useMovePublisherNetshopMutation__
 *
 * To run a mutation, you first call `useMovePublisherNetshopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovePublisherNetshopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movePublisherNetshopMutation, { data, loading, error }] = useMovePublisherNetshopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMovePublisherNetshopMutation(baseOptions?: Apollo.MutationHookOptions<MovePublisherNetshopMutation, MovePublisherNetshopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MovePublisherNetshopMutation, MovePublisherNetshopMutationVariables>(MovePublisherNetshopDocument, options);
      }
export type MovePublisherNetshopMutationHookResult = ReturnType<typeof useMovePublisherNetshopMutation>;
export type MovePublisherNetshopMutationResult = Apollo.MutationResult<MovePublisherNetshopMutation>;
export type MovePublisherNetshopMutationOptions = Apollo.BaseMutationOptions<MovePublisherNetshopMutation, MovePublisherNetshopMutationVariables>;
export const MoveSeriesDocument = gql`
    mutation moveSeries($input: MoveSeriesInput!) {
  moveSeries(input: $input) {
    series {
      id
      key: id
      title: name
      kana
      depth
    }
  }
}
    `;
export type MoveSeriesMutationFn = Apollo.MutationFunction<MoveSeriesMutation, MoveSeriesMutationVariables>;

/**
 * __useMoveSeriesMutation__
 *
 * To run a mutation, you first call `useMoveSeriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveSeriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveSeriesMutation, { data, loading, error }] = useMoveSeriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveSeriesMutation(baseOptions?: Apollo.MutationHookOptions<MoveSeriesMutation, MoveSeriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveSeriesMutation, MoveSeriesMutationVariables>(MoveSeriesDocument, options);
      }
export type MoveSeriesMutationHookResult = ReturnType<typeof useMoveSeriesMutation>;
export type MoveSeriesMutationResult = Apollo.MutationResult<MoveSeriesMutation>;
export type MoveSeriesMutationOptions = Apollo.BaseMutationOptions<MoveSeriesMutation, MoveSeriesMutationVariables>;
export const RevertAdminJproHistoryDocument = gql`
    mutation revertAdminJproHistory($input: RevertAdminJproHistoryInput!) {
  revertAdminJproHistory(input: $input) {
    jproHistories {
      id
      state
    }
  }
}
    `;
export type RevertAdminJproHistoryMutationFn = Apollo.MutationFunction<RevertAdminJproHistoryMutation, RevertAdminJproHistoryMutationVariables>;

/**
 * __useRevertAdminJproHistoryMutation__
 *
 * To run a mutation, you first call `useRevertAdminJproHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertAdminJproHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertAdminJproHistoryMutation, { data, loading, error }] = useRevertAdminJproHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevertAdminJproHistoryMutation(baseOptions?: Apollo.MutationHookOptions<RevertAdminJproHistoryMutation, RevertAdminJproHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevertAdminJproHistoryMutation, RevertAdminJproHistoryMutationVariables>(RevertAdminJproHistoryDocument, options);
      }
export type RevertAdminJproHistoryMutationHookResult = ReturnType<typeof useRevertAdminJproHistoryMutation>;
export type RevertAdminJproHistoryMutationResult = Apollo.MutationResult<RevertAdminJproHistoryMutation>;
export type RevertAdminJproHistoryMutationOptions = Apollo.BaseMutationOptions<RevertAdminJproHistoryMutation, RevertAdminJproHistoryMutationVariables>;
export const SendAllBooksToShopifyDocument = gql`
    mutation sendAllBooksToShopify($input: SendAllBooksToShopifyInput!) {
  sendAllBooksToShopify(input: $input) {
    publisher {
      id
    }
  }
}
    `;
export type SendAllBooksToShopifyMutationFn = Apollo.MutationFunction<SendAllBooksToShopifyMutation, SendAllBooksToShopifyMutationVariables>;

/**
 * __useSendAllBooksToShopifyMutation__
 *
 * To run a mutation, you first call `useSendAllBooksToShopifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAllBooksToShopifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAllBooksToShopifyMutation, { data, loading, error }] = useSendAllBooksToShopifyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendAllBooksToShopifyMutation(baseOptions?: Apollo.MutationHookOptions<SendAllBooksToShopifyMutation, SendAllBooksToShopifyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAllBooksToShopifyMutation, SendAllBooksToShopifyMutationVariables>(SendAllBooksToShopifyDocument, options);
      }
export type SendAllBooksToShopifyMutationHookResult = ReturnType<typeof useSendAllBooksToShopifyMutation>;
export type SendAllBooksToShopifyMutationResult = Apollo.MutationResult<SendAllBooksToShopifyMutation>;
export type SendAllBooksToShopifyMutationOptions = Apollo.BaseMutationOptions<SendAllBooksToShopifyMutation, SendAllBooksToShopifyMutationVariables>;
export const SortNewlyPublishedBooksDocument = gql`
    mutation sortNewlyPublishedBooks($input: SortNewlyPublishedBooksInput!) {
  sortNewlyPublishedBooks(input: $input) {
    success
  }
}
    `;
export type SortNewlyPublishedBooksMutationFn = Apollo.MutationFunction<SortNewlyPublishedBooksMutation, SortNewlyPublishedBooksMutationVariables>;

/**
 * __useSortNewlyPublishedBooksMutation__
 *
 * To run a mutation, you first call `useSortNewlyPublishedBooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortNewlyPublishedBooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortNewlyPublishedBooksMutation, { data, loading, error }] = useSortNewlyPublishedBooksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSortNewlyPublishedBooksMutation(baseOptions?: Apollo.MutationHookOptions<SortNewlyPublishedBooksMutation, SortNewlyPublishedBooksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SortNewlyPublishedBooksMutation, SortNewlyPublishedBooksMutationVariables>(SortNewlyPublishedBooksDocument, options);
      }
export type SortNewlyPublishedBooksMutationHookResult = ReturnType<typeof useSortNewlyPublishedBooksMutation>;
export type SortNewlyPublishedBooksMutationResult = Apollo.MutationResult<SortNewlyPublishedBooksMutation>;
export type SortNewlyPublishedBooksMutationOptions = Apollo.BaseMutationOptions<SortNewlyPublishedBooksMutation, SortNewlyPublishedBooksMutationVariables>;
export const UpdateAdminPublisherDocument = gql`
    mutation updateAdminPublisher($input: EditAdminPublisherInput!) {
  editAdminPublisher(input: $input) {
    publisher {
      ...PublisherDetails
    }
  }
}
    ${PublisherDetailsFragmentDoc}`;
export type UpdateAdminPublisherMutationFn = Apollo.MutationFunction<UpdateAdminPublisherMutation, UpdateAdminPublisherMutationVariables>;

/**
 * __useUpdateAdminPublisherMutation__
 *
 * To run a mutation, you first call `useUpdateAdminPublisherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminPublisherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminPublisherMutation, { data, loading, error }] = useUpdateAdminPublisherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdminPublisherMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminPublisherMutation, UpdateAdminPublisherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminPublisherMutation, UpdateAdminPublisherMutationVariables>(UpdateAdminPublisherDocument, options);
      }
export type UpdateAdminPublisherMutationHookResult = ReturnType<typeof useUpdateAdminPublisherMutation>;
export type UpdateAdminPublisherMutationResult = Apollo.MutationResult<UpdateAdminPublisherMutation>;
export type UpdateAdminPublisherMutationOptions = Apollo.BaseMutationOptions<UpdateAdminPublisherMutation, UpdateAdminPublisherMutationVariables>;
export const UpdateAuthorDocument = gql`
    mutation updateAuthor($input: EditAuthorInput!) {
  editAuthor(input: $input) {
    author {
      ...AuthorDetails
    }
  }
}
    ${AuthorDetailsFragmentDoc}`;
export type UpdateAuthorMutationFn = Apollo.MutationFunction<UpdateAuthorMutation, UpdateAuthorMutationVariables>;

/**
 * __useUpdateAuthorMutation__
 *
 * To run a mutation, you first call `useUpdateAuthorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuthorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuthorMutation, { data, loading, error }] = useUpdateAuthorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAuthorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAuthorMutation, UpdateAuthorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAuthorMutation, UpdateAuthorMutationVariables>(UpdateAuthorDocument, options);
      }
export type UpdateAuthorMutationHookResult = ReturnType<typeof useUpdateAuthorMutation>;
export type UpdateAuthorMutationResult = Apollo.MutationResult<UpdateAuthorMutation>;
export type UpdateAuthorMutationOptions = Apollo.BaseMutationOptions<UpdateAuthorMutation, UpdateAuthorMutationVariables>;
export const UpdateBannerDocument = gql`
    mutation updateBanner($input: EditBannerInput!) {
  editBanner(input: $input) {
    banner {
      ...BannerDetails
    }
  }
}
    ${BannerDetailsFragmentDoc}`;
export type UpdateBannerMutationFn = Apollo.MutationFunction<UpdateBannerMutation, UpdateBannerMutationVariables>;

/**
 * __useUpdateBannerMutation__
 *
 * To run a mutation, you first call `useUpdateBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBannerMutation, { data, loading, error }] = useUpdateBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBannerMutation, UpdateBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBannerMutation, UpdateBannerMutationVariables>(UpdateBannerDocument, options);
      }
export type UpdateBannerMutationHookResult = ReturnType<typeof useUpdateBannerMutation>;
export type UpdateBannerMutationResult = Apollo.MutationResult<UpdateBannerMutation>;
export type UpdateBannerMutationOptions = Apollo.BaseMutationOptions<UpdateBannerMutation, UpdateBannerMutationVariables>;
export const UpdateBannersDocument = gql`
    mutation updateBanners($input: EditBannersInput!) {
  editBanners(input: $input) {
    banners {
      id
      publicStatus
    }
  }
}
    `;
export type UpdateBannersMutationFn = Apollo.MutationFunction<UpdateBannersMutation, UpdateBannersMutationVariables>;

/**
 * __useUpdateBannersMutation__
 *
 * To run a mutation, you first call `useUpdateBannersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBannersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBannersMutation, { data, loading, error }] = useUpdateBannersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBannersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBannersMutation, UpdateBannersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBannersMutation, UpdateBannersMutationVariables>(UpdateBannersDocument, options);
      }
export type UpdateBannersMutationHookResult = ReturnType<typeof useUpdateBannersMutation>;
export type UpdateBannersMutationResult = Apollo.MutationResult<UpdateBannersMutation>;
export type UpdateBannersMutationOptions = Apollo.BaseMutationOptions<UpdateBannersMutation, UpdateBannersMutationVariables>;
export const UpdateBigBannerDocument = gql`
    mutation updateBigBanner($input: EditBigBannerInput!) {
  editBigBanner(input: $input) {
    bigBanner {
      ...BigBannerDetails
    }
  }
}
    ${BigBannerDetailsFragmentDoc}`;
export type UpdateBigBannerMutationFn = Apollo.MutationFunction<UpdateBigBannerMutation, UpdateBigBannerMutationVariables>;

/**
 * __useUpdateBigBannerMutation__
 *
 * To run a mutation, you first call `useUpdateBigBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBigBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBigBannerMutation, { data, loading, error }] = useUpdateBigBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBigBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBigBannerMutation, UpdateBigBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBigBannerMutation, UpdateBigBannerMutationVariables>(UpdateBigBannerDocument, options);
      }
export type UpdateBigBannerMutationHookResult = ReturnType<typeof useUpdateBigBannerMutation>;
export type UpdateBigBannerMutationResult = Apollo.MutationResult<UpdateBigBannerMutation>;
export type UpdateBigBannerMutationOptions = Apollo.BaseMutationOptions<UpdateBigBannerMutation, UpdateBigBannerMutationVariables>;
export const UpdateBigBannersDocument = gql`
    mutation updateBigBanners($input: EditBigBannersInput!) {
  editBigBanners(input: $input) {
    bigBanners {
      id
      publicStatus
    }
  }
}
    `;
export type UpdateBigBannersMutationFn = Apollo.MutationFunction<UpdateBigBannersMutation, UpdateBigBannersMutationVariables>;

/**
 * __useUpdateBigBannersMutation__
 *
 * To run a mutation, you first call `useUpdateBigBannersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBigBannersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBigBannersMutation, { data, loading, error }] = useUpdateBigBannersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBigBannersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBigBannersMutation, UpdateBigBannersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBigBannersMutation, UpdateBigBannersMutationVariables>(UpdateBigBannersDocument, options);
      }
export type UpdateBigBannersMutationHookResult = ReturnType<typeof useUpdateBigBannersMutation>;
export type UpdateBigBannersMutationResult = Apollo.MutationResult<UpdateBigBannersMutation>;
export type UpdateBigBannersMutationOptions = Apollo.BaseMutationOptions<UpdateBigBannersMutation, UpdateBigBannersMutationVariables>;
export const UpdateBookDocument = gql`
    mutation updateBook($input: EditBookInput!) {
  editBook(input: $input) {
    book {
      ...BookDetails
    }
  }
}
    ${BookDetailsFragmentDoc}`;
export type UpdateBookMutationFn = Apollo.MutationFunction<UpdateBookMutation, UpdateBookMutationVariables>;

/**
 * __useUpdateBookMutation__
 *
 * To run a mutation, you first call `useUpdateBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookMutation, { data, loading, error }] = useUpdateBookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookMutation, UpdateBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookMutation, UpdateBookMutationVariables>(UpdateBookDocument, options);
      }
export type UpdateBookMutationHookResult = ReturnType<typeof useUpdateBookMutation>;
export type UpdateBookMutationResult = Apollo.MutationResult<UpdateBookMutation>;
export type UpdateBookMutationOptions = Apollo.BaseMutationOptions<UpdateBookMutation, UpdateBookMutationVariables>;
export const UpdateBooksDocument = gql`
    mutation updateBooks($input: EditBooksInput!) {
  editBooks(input: $input) {
    books {
      ...BookDetails
    }
  }
}
    ${BookDetailsFragmentDoc}`;
export type UpdateBooksMutationFn = Apollo.MutationFunction<UpdateBooksMutation, UpdateBooksMutationVariables>;

/**
 * __useUpdateBooksMutation__
 *
 * To run a mutation, you first call `useUpdateBooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBooksMutation, { data, loading, error }] = useUpdateBooksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBooksMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBooksMutation, UpdateBooksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBooksMutation, UpdateBooksMutationVariables>(UpdateBooksDocument, options);
      }
export type UpdateBooksMutationHookResult = ReturnType<typeof useUpdateBooksMutation>;
export type UpdateBooksMutationResult = Apollo.MutationResult<UpdateBooksMutation>;
export type UpdateBooksMutationOptions = Apollo.BaseMutationOptions<UpdateBooksMutation, UpdateBooksMutationVariables>;
export const UpdateCompaniesDocument = gql`
    mutation updateCompanies($input: EditCompaniesInput!) {
  editCompanies(input: $input) {
    companies {
      id
      publicDate
      publicStatus
      category {
        id
        name
      }
    }
  }
}
    `;
export type UpdateCompaniesMutationFn = Apollo.MutationFunction<UpdateCompaniesMutation, UpdateCompaniesMutationVariables>;

/**
 * __useUpdateCompaniesMutation__
 *
 * To run a mutation, you first call `useUpdateCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompaniesMutation, { data, loading, error }] = useUpdateCompaniesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompaniesMutation, UpdateCompaniesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompaniesMutation, UpdateCompaniesMutationVariables>(UpdateCompaniesDocument, options);
      }
export type UpdateCompaniesMutationHookResult = ReturnType<typeof useUpdateCompaniesMutation>;
export type UpdateCompaniesMutationResult = Apollo.MutationResult<UpdateCompaniesMutation>;
export type UpdateCompaniesMutationOptions = Apollo.BaseMutationOptions<UpdateCompaniesMutation, UpdateCompaniesMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($input: EditCompanyInput!) {
  editCompany(input: $input) {
    company {
      ...CompanyDetails
    }
  }
}
    ${CompanyDetailsFragmentDoc}`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const UpdateCompanyCategoryDocument = gql`
    mutation updateCompanyCategory($input: EditCompanyCategoryInput!) {
  editCompanyCategory(input: $input) {
    companyCategory {
      id
      key: id
      title: name
      depth
    }
  }
}
    `;
export type UpdateCompanyCategoryMutationFn = Apollo.MutationFunction<UpdateCompanyCategoryMutation, UpdateCompanyCategoryMutationVariables>;

/**
 * __useUpdateCompanyCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyCategoryMutation, { data, loading, error }] = useUpdateCompanyCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyCategoryMutation, UpdateCompanyCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyCategoryMutation, UpdateCompanyCategoryMutationVariables>(UpdateCompanyCategoryDocument, options);
      }
export type UpdateCompanyCategoryMutationHookResult = ReturnType<typeof useUpdateCompanyCategoryMutation>;
export type UpdateCompanyCategoryMutationResult = Apollo.MutationResult<UpdateCompanyCategoryMutation>;
export type UpdateCompanyCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyCategoryMutation, UpdateCompanyCategoryMutationVariables>;
export const UpdateGenreDocument = gql`
    mutation updateGenre($input: EditGenreInput!) {
  editGenre(input: $input) {
    genre {
      id
      key: id
      title: name
      depth
    }
  }
}
    `;
export type UpdateGenreMutationFn = Apollo.MutationFunction<UpdateGenreMutation, UpdateGenreMutationVariables>;

/**
 * __useUpdateGenreMutation__
 *
 * To run a mutation, you first call `useUpdateGenreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGenreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGenreMutation, { data, loading, error }] = useUpdateGenreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGenreMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGenreMutation, UpdateGenreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGenreMutation, UpdateGenreMutationVariables>(UpdateGenreDocument, options);
      }
export type UpdateGenreMutationHookResult = ReturnType<typeof useUpdateGenreMutation>;
export type UpdateGenreMutationResult = Apollo.MutationResult<UpdateGenreMutation>;
export type UpdateGenreMutationOptions = Apollo.BaseMutationOptions<UpdateGenreMutation, UpdateGenreMutationVariables>;
export const UpdateHondanaEbookDocument = gql`
    mutation updateHondanaEbook($input: EditHondanaEbookInput!) {
  editHondanaEbook(input: $input) {
    fileUploadUrl
    imageUploadUrl
    hondanaEbook {
      id
    }
  }
}
    `;
export type UpdateHondanaEbookMutationFn = Apollo.MutationFunction<UpdateHondanaEbookMutation, UpdateHondanaEbookMutationVariables>;

/**
 * __useUpdateHondanaEbookMutation__
 *
 * To run a mutation, you first call `useUpdateHondanaEbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHondanaEbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHondanaEbookMutation, { data, loading, error }] = useUpdateHondanaEbookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHondanaEbookMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHondanaEbookMutation, UpdateHondanaEbookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHondanaEbookMutation, UpdateHondanaEbookMutationVariables>(UpdateHondanaEbookDocument, options);
      }
export type UpdateHondanaEbookMutationHookResult = ReturnType<typeof useUpdateHondanaEbookMutation>;
export type UpdateHondanaEbookMutationResult = Apollo.MutationResult<UpdateHondanaEbookMutation>;
export type UpdateHondanaEbookMutationOptions = Apollo.BaseMutationOptions<UpdateHondanaEbookMutation, UpdateHondanaEbookMutationVariables>;
export const UpdateInfoDocument = gql`
    mutation updateInfo($input: EditInfoInput!) {
  editInfo(input: $input) {
    info {
      ...InfoDetails
    }
  }
}
    ${InfoDetailsFragmentDoc}`;
export type UpdateInfoMutationFn = Apollo.MutationFunction<UpdateInfoMutation, UpdateInfoMutationVariables>;

/**
 * __useUpdateInfoMutation__
 *
 * To run a mutation, you first call `useUpdateInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInfoMutation, { data, loading, error }] = useUpdateInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInfoMutation, UpdateInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInfoMutation, UpdateInfoMutationVariables>(UpdateInfoDocument, options);
      }
export type UpdateInfoMutationHookResult = ReturnType<typeof useUpdateInfoMutation>;
export type UpdateInfoMutationResult = Apollo.MutationResult<UpdateInfoMutation>;
export type UpdateInfoMutationOptions = Apollo.BaseMutationOptions<UpdateInfoMutation, UpdateInfoMutationVariables>;
export const UpdateNewsCategoryDocument = gql`
    mutation updateNewsCategory($input: EditNewsCategoryInput!) {
  editNewsCategory(input: $input) {
    newsCategory {
      id
      key: id
      title: name
      depth
    }
  }
}
    `;
export type UpdateNewsCategoryMutationFn = Apollo.MutationFunction<UpdateNewsCategoryMutation, UpdateNewsCategoryMutationVariables>;

/**
 * __useUpdateNewsCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateNewsCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNewsCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNewsCategoryMutation, { data, loading, error }] = useUpdateNewsCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNewsCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNewsCategoryMutation, UpdateNewsCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNewsCategoryMutation, UpdateNewsCategoryMutationVariables>(UpdateNewsCategoryDocument, options);
      }
export type UpdateNewsCategoryMutationHookResult = ReturnType<typeof useUpdateNewsCategoryMutation>;
export type UpdateNewsCategoryMutationResult = Apollo.MutationResult<UpdateNewsCategoryMutation>;
export type UpdateNewsCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateNewsCategoryMutation, UpdateNewsCategoryMutationVariables>;
export const UpdateNewsPostDocument = gql`
    mutation updateNewsPost($input: EditNewsPostInput!) {
  editNewsPost(input: $input) {
    newsPost {
      ...NewsPostDetails
    }
  }
}
    ${NewsPostDetailsFragmentDoc}`;
export type UpdateNewsPostMutationFn = Apollo.MutationFunction<UpdateNewsPostMutation, UpdateNewsPostMutationVariables>;

/**
 * __useUpdateNewsPostMutation__
 *
 * To run a mutation, you first call `useUpdateNewsPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNewsPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNewsPostMutation, { data, loading, error }] = useUpdateNewsPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNewsPostMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNewsPostMutation, UpdateNewsPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNewsPostMutation, UpdateNewsPostMutationVariables>(UpdateNewsPostDocument, options);
      }
export type UpdateNewsPostMutationHookResult = ReturnType<typeof useUpdateNewsPostMutation>;
export type UpdateNewsPostMutationResult = Apollo.MutationResult<UpdateNewsPostMutation>;
export type UpdateNewsPostMutationOptions = Apollo.BaseMutationOptions<UpdateNewsPostMutation, UpdateNewsPostMutationVariables>;
export const UpdateNewsPostsDocument = gql`
    mutation updateNewsPosts($input: EditNewsPostsInput!) {
  editNewsPosts(input: $input) {
    newsPosts {
      id
      publicDate
      publicStatus
      naviDisplay
      category {
        id
      }
    }
  }
}
    `;
export type UpdateNewsPostsMutationFn = Apollo.MutationFunction<UpdateNewsPostsMutation, UpdateNewsPostsMutationVariables>;

/**
 * __useUpdateNewsPostsMutation__
 *
 * To run a mutation, you first call `useUpdateNewsPostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNewsPostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNewsPostsMutation, { data, loading, error }] = useUpdateNewsPostsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNewsPostsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNewsPostsMutation, UpdateNewsPostsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNewsPostsMutation, UpdateNewsPostsMutationVariables>(UpdateNewsPostsDocument, options);
      }
export type UpdateNewsPostsMutationHookResult = ReturnType<typeof useUpdateNewsPostsMutation>;
export type UpdateNewsPostsMutationResult = Apollo.MutationResult<UpdateNewsPostsMutation>;
export type UpdateNewsPostsMutationOptions = Apollo.BaseMutationOptions<UpdateNewsPostsMutation, UpdateNewsPostsMutationVariables>;
export const UpdatePublisherDocument = gql`
    mutation updatePublisher($input: EditPublisherInput!) {
  editPublisher(input: $input) {
    publisher {
      ...PublisherDetails
    }
  }
}
    ${PublisherDetailsFragmentDoc}`;
export type UpdatePublisherMutationFn = Apollo.MutationFunction<UpdatePublisherMutation, UpdatePublisherMutationVariables>;

/**
 * __useUpdatePublisherMutation__
 *
 * To run a mutation, you first call `useUpdatePublisherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublisherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublisherMutation, { data, loading, error }] = useUpdatePublisherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePublisherMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublisherMutation, UpdatePublisherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePublisherMutation, UpdatePublisherMutationVariables>(UpdatePublisherDocument, options);
      }
export type UpdatePublisherMutationHookResult = ReturnType<typeof useUpdatePublisherMutation>;
export type UpdatePublisherMutationResult = Apollo.MutationResult<UpdatePublisherMutation>;
export type UpdatePublisherMutationOptions = Apollo.BaseMutationOptions<UpdatePublisherMutation, UpdatePublisherMutationVariables>;
export const UpdatePublisherEbookstoresDocument = gql`
    mutation updatePublisherEbookstores($input: EditPublisherEbookstoresInput!) {
  editPublisherEbookstores(input: $input) {
    publisherEbookstores {
      id
      publicStatus
    }
  }
}
    `;
export type UpdatePublisherEbookstoresMutationFn = Apollo.MutationFunction<UpdatePublisherEbookstoresMutation, UpdatePublisherEbookstoresMutationVariables>;

/**
 * __useUpdatePublisherEbookstoresMutation__
 *
 * To run a mutation, you first call `useUpdatePublisherEbookstoresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublisherEbookstoresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublisherEbookstoresMutation, { data, loading, error }] = useUpdatePublisherEbookstoresMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePublisherEbookstoresMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublisherEbookstoresMutation, UpdatePublisherEbookstoresMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePublisherEbookstoresMutation, UpdatePublisherEbookstoresMutationVariables>(UpdatePublisherEbookstoresDocument, options);
      }
export type UpdatePublisherEbookstoresMutationHookResult = ReturnType<typeof useUpdatePublisherEbookstoresMutation>;
export type UpdatePublisherEbookstoresMutationResult = Apollo.MutationResult<UpdatePublisherEbookstoresMutation>;
export type UpdatePublisherEbookstoresMutationOptions = Apollo.BaseMutationOptions<UpdatePublisherEbookstoresMutation, UpdatePublisherEbookstoresMutationVariables>;
export const UpdatePublisherNetshopsDocument = gql`
    mutation updatePublisherNetshops($input: EditPublisherNetshopsInput!) {
  editPublisherNetshops(input: $input) {
    publisherNetshops {
      id
      publicStatus
    }
  }
}
    `;
export type UpdatePublisherNetshopsMutationFn = Apollo.MutationFunction<UpdatePublisherNetshopsMutation, UpdatePublisherNetshopsMutationVariables>;

/**
 * __useUpdatePublisherNetshopsMutation__
 *
 * To run a mutation, you first call `useUpdatePublisherNetshopsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublisherNetshopsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublisherNetshopsMutation, { data, loading, error }] = useUpdatePublisherNetshopsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePublisherNetshopsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublisherNetshopsMutation, UpdatePublisherNetshopsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePublisherNetshopsMutation, UpdatePublisherNetshopsMutationVariables>(UpdatePublisherNetshopsDocument, options);
      }
export type UpdatePublisherNetshopsMutationHookResult = ReturnType<typeof useUpdatePublisherNetshopsMutation>;
export type UpdatePublisherNetshopsMutationResult = Apollo.MutationResult<UpdatePublisherNetshopsMutation>;
export type UpdatePublisherNetshopsMutationOptions = Apollo.BaseMutationOptions<UpdatePublisherNetshopsMutation, UpdatePublisherNetshopsMutationVariables>;
export const UpdateSeriesDocument = gql`
    mutation updateSeries($input: EditSeriesInput!) {
  editSeries(input: $input) {
    series {
      id
      key: id
      title: name
      kana
      depth
    }
  }
}
    `;
export type UpdateSeriesMutationFn = Apollo.MutationFunction<UpdateSeriesMutation, UpdateSeriesMutationVariables>;

/**
 * __useUpdateSeriesMutation__
 *
 * To run a mutation, you first call `useUpdateSeriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeriesMutation, { data, loading, error }] = useUpdateSeriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSeriesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeriesMutation, UpdateSeriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeriesMutation, UpdateSeriesMutationVariables>(UpdateSeriesDocument, options);
      }
export type UpdateSeriesMutationHookResult = ReturnType<typeof useUpdateSeriesMutation>;
export type UpdateSeriesMutationResult = Apollo.MutationResult<UpdateSeriesMutation>;
export type UpdateSeriesMutationOptions = Apollo.BaseMutationOptions<UpdateSeriesMutation, UpdateSeriesMutationVariables>;
export const AdminInfoDocument = gql`
    query adminInfo($id: ID!) {
  admin {
    info(id: $id) {
      ...InfoDetails
    }
  }
}
    ${InfoDetailsFragmentDoc}`;

/**
 * __useAdminInfoQuery__
 *
 * To run a query within a React component, call `useAdminInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminInfoQuery(baseOptions: Apollo.QueryHookOptions<AdminInfoQuery, AdminInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminInfoQuery, AdminInfoQueryVariables>(AdminInfoDocument, options);
      }
export function useAdminInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminInfoQuery, AdminInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminInfoQuery, AdminInfoQueryVariables>(AdminInfoDocument, options);
        }
export type AdminInfoQueryHookResult = ReturnType<typeof useAdminInfoQuery>;
export type AdminInfoLazyQueryHookResult = ReturnType<typeof useAdminInfoLazyQuery>;
export type AdminInfoQueryResult = Apollo.QueryResult<AdminInfoQuery, AdminInfoQueryVariables>;
export function refetchAdminInfoQuery(variables?: AdminInfoQueryVariables) {
      return { query: AdminInfoDocument, variables: variables }
    }
export const AdminInfoListDocument = gql`
    query adminInfoList($page: Int = 1, $limit: Int = 20) {
  admin {
    infos(page: $page, limit: $limit) {
      nodes {
        ...InfoDetails
      }
      pageInfo {
        ...PagyDetails
      }
    }
  }
}
    ${InfoDetailsFragmentDoc}
${PagyDetailsFragmentDoc}`;

/**
 * __useAdminInfoListQuery__
 *
 * To run a query within a React component, call `useAdminInfoListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminInfoListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminInfoListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAdminInfoListQuery(baseOptions?: Apollo.QueryHookOptions<AdminInfoListQuery, AdminInfoListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminInfoListQuery, AdminInfoListQueryVariables>(AdminInfoListDocument, options);
      }
export function useAdminInfoListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminInfoListQuery, AdminInfoListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminInfoListQuery, AdminInfoListQueryVariables>(AdminInfoListDocument, options);
        }
export type AdminInfoListQueryHookResult = ReturnType<typeof useAdminInfoListQuery>;
export type AdminInfoListLazyQueryHookResult = ReturnType<typeof useAdminInfoListLazyQuery>;
export type AdminInfoListQueryResult = Apollo.QueryResult<AdminInfoListQuery, AdminInfoListQueryVariables>;
export function refetchAdminInfoListQuery(variables?: AdminInfoListQueryVariables) {
      return { query: AdminInfoListDocument, variables: variables }
    }
export const AdminJproHistoryListDocument = gql`
    query adminJproHistoryList($page: Int = 1, $limit: Int = 20) {
  admin {
    jproHistories(page: $page, limit: $limit) {
      nodes {
        ...JproHistoryDetails
      }
      pageInfo {
        ...PagyDetails
      }
    }
  }
}
    ${JproHistoryDetailsFragmentDoc}
${PagyDetailsFragmentDoc}`;

/**
 * __useAdminJproHistoryListQuery__
 *
 * To run a query within a React component, call `useAdminJproHistoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminJproHistoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminJproHistoryListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAdminJproHistoryListQuery(baseOptions?: Apollo.QueryHookOptions<AdminJproHistoryListQuery, AdminJproHistoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminJproHistoryListQuery, AdminJproHistoryListQueryVariables>(AdminJproHistoryListDocument, options);
      }
export function useAdminJproHistoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminJproHistoryListQuery, AdminJproHistoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminJproHistoryListQuery, AdminJproHistoryListQueryVariables>(AdminJproHistoryListDocument, options);
        }
export type AdminJproHistoryListQueryHookResult = ReturnType<typeof useAdminJproHistoryListQuery>;
export type AdminJproHistoryListLazyQueryHookResult = ReturnType<typeof useAdminJproHistoryListLazyQuery>;
export type AdminJproHistoryListQueryResult = Apollo.QueryResult<AdminJproHistoryListQuery, AdminJproHistoryListQueryVariables>;
export function refetchAdminJproHistoryListQuery(variables?: AdminJproHistoryListQueryVariables) {
      return { query: AdminJproHistoryListDocument, variables: variables }
    }
export const AdminOperationLogListDocument = gql`
    query adminOperationLogList($publisherId: ID!, $q: OperationLogSearchParams = null, $page: Int = 1, $limit: Int = 20) {
  admin {
    operationLogs(publisherId: $publisherId, q: $q, page: $page, limit: $limit) {
      nodes {
        ...OperationLogDetails
      }
      pageInfo {
        ...PagyDetails
      }
    }
  }
}
    ${OperationLogDetailsFragmentDoc}
${PagyDetailsFragmentDoc}`;

/**
 * __useAdminOperationLogListQuery__
 *
 * To run a query within a React component, call `useAdminOperationLogListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOperationLogListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOperationLogListQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      q: // value for 'q'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAdminOperationLogListQuery(baseOptions: Apollo.QueryHookOptions<AdminOperationLogListQuery, AdminOperationLogListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminOperationLogListQuery, AdminOperationLogListQueryVariables>(AdminOperationLogListDocument, options);
      }
export function useAdminOperationLogListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminOperationLogListQuery, AdminOperationLogListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminOperationLogListQuery, AdminOperationLogListQueryVariables>(AdminOperationLogListDocument, options);
        }
export type AdminOperationLogListQueryHookResult = ReturnType<typeof useAdminOperationLogListQuery>;
export type AdminOperationLogListLazyQueryHookResult = ReturnType<typeof useAdminOperationLogListLazyQuery>;
export type AdminOperationLogListQueryResult = Apollo.QueryResult<AdminOperationLogListQuery, AdminOperationLogListQueryVariables>;
export function refetchAdminOperationLogListQuery(variables?: AdminOperationLogListQueryVariables) {
      return { query: AdminOperationLogListDocument, variables: variables }
    }
export const AdminPublisherDocument = gql`
    query AdminPublisher($publisherId: ID!) {
  publisher(id: $publisherId) {
    ...PublisherDetails
    users {
      id
      name
      email
    }
  }
}
    ${PublisherDetailsFragmentDoc}`;

/**
 * __useAdminPublisherQuery__
 *
 * To run a query within a React component, call `useAdminPublisherQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPublisherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPublisherQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useAdminPublisherQuery(baseOptions: Apollo.QueryHookOptions<AdminPublisherQuery, AdminPublisherQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminPublisherQuery, AdminPublisherQueryVariables>(AdminPublisherDocument, options);
      }
export function useAdminPublisherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminPublisherQuery, AdminPublisherQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminPublisherQuery, AdminPublisherQueryVariables>(AdminPublisherDocument, options);
        }
export type AdminPublisherQueryHookResult = ReturnType<typeof useAdminPublisherQuery>;
export type AdminPublisherLazyQueryHookResult = ReturnType<typeof useAdminPublisherLazyQuery>;
export type AdminPublisherQueryResult = Apollo.QueryResult<AdminPublisherQuery, AdminPublisherQueryVariables>;
export function refetchAdminPublisherQuery(variables?: AdminPublisherQueryVariables) {
      return { query: AdminPublisherDocument, variables: variables }
    }
export const AuthorDocument = gql`
    query author($publisherId: ID!, $id: ID!) {
  publisher(id: $publisherId) {
    author(id: $id) {
      ...AuthorDetails
    }
  }
}
    ${AuthorDetailsFragmentDoc}`;

/**
 * __useAuthorQuery__
 *
 * To run a query within a React component, call `useAuthorQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAuthorQuery(baseOptions: Apollo.QueryHookOptions<AuthorQuery, AuthorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthorQuery, AuthorQueryVariables>(AuthorDocument, options);
      }
export function useAuthorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthorQuery, AuthorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthorQuery, AuthorQueryVariables>(AuthorDocument, options);
        }
export type AuthorQueryHookResult = ReturnType<typeof useAuthorQuery>;
export type AuthorLazyQueryHookResult = ReturnType<typeof useAuthorLazyQuery>;
export type AuthorQueryResult = Apollo.QueryResult<AuthorQuery, AuthorQueryVariables>;
export function refetchAuthorQuery(variables?: AuthorQueryVariables) {
      return { query: AuthorDocument, variables: variables }
    }
export const AuthorListDocument = gql`
    query authorList($publisherId: ID!, $page: Int = 1, $q: AuthorSearchParams = null, $limit: Int = 20) {
  publisher(id: $publisherId) {
    authors(page: $page, q: $q, limit: $limit) {
      nodes {
        ...AuthorDetails
      }
      pageInfo {
        count
        next
        prev
        from
        to
        last
        page
        pages
      }
    }
  }
}
    ${AuthorDetailsFragmentDoc}`;

/**
 * __useAuthorListQuery__
 *
 * To run a query within a React component, call `useAuthorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorListQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      page: // value for 'page'
 *      q: // value for 'q'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAuthorListQuery(baseOptions: Apollo.QueryHookOptions<AuthorListQuery, AuthorListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthorListQuery, AuthorListQueryVariables>(AuthorListDocument, options);
      }
export function useAuthorListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthorListQuery, AuthorListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthorListQuery, AuthorListQueryVariables>(AuthorListDocument, options);
        }
export type AuthorListQueryHookResult = ReturnType<typeof useAuthorListQuery>;
export type AuthorListLazyQueryHookResult = ReturnType<typeof useAuthorListLazyQuery>;
export type AuthorListQueryResult = Apollo.QueryResult<AuthorListQuery, AuthorListQueryVariables>;
export function refetchAuthorListQuery(variables?: AuthorListQueryVariables) {
      return { query: AuthorListDocument, variables: variables }
    }
export const BannerDocument = gql`
    query banner($publisherId: ID!, $id: ID!) {
  publisher(id: $publisherId) {
    banner(id: $id, withPrivate: true) {
      ...BannerDetails
    }
  }
}
    ${BannerDetailsFragmentDoc}`;

/**
 * __useBannerQuery__
 *
 * To run a query within a React component, call `useBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBannerQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBannerQuery(baseOptions: Apollo.QueryHookOptions<BannerQuery, BannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BannerQuery, BannerQueryVariables>(BannerDocument, options);
      }
export function useBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BannerQuery, BannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BannerQuery, BannerQueryVariables>(BannerDocument, options);
        }
export type BannerQueryHookResult = ReturnType<typeof useBannerQuery>;
export type BannerLazyQueryHookResult = ReturnType<typeof useBannerLazyQuery>;
export type BannerQueryResult = Apollo.QueryResult<BannerQuery, BannerQueryVariables>;
export function refetchBannerQuery(variables?: BannerQueryVariables) {
      return { query: BannerDocument, variables: variables }
    }
export const BannerListDocument = gql`
    query bannerList($publisherId: ID!) {
  publisher(id: $publisherId) {
    banners(withPrivate: true) {
      nodes {
        ...BannerDetails
      }
    }
  }
}
    ${BannerDetailsFragmentDoc}`;

/**
 * __useBannerListQuery__
 *
 * To run a query within a React component, call `useBannerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBannerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBannerListQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useBannerListQuery(baseOptions: Apollo.QueryHookOptions<BannerListQuery, BannerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BannerListQuery, BannerListQueryVariables>(BannerListDocument, options);
      }
export function useBannerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BannerListQuery, BannerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BannerListQuery, BannerListQueryVariables>(BannerListDocument, options);
        }
export type BannerListQueryHookResult = ReturnType<typeof useBannerListQuery>;
export type BannerListLazyQueryHookResult = ReturnType<typeof useBannerListLazyQuery>;
export type BannerListQueryResult = Apollo.QueryResult<BannerListQuery, BannerListQueryVariables>;
export function refetchBannerListQuery(variables?: BannerListQueryVariables) {
      return { query: BannerListDocument, variables: variables }
    }
export const BigBannerDocument = gql`
    query bigBanner($publisherId: ID!, $id: ID!) {
  publisher(id: $publisherId) {
    bigBanner(id: $id, withPrivate: true) {
      ...BigBannerDetails
    }
  }
}
    ${BigBannerDetailsFragmentDoc}`;

/**
 * __useBigBannerQuery__
 *
 * To run a query within a React component, call `useBigBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useBigBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBigBannerQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBigBannerQuery(baseOptions: Apollo.QueryHookOptions<BigBannerQuery, BigBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BigBannerQuery, BigBannerQueryVariables>(BigBannerDocument, options);
      }
export function useBigBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BigBannerQuery, BigBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BigBannerQuery, BigBannerQueryVariables>(BigBannerDocument, options);
        }
export type BigBannerQueryHookResult = ReturnType<typeof useBigBannerQuery>;
export type BigBannerLazyQueryHookResult = ReturnType<typeof useBigBannerLazyQuery>;
export type BigBannerQueryResult = Apollo.QueryResult<BigBannerQuery, BigBannerQueryVariables>;
export function refetchBigBannerQuery(variables?: BigBannerQueryVariables) {
      return { query: BigBannerDocument, variables: variables }
    }
export const BigBannerListDocument = gql`
    query bigBannerList($publisherId: ID!) {
  publisher(id: $publisherId) {
    bigBanners(withPrivate: true) {
      nodes {
        ...BigBannerDetails
      }
    }
  }
}
    ${BigBannerDetailsFragmentDoc}`;

/**
 * __useBigBannerListQuery__
 *
 * To run a query within a React component, call `useBigBannerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBigBannerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBigBannerListQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useBigBannerListQuery(baseOptions: Apollo.QueryHookOptions<BigBannerListQuery, BigBannerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BigBannerListQuery, BigBannerListQueryVariables>(BigBannerListDocument, options);
      }
export function useBigBannerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BigBannerListQuery, BigBannerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BigBannerListQuery, BigBannerListQueryVariables>(BigBannerListDocument, options);
        }
export type BigBannerListQueryHookResult = ReturnType<typeof useBigBannerListQuery>;
export type BigBannerListLazyQueryHookResult = ReturnType<typeof useBigBannerListLazyQuery>;
export type BigBannerListQueryResult = Apollo.QueryResult<BigBannerListQuery, BigBannerListQueryVariables>;
export function refetchBigBannerListQuery(variables?: BigBannerListQueryVariables) {
      return { query: BigBannerListDocument, variables: variables }
    }
export const BookDocument = gql`
    query book($publisherId: ID!, $id: ID!) {
  publisher(id: $publisherId) {
    book(id: $id, withPrivate: true) {
      ...BookDetails
    }
  }
}
    ${BookDetailsFragmentDoc}`;

/**
 * __useBookQuery__
 *
 * To run a query within a React component, call `useBookQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookQuery(baseOptions: Apollo.QueryHookOptions<BookQuery, BookQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookQuery, BookQueryVariables>(BookDocument, options);
      }
export function useBookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookQuery, BookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookQuery, BookQueryVariables>(BookDocument, options);
        }
export type BookQueryHookResult = ReturnType<typeof useBookQuery>;
export type BookLazyQueryHookResult = ReturnType<typeof useBookLazyQuery>;
export type BookQueryResult = Apollo.QueryResult<BookQuery, BookQueryVariables>;
export function refetchBookQuery(variables?: BookQueryVariables) {
      return { query: BookDocument, variables: variables }
    }
export const BookListDocument = gql`
    query bookList($publisherId: ID!, $page: Int = 1, $q: BookSearchParams = null, $limit: Int = 20) {
  publisher(id: $publisherId) {
    books(page: $page, q: $q, limit: $limit, withPrivate: true, withOtherFormat: true) {
      nodes {
        ...BookListDetails
      }
      pageInfo {
        count
        next
        prev
        from
        to
        last
        page
        pages
      }
    }
  }
}
    ${BookListDetailsFragmentDoc}`;

/**
 * __useBookListQuery__
 *
 * To run a query within a React component, call `useBookListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookListQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      page: // value for 'page'
 *      q: // value for 'q'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useBookListQuery(baseOptions: Apollo.QueryHookOptions<BookListQuery, BookListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookListQuery, BookListQueryVariables>(BookListDocument, options);
      }
export function useBookListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookListQuery, BookListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookListQuery, BookListQueryVariables>(BookListDocument, options);
        }
export type BookListQueryHookResult = ReturnType<typeof useBookListQuery>;
export type BookListLazyQueryHookResult = ReturnType<typeof useBookListLazyQuery>;
export type BookListQueryResult = Apollo.QueryResult<BookListQuery, BookListQueryVariables>;
export function refetchBookListQuery(variables?: BookListQueryVariables) {
      return { query: BookListDocument, variables: variables }
    }
export const BookSelectionListDocument = gql`
    query bookSelectionList($publisherId: ID!, $page: Int = 1, $q: BookSearchParams = null, $limit: Int = 20) {
  publisher(id: $publisherId) {
    books(page: $page, q: $q, limit: $limit, withPrivate: true, withOtherFormat: true) {
      nodes {
        ...BookSelectionDetails
      }
      pageInfo {
        count
        next
        prev
        from
        to
        last
        page
        pages
      }
    }
  }
}
    ${BookSelectionDetailsFragmentDoc}`;

/**
 * __useBookSelectionListQuery__
 *
 * To run a query within a React component, call `useBookSelectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookSelectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookSelectionListQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      page: // value for 'page'
 *      q: // value for 'q'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useBookSelectionListQuery(baseOptions: Apollo.QueryHookOptions<BookSelectionListQuery, BookSelectionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookSelectionListQuery, BookSelectionListQueryVariables>(BookSelectionListDocument, options);
      }
export function useBookSelectionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookSelectionListQuery, BookSelectionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookSelectionListQuery, BookSelectionListQueryVariables>(BookSelectionListDocument, options);
        }
export type BookSelectionListQueryHookResult = ReturnType<typeof useBookSelectionListQuery>;
export type BookSelectionListLazyQueryHookResult = ReturnType<typeof useBookSelectionListLazyQuery>;
export type BookSelectionListQueryResult = Apollo.QueryResult<BookSelectionListQuery, BookSelectionListQueryVariables>;
export function refetchBookSelectionListQuery(variables?: BookSelectionListQueryVariables) {
      return { query: BookSelectionListDocument, variables: variables }
    }
export const CompanyDocument = gql`
    query company($publisherId: ID!, $id: ID!) {
  publisher(id: $publisherId) {
    company(id: $id, withPrivate: true) {
      ...CompanyDetails
    }
  }
}
    ${CompanyDetailsFragmentDoc}`;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export function refetchCompanyQuery(variables?: CompanyQueryVariables) {
      return { query: CompanyDocument, variables: variables }
    }
export const CompanyCategoriesDocument = gql`
    query companyCategories($publisherId: ID!) {
  publisher(id: $publisherId) {
    companyCategories {
      id
      name
      depth
    }
  }
}
    `;

/**
 * __useCompanyCategoriesQuery__
 *
 * To run a query within a React component, call `useCompanyCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyCategoriesQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useCompanyCategoriesQuery(baseOptions: Apollo.QueryHookOptions<CompanyCategoriesQuery, CompanyCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyCategoriesQuery, CompanyCategoriesQueryVariables>(CompanyCategoriesDocument, options);
      }
export function useCompanyCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyCategoriesQuery, CompanyCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyCategoriesQuery, CompanyCategoriesQueryVariables>(CompanyCategoriesDocument, options);
        }
export type CompanyCategoriesQueryHookResult = ReturnType<typeof useCompanyCategoriesQuery>;
export type CompanyCategoriesLazyQueryHookResult = ReturnType<typeof useCompanyCategoriesLazyQuery>;
export type CompanyCategoriesQueryResult = Apollo.QueryResult<CompanyCategoriesQuery, CompanyCategoriesQueryVariables>;
export function refetchCompanyCategoriesQuery(variables?: CompanyCategoriesQueryVariables) {
      return { query: CompanyCategoriesDocument, variables: variables }
    }
export const CompanyCategoryTreeDocument = gql`
    query companyCategoryTree($publisherId: ID!) {
  publisher(id: $publisherId) {
    companyCategoryTree {
      id
      key: id
      name
      depth
      uneditable: isFix
      children: branches {
        id
        key: id
        name
        depth
        uneditable: isFix
        children: branches {
          id
          key: id
          name
          depth
          uneditable: isFix
        }
      }
    }
  }
}
    `;

/**
 * __useCompanyCategoryTreeQuery__
 *
 * To run a query within a React component, call `useCompanyCategoryTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyCategoryTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyCategoryTreeQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useCompanyCategoryTreeQuery(baseOptions: Apollo.QueryHookOptions<CompanyCategoryTreeQuery, CompanyCategoryTreeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyCategoryTreeQuery, CompanyCategoryTreeQueryVariables>(CompanyCategoryTreeDocument, options);
      }
export function useCompanyCategoryTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyCategoryTreeQuery, CompanyCategoryTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyCategoryTreeQuery, CompanyCategoryTreeQueryVariables>(CompanyCategoryTreeDocument, options);
        }
export type CompanyCategoryTreeQueryHookResult = ReturnType<typeof useCompanyCategoryTreeQuery>;
export type CompanyCategoryTreeLazyQueryHookResult = ReturnType<typeof useCompanyCategoryTreeLazyQuery>;
export type CompanyCategoryTreeQueryResult = Apollo.QueryResult<CompanyCategoryTreeQuery, CompanyCategoryTreeQueryVariables>;
export function refetchCompanyCategoryTreeQuery(variables?: CompanyCategoryTreeQueryVariables) {
      return { query: CompanyCategoryTreeDocument, variables: variables }
    }
export const CompanyListDocument = gql`
    query companyList($publisherId: ID!, $page: Int = 1, $q: CompanySearchParams = null, $limit: Int = 20) {
  publisher(id: $publisherId) {
    companies(page: $page, q: $q, limit: $limit, withPrivate: true) {
      nodes {
        ...CompanyDetails
      }
      pageInfo {
        ...PagyDetails
      }
    }
  }
}
    ${CompanyDetailsFragmentDoc}
${PagyDetailsFragmentDoc}`;

/**
 * __useCompanyListQuery__
 *
 * To run a query within a React component, call `useCompanyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyListQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      page: // value for 'page'
 *      q: // value for 'q'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCompanyListQuery(baseOptions: Apollo.QueryHookOptions<CompanyListQuery, CompanyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyListQuery, CompanyListQueryVariables>(CompanyListDocument, options);
      }
export function useCompanyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyListQuery, CompanyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyListQuery, CompanyListQueryVariables>(CompanyListDocument, options);
        }
export type CompanyListQueryHookResult = ReturnType<typeof useCompanyListQuery>;
export type CompanyListLazyQueryHookResult = ReturnType<typeof useCompanyListLazyQuery>;
export type CompanyListQueryResult = Apollo.QueryResult<CompanyListQuery, CompanyListQueryVariables>;
export function refetchCompanyListQuery(variables?: CompanyListQueryVariables) {
      return { query: CompanyListDocument, variables: variables }
    }
export const GenreTreeDocument = gql`
    query genreTree($publisherId: ID!) {
  publisher(id: $publisherId) {
    genreTree {
      id
      key: id
      name
      depth
      parent {
        id
        name
        parent {
          id
          name
        }
      }
      children: branches {
        id
        key: id
        name
        depth
        parent {
          id
          name
          parent {
            id
            name
          }
        }
        children: branches {
          id
          key: id
          name
          depth
          parent {
            id
            name
            parent {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGenreTreeQuery__
 *
 * To run a query within a React component, call `useGenreTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenreTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenreTreeQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useGenreTreeQuery(baseOptions: Apollo.QueryHookOptions<GenreTreeQuery, GenreTreeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenreTreeQuery, GenreTreeQueryVariables>(GenreTreeDocument, options);
      }
export function useGenreTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenreTreeQuery, GenreTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenreTreeQuery, GenreTreeQueryVariables>(GenreTreeDocument, options);
        }
export type GenreTreeQueryHookResult = ReturnType<typeof useGenreTreeQuery>;
export type GenreTreeLazyQueryHookResult = ReturnType<typeof useGenreTreeLazyQuery>;
export type GenreTreeQueryResult = Apollo.QueryResult<GenreTreeQuery, GenreTreeQueryVariables>;
export function refetchGenreTreeQuery(variables?: GenreTreeQueryVariables) {
      return { query: GenreTreeDocument, variables: variables }
    }
export const GenresDocument = gql`
    query genres($publisherId: ID!) {
  publisher(id: $publisherId) {
    genres {
      id
      name
      depth
    }
  }
}
    `;

/**
 * __useGenresQuery__
 *
 * To run a query within a React component, call `useGenresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenresQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useGenresQuery(baseOptions: Apollo.QueryHookOptions<GenresQuery, GenresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenresQuery, GenresQueryVariables>(GenresDocument, options);
      }
export function useGenresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenresQuery, GenresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenresQuery, GenresQueryVariables>(GenresDocument, options);
        }
export type GenresQueryHookResult = ReturnType<typeof useGenresQuery>;
export type GenresLazyQueryHookResult = ReturnType<typeof useGenresLazyQuery>;
export type GenresQueryResult = Apollo.QueryResult<GenresQuery, GenresQueryVariables>;
export function refetchGenresQuery(variables?: GenresQueryVariables) {
      return { query: GenresDocument, variables: variables }
    }
export const HondanaEbookListDocument = gql`
    query hondanaEbookList($publisherId: ID!, $page: Int = 1, $limit: Int = 20) {
  publisher(id: $publisherId) {
    hondanaEbooks(page: $page, limit: $limit) {
      nodes {
        ...HondanaEbookListDetails
      }
      pageInfo {
        count
        next
        prev
        from
        to
        last
        page
        pages
      }
    }
  }
}
    ${HondanaEbookListDetailsFragmentDoc}`;

/**
 * __useHondanaEbookListQuery__
 *
 * To run a query within a React component, call `useHondanaEbookListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHondanaEbookListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHondanaEbookListQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useHondanaEbookListQuery(baseOptions: Apollo.QueryHookOptions<HondanaEbookListQuery, HondanaEbookListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HondanaEbookListQuery, HondanaEbookListQueryVariables>(HondanaEbookListDocument, options);
      }
export function useHondanaEbookListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HondanaEbookListQuery, HondanaEbookListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HondanaEbookListQuery, HondanaEbookListQueryVariables>(HondanaEbookListDocument, options);
        }
export type HondanaEbookListQueryHookResult = ReturnType<typeof useHondanaEbookListQuery>;
export type HondanaEbookListLazyQueryHookResult = ReturnType<typeof useHondanaEbookListLazyQuery>;
export type HondanaEbookListQueryResult = Apollo.QueryResult<HondanaEbookListQuery, HondanaEbookListQueryVariables>;
export function refetchHondanaEbookListQuery(variables?: HondanaEbookListQueryVariables) {
      return { query: HondanaEbookListDocument, variables: variables }
    }
export const InfoListDocument = gql`
    query infoList {
  infos {
    ...InfoDetails
  }
}
    ${InfoDetailsFragmentDoc}`;

/**
 * __useInfoListQuery__
 *
 * To run a query within a React component, call `useInfoListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfoListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfoListQuery({
 *   variables: {
 *   },
 * });
 */
export function useInfoListQuery(baseOptions?: Apollo.QueryHookOptions<InfoListQuery, InfoListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InfoListQuery, InfoListQueryVariables>(InfoListDocument, options);
      }
export function useInfoListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InfoListQuery, InfoListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InfoListQuery, InfoListQueryVariables>(InfoListDocument, options);
        }
export type InfoListQueryHookResult = ReturnType<typeof useInfoListQuery>;
export type InfoListLazyQueryHookResult = ReturnType<typeof useInfoListLazyQuery>;
export type InfoListQueryResult = Apollo.QueryResult<InfoListQuery, InfoListQueryVariables>;
export function refetchInfoListQuery(variables?: InfoListQueryVariables) {
      return { query: InfoListDocument, variables: variables }
    }
export const NewBookListDocument = gql`
    query newBookList($publisherId: ID!) {
  publisher(id: $publisherId) {
    newlyPublishedBooks(withPrivate: true, withOtherFormat: true, limit: 120) {
      nodes {
        ...NewBookDetails
      }
    }
  }
}
    ${NewBookDetailsFragmentDoc}`;

/**
 * __useNewBookListQuery__
 *
 * To run a query within a React component, call `useNewBookListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewBookListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewBookListQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useNewBookListQuery(baseOptions: Apollo.QueryHookOptions<NewBookListQuery, NewBookListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewBookListQuery, NewBookListQueryVariables>(NewBookListDocument, options);
      }
export function useNewBookListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewBookListQuery, NewBookListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewBookListQuery, NewBookListQueryVariables>(NewBookListDocument, options);
        }
export type NewBookListQueryHookResult = ReturnType<typeof useNewBookListQuery>;
export type NewBookListLazyQueryHookResult = ReturnType<typeof useNewBookListLazyQuery>;
export type NewBookListQueryResult = Apollo.QueryResult<NewBookListQuery, NewBookListQueryVariables>;
export function refetchNewBookListQuery(variables?: NewBookListQueryVariables) {
      return { query: NewBookListDocument, variables: variables }
    }
export const NewsCategoriesDocument = gql`
    query newsCategories($publisherId: ID!) {
  publisher(id: $publisherId) {
    newsCategories {
      id
      name
      depth
    }
  }
}
    `;

/**
 * __useNewsCategoriesQuery__
 *
 * To run a query within a React component, call `useNewsCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsCategoriesQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useNewsCategoriesQuery(baseOptions: Apollo.QueryHookOptions<NewsCategoriesQuery, NewsCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsCategoriesQuery, NewsCategoriesQueryVariables>(NewsCategoriesDocument, options);
      }
export function useNewsCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsCategoriesQuery, NewsCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsCategoriesQuery, NewsCategoriesQueryVariables>(NewsCategoriesDocument, options);
        }
export type NewsCategoriesQueryHookResult = ReturnType<typeof useNewsCategoriesQuery>;
export type NewsCategoriesLazyQueryHookResult = ReturnType<typeof useNewsCategoriesLazyQuery>;
export type NewsCategoriesQueryResult = Apollo.QueryResult<NewsCategoriesQuery, NewsCategoriesQueryVariables>;
export function refetchNewsCategoriesQuery(variables?: NewsCategoriesQueryVariables) {
      return { query: NewsCategoriesDocument, variables: variables }
    }
export const NewsCategoryTreeDocument = gql`
    query newsCategoryTree($publisherId: ID!) {
  publisher(id: $publisherId) {
    newsCategoryTree {
      id
      key: id
      name
      depth
      uneditable: isFix
      children: branches {
        id
        key: id
        name
        depth
        uneditable: isFix
        children: branches {
          id
          key: id
          name
          depth
          uneditable: isFix
        }
      }
    }
  }
}
    `;

/**
 * __useNewsCategoryTreeQuery__
 *
 * To run a query within a React component, call `useNewsCategoryTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsCategoryTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsCategoryTreeQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useNewsCategoryTreeQuery(baseOptions: Apollo.QueryHookOptions<NewsCategoryTreeQuery, NewsCategoryTreeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsCategoryTreeQuery, NewsCategoryTreeQueryVariables>(NewsCategoryTreeDocument, options);
      }
export function useNewsCategoryTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsCategoryTreeQuery, NewsCategoryTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsCategoryTreeQuery, NewsCategoryTreeQueryVariables>(NewsCategoryTreeDocument, options);
        }
export type NewsCategoryTreeQueryHookResult = ReturnType<typeof useNewsCategoryTreeQuery>;
export type NewsCategoryTreeLazyQueryHookResult = ReturnType<typeof useNewsCategoryTreeLazyQuery>;
export type NewsCategoryTreeQueryResult = Apollo.QueryResult<NewsCategoryTreeQuery, NewsCategoryTreeQueryVariables>;
export function refetchNewsCategoryTreeQuery(variables?: NewsCategoryTreeQueryVariables) {
      return { query: NewsCategoryTreeDocument, variables: variables }
    }
export const NewsPostDocument = gql`
    query newsPost($publisherId: ID!, $id: ID!) {
  publisher(id: $publisherId) {
    newsPost(id: $id, withPrivate: true) {
      ...NewsPostDetails
    }
  }
}
    ${NewsPostDetailsFragmentDoc}`;

/**
 * __useNewsPostQuery__
 *
 * To run a query within a React component, call `useNewsPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsPostQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNewsPostQuery(baseOptions: Apollo.QueryHookOptions<NewsPostQuery, NewsPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsPostQuery, NewsPostQueryVariables>(NewsPostDocument, options);
      }
export function useNewsPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsPostQuery, NewsPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsPostQuery, NewsPostQueryVariables>(NewsPostDocument, options);
        }
export type NewsPostQueryHookResult = ReturnType<typeof useNewsPostQuery>;
export type NewsPostLazyQueryHookResult = ReturnType<typeof useNewsPostLazyQuery>;
export type NewsPostQueryResult = Apollo.QueryResult<NewsPostQuery, NewsPostQueryVariables>;
export function refetchNewsPostQuery(variables?: NewsPostQueryVariables) {
      return { query: NewsPostDocument, variables: variables }
    }
export const NewsPostListDocument = gql`
    query newsPostList($publisherId: ID!, $page: Int = 1, $q: NewsPostSearchParams = null, $limit: Int = 20) {
  publisher(id: $publisherId) {
    newsPosts(page: $page, q: $q, limit: $limit, withPrivate: true) {
      nodes {
        ...NewsPostListDetails
      }
      pageInfo {
        ...PagyDetails
      }
    }
  }
}
    ${NewsPostListDetailsFragmentDoc}
${PagyDetailsFragmentDoc}`;

/**
 * __useNewsPostListQuery__
 *
 * To run a query within a React component, call `useNewsPostListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsPostListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsPostListQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      page: // value for 'page'
 *      q: // value for 'q'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useNewsPostListQuery(baseOptions: Apollo.QueryHookOptions<NewsPostListQuery, NewsPostListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsPostListQuery, NewsPostListQueryVariables>(NewsPostListDocument, options);
      }
export function useNewsPostListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsPostListQuery, NewsPostListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsPostListQuery, NewsPostListQueryVariables>(NewsPostListDocument, options);
        }
export type NewsPostListQueryHookResult = ReturnType<typeof useNewsPostListQuery>;
export type NewsPostListLazyQueryHookResult = ReturnType<typeof useNewsPostListLazyQuery>;
export type NewsPostListQueryResult = Apollo.QueryResult<NewsPostListQuery, NewsPostListQueryVariables>;
export function refetchNewsPostListQuery(variables?: NewsPostListQueryVariables) {
      return { query: NewsPostListDocument, variables: variables }
    }
export const PreviewBookDocument = gql`
    query previewBook($publisherId: ID!, $id: ID!) {
  publisher(id: $publisherId) {
    previewBook(id: $id, withPrivate: true) {
      ...PreviewBookDetails
    }
  }
}
    ${PreviewBookDetailsFragmentDoc}`;

/**
 * __usePreviewBookQuery__
 *
 * To run a query within a React component, call `usePreviewBookQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewBookQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePreviewBookQuery(baseOptions: Apollo.QueryHookOptions<PreviewBookQuery, PreviewBookQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewBookQuery, PreviewBookQueryVariables>(PreviewBookDocument, options);
      }
export function usePreviewBookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewBookQuery, PreviewBookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewBookQuery, PreviewBookQueryVariables>(PreviewBookDocument, options);
        }
export type PreviewBookQueryHookResult = ReturnType<typeof usePreviewBookQuery>;
export type PreviewBookLazyQueryHookResult = ReturnType<typeof usePreviewBookLazyQuery>;
export type PreviewBookQueryResult = Apollo.QueryResult<PreviewBookQuery, PreviewBookQueryVariables>;
export function refetchPreviewBookQuery(variables?: PreviewBookQueryVariables) {
      return { query: PreviewBookDocument, variables: variables }
    }
export const PreviewBookListDocument = gql`
    query previewBookList($publisherId: ID!, $page: Int = 1, $q: BookSearchParams = {sort: CREATED_AT_DESC}, $limit: Int = 1) {
  publisher(id: $publisherId) {
    previewBooks(page: $page, q: $q, limit: $limit, withPrivate: true, withOtherFormat: true) {
      nodes {
        ...PreviewBookListDetails
      }
      pageInfo {
        count
        next
        prev
        from
        to
        last
        page
        pages
      }
    }
  }
}
    ${PreviewBookListDetailsFragmentDoc}`;

/**
 * __usePreviewBookListQuery__
 *
 * To run a query within a React component, call `usePreviewBookListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewBookListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewBookListQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      page: // value for 'page'
 *      q: // value for 'q'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePreviewBookListQuery(baseOptions: Apollo.QueryHookOptions<PreviewBookListQuery, PreviewBookListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewBookListQuery, PreviewBookListQueryVariables>(PreviewBookListDocument, options);
      }
export function usePreviewBookListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewBookListQuery, PreviewBookListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewBookListQuery, PreviewBookListQueryVariables>(PreviewBookListDocument, options);
        }
export type PreviewBookListQueryHookResult = ReturnType<typeof usePreviewBookListQuery>;
export type PreviewBookListLazyQueryHookResult = ReturnType<typeof usePreviewBookListLazyQuery>;
export type PreviewBookListQueryResult = Apollo.QueryResult<PreviewBookListQuery, PreviewBookListQueryVariables>;
export function refetchPreviewBookListQuery(variables?: PreviewBookListQueryVariables) {
      return { query: PreviewBookListDocument, variables: variables }
    }
export const PreviewCompanyDocument = gql`
    query previewCompany($publisherId: ID!, $id: ID!) {
  publisher(id: $publisherId) {
    previewCompany(id: $id, withPrivate: true) {
      ...PreviewCompanyDetails
    }
  }
}
    ${PreviewCompanyDetailsFragmentDoc}`;

/**
 * __usePreviewCompanyQuery__
 *
 * To run a query within a React component, call `usePreviewCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewCompanyQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePreviewCompanyQuery(baseOptions: Apollo.QueryHookOptions<PreviewCompanyQuery, PreviewCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewCompanyQuery, PreviewCompanyQueryVariables>(PreviewCompanyDocument, options);
      }
export function usePreviewCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewCompanyQuery, PreviewCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewCompanyQuery, PreviewCompanyQueryVariables>(PreviewCompanyDocument, options);
        }
export type PreviewCompanyQueryHookResult = ReturnType<typeof usePreviewCompanyQuery>;
export type PreviewCompanyLazyQueryHookResult = ReturnType<typeof usePreviewCompanyLazyQuery>;
export type PreviewCompanyQueryResult = Apollo.QueryResult<PreviewCompanyQuery, PreviewCompanyQueryVariables>;
export function refetchPreviewCompanyQuery(variables?: PreviewCompanyQueryVariables) {
      return { query: PreviewCompanyDocument, variables: variables }
    }
export const PreviewCompanyListDocument = gql`
    query previewCompanyList($publisherId: ID!, $page: Int = 1, $q: CompanySearchParams = {sort: CREATED_AT_DESC}, $limit: Int = 1) {
  publisher(id: $publisherId) {
    previewCompanies(page: $page, q: $q, limit: $limit, withPrivate: true) {
      nodes {
        ...PreviewCompanyDetails
      }
      pageInfo {
        ...PagyDetails
      }
    }
  }
}
    ${PreviewCompanyDetailsFragmentDoc}
${PagyDetailsFragmentDoc}`;

/**
 * __usePreviewCompanyListQuery__
 *
 * To run a query within a React component, call `usePreviewCompanyListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewCompanyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewCompanyListQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      page: // value for 'page'
 *      q: // value for 'q'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePreviewCompanyListQuery(baseOptions: Apollo.QueryHookOptions<PreviewCompanyListQuery, PreviewCompanyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewCompanyListQuery, PreviewCompanyListQueryVariables>(PreviewCompanyListDocument, options);
      }
export function usePreviewCompanyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewCompanyListQuery, PreviewCompanyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewCompanyListQuery, PreviewCompanyListQueryVariables>(PreviewCompanyListDocument, options);
        }
export type PreviewCompanyListQueryHookResult = ReturnType<typeof usePreviewCompanyListQuery>;
export type PreviewCompanyListLazyQueryHookResult = ReturnType<typeof usePreviewCompanyListLazyQuery>;
export type PreviewCompanyListQueryResult = Apollo.QueryResult<PreviewCompanyListQuery, PreviewCompanyListQueryVariables>;
export function refetchPreviewCompanyListQuery(variables?: PreviewCompanyListQueryVariables) {
      return { query: PreviewCompanyListDocument, variables: variables }
    }
export const PreviewNewsDocument = gql`
    query previewNews($publisherId: ID!, $id: ID!) {
  publisher(id: $publisherId) {
    previewNews(id: $id, withPrivate: true) {
      ...PreviewNewsDetails
    }
  }
}
    ${PreviewNewsDetailsFragmentDoc}`;

/**
 * __usePreviewNewsQuery__
 *
 * To run a query within a React component, call `usePreviewNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewNewsQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePreviewNewsQuery(baseOptions: Apollo.QueryHookOptions<PreviewNewsQuery, PreviewNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewNewsQuery, PreviewNewsQueryVariables>(PreviewNewsDocument, options);
      }
export function usePreviewNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewNewsQuery, PreviewNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewNewsQuery, PreviewNewsQueryVariables>(PreviewNewsDocument, options);
        }
export type PreviewNewsQueryHookResult = ReturnType<typeof usePreviewNewsQuery>;
export type PreviewNewsLazyQueryHookResult = ReturnType<typeof usePreviewNewsLazyQuery>;
export type PreviewNewsQueryResult = Apollo.QueryResult<PreviewNewsQuery, PreviewNewsQueryVariables>;
export function refetchPreviewNewsQuery(variables?: PreviewNewsQueryVariables) {
      return { query: PreviewNewsDocument, variables: variables }
    }
export const PreviewNewsListDocument = gql`
    query previewNewsList($publisherId: ID!, $page: Int = 1, $q: NewsPostSearchParams = {sort: CREATED_AT_DESC}, $limit: Int = 20) {
  publisher(id: $publisherId) {
    previewNewsList(page: $page, q: $q, limit: $limit, withPrivate: true) {
      nodes {
        ...PreviewNewsDetails
      }
      pageInfo {
        ...PagyDetails
      }
    }
  }
}
    ${PreviewNewsDetailsFragmentDoc}
${PagyDetailsFragmentDoc}`;

/**
 * __usePreviewNewsListQuery__
 *
 * To run a query within a React component, call `usePreviewNewsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewNewsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewNewsListQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      page: // value for 'page'
 *      q: // value for 'q'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePreviewNewsListQuery(baseOptions: Apollo.QueryHookOptions<PreviewNewsListQuery, PreviewNewsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewNewsListQuery, PreviewNewsListQueryVariables>(PreviewNewsListDocument, options);
      }
export function usePreviewNewsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewNewsListQuery, PreviewNewsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewNewsListQuery, PreviewNewsListQueryVariables>(PreviewNewsListDocument, options);
        }
export type PreviewNewsListQueryHookResult = ReturnType<typeof usePreviewNewsListQuery>;
export type PreviewNewsListLazyQueryHookResult = ReturnType<typeof usePreviewNewsListLazyQuery>;
export type PreviewNewsListQueryResult = Apollo.QueryResult<PreviewNewsListQuery, PreviewNewsListQueryVariables>;
export function refetchPreviewNewsListQuery(variables?: PreviewNewsListQueryVariables) {
      return { query: PreviewNewsListDocument, variables: variables }
    }
export const PublisherDocument = gql`
    query publisher($publisherId: ID!) {
  publisher(id: $publisherId) {
    ...PublisherDetails
  }
}
    ${PublisherDetailsFragmentDoc}`;

/**
 * __usePublisherQuery__
 *
 * To run a query within a React component, call `usePublisherQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function usePublisherQuery(baseOptions: Apollo.QueryHookOptions<PublisherQuery, PublisherQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherQuery, PublisherQueryVariables>(PublisherDocument, options);
      }
export function usePublisherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherQuery, PublisherQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherQuery, PublisherQueryVariables>(PublisherDocument, options);
        }
export type PublisherQueryHookResult = ReturnType<typeof usePublisherQuery>;
export type PublisherLazyQueryHookResult = ReturnType<typeof usePublisherLazyQuery>;
export type PublisherQueryResult = Apollo.QueryResult<PublisherQuery, PublisherQueryVariables>;
export function refetchPublisherQuery(variables?: PublisherQueryVariables) {
      return { query: PublisherDocument, variables: variables }
    }
export const PublisherBookFormDocument = gql`
    query publisherBookForm($publisherId: ID!) {
  publisher(id: $publisherId) {
    ebookstores(withPrivate: true) {
      nodes {
        ...PublisherEbookstoreDetails
      }
    }
    profile {
      jpro
      freeItems {
        ...PublisherFreeItemDetails
      }
    }
  }
}
    ${PublisherEbookstoreDetailsFragmentDoc}
${PublisherFreeItemDetailsFragmentDoc}`;

/**
 * __usePublisherBookFormQuery__
 *
 * To run a query within a React component, call `usePublisherBookFormQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherBookFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherBookFormQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function usePublisherBookFormQuery(baseOptions: Apollo.QueryHookOptions<PublisherBookFormQuery, PublisherBookFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherBookFormQuery, PublisherBookFormQueryVariables>(PublisherBookFormDocument, options);
      }
export function usePublisherBookFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherBookFormQuery, PublisherBookFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherBookFormQuery, PublisherBookFormQueryVariables>(PublisherBookFormDocument, options);
        }
export type PublisherBookFormQueryHookResult = ReturnType<typeof usePublisherBookFormQuery>;
export type PublisherBookFormLazyQueryHookResult = ReturnType<typeof usePublisherBookFormLazyQuery>;
export type PublisherBookFormQueryResult = Apollo.QueryResult<PublisherBookFormQuery, PublisherBookFormQueryVariables>;
export function refetchPublisherBookFormQuery(variables?: PublisherBookFormQueryVariables) {
      return { query: PublisherBookFormDocument, variables: variables }
    }
export const PublisherEbookstoreListDocument = gql`
    query publisherEbookstoreList($publisherId: ID!) {
  publisher(id: $publisherId) {
    ebookstores(withPrivate: true) {
      nodes {
        ...PublisherEbookstoreDetails
      }
    }
  }
}
    ${PublisherEbookstoreDetailsFragmentDoc}`;

/**
 * __usePublisherEbookstoreListQuery__
 *
 * To run a query within a React component, call `usePublisherEbookstoreListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherEbookstoreListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherEbookstoreListQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function usePublisherEbookstoreListQuery(baseOptions: Apollo.QueryHookOptions<PublisherEbookstoreListQuery, PublisherEbookstoreListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherEbookstoreListQuery, PublisherEbookstoreListQueryVariables>(PublisherEbookstoreListDocument, options);
      }
export function usePublisherEbookstoreListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherEbookstoreListQuery, PublisherEbookstoreListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherEbookstoreListQuery, PublisherEbookstoreListQueryVariables>(PublisherEbookstoreListDocument, options);
        }
export type PublisherEbookstoreListQueryHookResult = ReturnType<typeof usePublisherEbookstoreListQuery>;
export type PublisherEbookstoreListLazyQueryHookResult = ReturnType<typeof usePublisherEbookstoreListLazyQuery>;
export type PublisherEbookstoreListQueryResult = Apollo.QueryResult<PublisherEbookstoreListQuery, PublisherEbookstoreListQueryVariables>;
export function refetchPublisherEbookstoreListQuery(variables?: PublisherEbookstoreListQueryVariables) {
      return { query: PublisherEbookstoreListDocument, variables: variables }
    }
export const PublisherJproDocument = gql`
    query publisherJpro($publisherId: ID!) {
  publisher(id: $publisherId) {
    id
    profile {
      id
      jpro
    }
  }
}
    `;

/**
 * __usePublisherJproQuery__
 *
 * To run a query within a React component, call `usePublisherJproQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherJproQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherJproQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function usePublisherJproQuery(baseOptions: Apollo.QueryHookOptions<PublisherJproQuery, PublisherJproQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherJproQuery, PublisherJproQueryVariables>(PublisherJproDocument, options);
      }
export function usePublisherJproLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherJproQuery, PublisherJproQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherJproQuery, PublisherJproQueryVariables>(PublisherJproDocument, options);
        }
export type PublisherJproQueryHookResult = ReturnType<typeof usePublisherJproQuery>;
export type PublisherJproLazyQueryHookResult = ReturnType<typeof usePublisherJproLazyQuery>;
export type PublisherJproQueryResult = Apollo.QueryResult<PublisherJproQuery, PublisherJproQueryVariables>;
export function refetchPublisherJproQuery(variables?: PublisherJproQueryVariables) {
      return { query: PublisherJproDocument, variables: variables }
    }
export const PublisherNetshopListDocument = gql`
    query publisherNetshopList($publisherId: ID!) {
  publisher(id: $publisherId) {
    netshops(withPrivate: true) {
      nodes {
        ...PublisherNetshopDetails
      }
    }
  }
}
    ${PublisherNetshopDetailsFragmentDoc}`;

/**
 * __usePublisherNetshopListQuery__
 *
 * To run a query within a React component, call `usePublisherNetshopListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherNetshopListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherNetshopListQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function usePublisherNetshopListQuery(baseOptions: Apollo.QueryHookOptions<PublisherNetshopListQuery, PublisherNetshopListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherNetshopListQuery, PublisherNetshopListQueryVariables>(PublisherNetshopListDocument, options);
      }
export function usePublisherNetshopListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherNetshopListQuery, PublisherNetshopListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherNetshopListQuery, PublisherNetshopListQueryVariables>(PublisherNetshopListDocument, options);
        }
export type PublisherNetshopListQueryHookResult = ReturnType<typeof usePublisherNetshopListQuery>;
export type PublisherNetshopListLazyQueryHookResult = ReturnType<typeof usePublisherNetshopListLazyQuery>;
export type PublisherNetshopListQueryResult = Apollo.QueryResult<PublisherNetshopListQuery, PublisherNetshopListQueryVariables>;
export function refetchPublisherNetshopListQuery(variables?: PublisherNetshopListQueryVariables) {
      return { query: PublisherNetshopListDocument, variables: variables }
    }
export const PublisherSidebarDocument = gql`
    query publisherSidebar($publisherId: ID!) {
  publisher(id: $publisherId) {
    id
    profile {
      id
      url
      shopifyUrl
    }
  }
}
    `;

/**
 * __usePublisherSidebarQuery__
 *
 * To run a query within a React component, call `usePublisherSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherSidebarQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function usePublisherSidebarQuery(baseOptions: Apollo.QueryHookOptions<PublisherSidebarQuery, PublisherSidebarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherSidebarQuery, PublisherSidebarQueryVariables>(PublisherSidebarDocument, options);
      }
export function usePublisherSidebarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherSidebarQuery, PublisherSidebarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherSidebarQuery, PublisherSidebarQueryVariables>(PublisherSidebarDocument, options);
        }
export type PublisherSidebarQueryHookResult = ReturnType<typeof usePublisherSidebarQuery>;
export type PublisherSidebarLazyQueryHookResult = ReturnType<typeof usePublisherSidebarLazyQuery>;
export type PublisherSidebarQueryResult = Apollo.QueryResult<PublisherSidebarQuery, PublisherSidebarQueryVariables>;
export function refetchPublisherSidebarQuery(variables?: PublisherSidebarQueryVariables) {
      return { query: PublisherSidebarDocument, variables: variables }
    }
export const PublishersDocument = gql`
    query publishers($q: PublisherSearchParams = null, $page: Int = 1, $limit: Int = 20) {
  admin {
    publishers(q: $q, page: $page, limit: $limit) {
      nodes {
        ...PublisherDetails
      }
      pageInfo {
        ...PagyDetails
      }
    }
  }
}
    ${PublisherDetailsFragmentDoc}
${PagyDetailsFragmentDoc}`;

/**
 * __usePublishersQuery__
 *
 * To run a query within a React component, call `usePublishersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishersQuery({
 *   variables: {
 *      q: // value for 'q'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePublishersQuery(baseOptions?: Apollo.QueryHookOptions<PublishersQuery, PublishersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublishersQuery, PublishersQueryVariables>(PublishersDocument, options);
      }
export function usePublishersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublishersQuery, PublishersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublishersQuery, PublishersQueryVariables>(PublishersDocument, options);
        }
export type PublishersQueryHookResult = ReturnType<typeof usePublishersQuery>;
export type PublishersLazyQueryHookResult = ReturnType<typeof usePublishersLazyQuery>;
export type PublishersQueryResult = Apollo.QueryResult<PublishersQuery, PublishersQueryVariables>;
export function refetchPublishersQuery(variables?: PublishersQueryVariables) {
      return { query: PublishersDocument, variables: variables }
    }
export const SeriesDocument = gql`
    query series($publisherId: ID!) {
  publisher(id: $publisherId) {
    series {
      id
      name
      kana
      depth
    }
  }
}
    `;

/**
 * __useSeriesQuery__
 *
 * To run a query within a React component, call `useSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeriesQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useSeriesQuery(baseOptions: Apollo.QueryHookOptions<SeriesQuery, SeriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeriesQuery, SeriesQueryVariables>(SeriesDocument, options);
      }
export function useSeriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeriesQuery, SeriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeriesQuery, SeriesQueryVariables>(SeriesDocument, options);
        }
export type SeriesQueryHookResult = ReturnType<typeof useSeriesQuery>;
export type SeriesLazyQueryHookResult = ReturnType<typeof useSeriesLazyQuery>;
export type SeriesQueryResult = Apollo.QueryResult<SeriesQuery, SeriesQueryVariables>;
export function refetchSeriesQuery(variables?: SeriesQueryVariables) {
      return { query: SeriesDocument, variables: variables }
    }
export const SeriesTreeDocument = gql`
    query seriesTree($publisherId: ID!) {
  publisher(id: $publisherId) {
    seriesTree {
      id
      key: id
      name
      kana
      depth
      parent {
        id
        name
        parent {
          id
          name
        }
      }
      children: branches {
        id
        key: id
        name
        kana
        depth
        parent {
          id
          name
          parent {
            id
            name
          }
        }
        children: branches {
          id
          key: id
          name
          kana
          depth
          parent {
            id
            name
            parent {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSeriesTreeQuery__
 *
 * To run a query within a React component, call `useSeriesTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeriesTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeriesTreeQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useSeriesTreeQuery(baseOptions: Apollo.QueryHookOptions<SeriesTreeQuery, SeriesTreeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeriesTreeQuery, SeriesTreeQueryVariables>(SeriesTreeDocument, options);
      }
export function useSeriesTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeriesTreeQuery, SeriesTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeriesTreeQuery, SeriesTreeQueryVariables>(SeriesTreeDocument, options);
        }
export type SeriesTreeQueryHookResult = ReturnType<typeof useSeriesTreeQuery>;
export type SeriesTreeLazyQueryHookResult = ReturnType<typeof useSeriesTreeLazyQuery>;
export type SeriesTreeQueryResult = Apollo.QueryResult<SeriesTreeQuery, SeriesTreeQueryVariables>;
export function refetchSeriesTreeQuery(variables?: SeriesTreeQueryVariables) {
      return { query: SeriesTreeDocument, variables: variables }
    }