import React, { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'

type Props = {
  opened: boolean
  lg?: boolean
  onClose(): void
  title: string
  HeaderElement?: JSX.Element
  footer: JSX.Element
}

// type State = {}

export const ModalDialog: React.FC<Props> = ({
  opened,
  lg,
  onClose,
  title,
  HeaderElement,
  children,
  footer,
}) => {
  const el = useRef<HTMLDivElement>(document.createElement('div'))

  useEffect(() => {
    if (!el.current) return
    document.getElementById('modal-root')?.appendChild(el.current)
  }, [])

  useEffect(() => {
    if (opened) {
      document.body.className += ' modal-open'
    } else {
      document.body.className = document.body.className.replace(
        ' modal-open',
        ''
      )
    }
  }, [opened])

  if (!el.current) return <></>
  return createPortal(
    <>
      <div
        className={`modal fade bd-example-modal-sm ${
          opened ? 'show d-block' : ''
        }`}
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onClick={onClose}
      >
        <div
          className={`modal-dialog ${lg ? 'modal-lg' : ''}`}
          role="document"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className="modal-content">
            <div className="modal-header">
              {}
              <h5 className="modal-title" id="exampleModalLabel">
                {title}
              </h5>
              {HeaderElement}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body-wrapper">
              <div className="modal-body">{children}</div>
            </div>
            <div className="modal-footer">{footer}</div>
          </div>
        </div>
      </div>
      <div className={`modal-backdrop fade ${opened ? 'show' : 'd-none'}`} />
    </>,
    el.current
  )
}
