import React, { useRef, forwardRef, useImperativeHandle } from 'react'

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export const RadioButton = forwardRef<HTMLInputElement, Props>(function Input(
  { children, className = '', ...props },
  ref
) {
  const inputRef = useRef<HTMLInputElement>(null)
  const handleClick = () => {
    inputRef?.current?.click()
  }
  useImperativeHandle(ref, () => inputRef.current!)

  return (
    <>
      <input
        ref={inputRef}
        type="radio"
        className="custom-control-input"
        {...props}
      />
      <label
        className={`custom-control-label ${className}`}
        onClick={handleClick}
      >
        {children}
      </label>
    </>
  )
})
