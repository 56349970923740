import React from 'react'

type TreeNode = { name: string; children?: TreeNode[] }

export const JoinedNodeName: React.FC<{ node: TreeNode }> = ({ node }) => (
  <>
    {node.name}
    {node.children?.map((child, i) => (
      <React.Fragment key={i}>
        {' > '}
        <JoinedNodeName node={child} />
      </React.Fragment>
    ))}
  </>
)
