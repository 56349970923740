import React, { useState } from 'react'

type Props = {
  type?: string
  message: string
}

const AlertToast: React.FC<Props> = ({ type, message }) => {
  const [opened, setOpened] = useState(true)
  let toastType = type
  switch (type) {
    case 'alert':
      toastType = 'error'
      break
    case 'notice':
      toastType = 'success'
      break
  }
  return (
    <div id="toast-container" className="toast-top-center">
      {opened && (
        <div
          className={`toast toast-${toastType}`}
          aria-live="assertive"
          onClick={() => {
            setOpened(!opened)
          }}
        >
          <button type="button" className="toast-close-button" role="button">
            ×
          </button>
          <div className="toast-message">{message}</div>
        </div>
      )}
    </div>
  )
}

export default AlertToast
