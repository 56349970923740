/* eslint-disable react-hooks/rules-of-hooks */
import * as ActiveStorage from '@rails/activestorage'
import Rails from '@rails/ujs'

Rails.start()
ActiveStorage.start()

var componentRequireContext = require.context('components', true)
var ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)
global.Rails = Rails
global.ReactRailsUJS = ReactRailsUJS
