import React from 'react'
import { animateScroll } from 'react-scroll'

const ScrollToTop: React.FC = () => {
  return (
    <div
      className="btn-pagetop"
      onClick={() => {
        animateScroll.scrollToTop()
      }}
    />
  )
}

export default ScrollToTop
